import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "./CGTMSE.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import DocumentRequired from "../DocumentRequired/DocumentRequired";
import {
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
} from "../../../utils/DocumentsRequiredLists/cgtmseDocuments";
import CgtmseTable from "../../CgtmseTable/CgtmseTable";
import {
  cgtmsePic,
  featuresPic,
  partner10,
  partner11,
  rectangleFooterImage,
} from "../../../utils/ImagesData/imagesData";
const CGTMSE = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const featuresList = [
    "Collateral-free Credit",
    "Low rate of interest",
    "Attractive bundled offerings",
    "Less Documentation",
    "Long term & short term financing",
    "Flexible Tenure",
    "Large Capital",
  ];

  return (
    <section className="cgtmse-container-section">
      <Helmet>
        <title>
          Credit Guarantee Fund Trust for Micro and Small Enterprises - Finconic
        </title>
        <meta name="description" content="Contact us" />
      </Helmet>
      <Navbar />

      <section className="herosection-flex-img-container-md">
        <div className="inner-container-flex-row">
          <div className=" img-section-left">
            <img src={cgtmsePic} alt="" className="img-one-md-p" />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2">CGTMSE</h2>
            <p>
              The Credit Guarantee Fund Trust for Micro and Small Enterprises
              (CGTMSE) is a scheme in India that was launched to facilitate
              collateral-free credit to micro and small enterprises (MSEs)
            </p>
            <div className="div-main-btn-container">
              <button
                className="cta-btn-flex"
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                Reach Out to Us{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="business-content-p">
        <p>
          The Credit Guarantee Fund Trust for Micro and Small Enterprises
          (CGTMSE) is a scheme in India that was launched to facilitate
          collateral-free credit to micro and small enterprises (MSEs). It was
          established by the Government of India in association with the Small
          Industries Development Bank of India (SIDBI) and the Ministry of
          Micro, Small, and Medium Enterprises (MSMEs).
        </p>
      </div>
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col">
            <h1>Features of CGTMSE</h1>
            <ul className="text-content-flex-col-list">
              {featuresList.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className="image-md">
            <img src={featuresPic} alt="" />
          </div>
        </div>
      </div>

      {/* <div className="outer-section-flex-row card-one">
        <h2>Factors Affecting the Business Loan Amount</h2>
        <div className="card-one-flex">
          <div className="card-img-one-md-cgtmse">
            <img src={factorsPic} alt="" />
          </div>
          <div className="card-container-box-cgtmse" data-aos="fade-left">
            <div className="one-card-cgtmse" data-aos="fade-left">
              <h3>Repayment Capacity</h3>
            </div>
            <div className="two-card-cgtmse" data-aos="fade-left">
              <h3>Average Profit earned from the business</h3>
            </div>
            <div className="three-card-cgtmse" data-aos="fade-left">
              <h3>Cash flow of the business</h3>
            </div>
            <div className="four-card-cgtmse" data-aos="fade-left">
              <h3>Years of existence of the business</h3>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-flex-row">
        <div className="inner-container">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Business Loan?</h2>
            <p>
              Sole proprietorship firm/Partnership Firms/Any registered
              Company/Self Employed Professionals
            </p>
            <p>Business must have at least 3 years of existence</p>
            <p>ITR filing for at least 1 year</p>
          </div>
          <div className="card-image-flex floating">
            <img src={floatingImage} alt="floating image" />
          </div>
        </div>
      </div> */}
      <CgtmseTable />
      <DocumentRequired
        pvtLtdDocumentData={pvtLtdDocumentData}
        partnershipDocumentData={partnershipDocumentData}
        proprietorshipDocumentData={proprietorshipDocumentData}
      />
      <div className="  md:mt-[2rem] relative md:top-[-6rem] mt-[22rem] bottom-[-10rem]">
        <div className="partner-list-container">
          <img
            src={rectangleFooterImage}
            className=" absolute w-screen mt-[-5.5rem] h-[34rem]"
            alt=""
          />
          <h2>Our Partners</h2>
          <div className="company-logos-cgtmse">
            <div className="company-logo-image-desktop">
              <img src={partner10} alt="Partner Image 1" />
            </div>
            <div className="company-logo-image-desktop">
              <img src={partner11} alt="Partner Image 2" />
            </div>
          </div>
        </div>{" "}
      </div>

      <section className="cgtmse-footer-container">
        <Footer />
      </section>
    </section>
  );
};

export default CGTMSE;
