import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import "./MobileSidebar.css";

const listItemStyle = {
  fontFamily: "Inter, sans-serif",
  fontSize: "1.2rem",
};

const sublistTextStyle = {
  color: "#656565",
  fontSize: "1.2rem",
};

const MobileSidebar = ({ open, onClose, links ,setDefaultNavbarStyles}) => {
  const [isServicesSublistOpen, setServicesSublistOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setServicesSublistOpen(false);
    }
  }, [open]);

  const toggleServicesSublist = () => {
    setServicesSublistOpen(!isServicesSublistOpen);
  };

  return (
    <div className="sidebar-drawer-container">
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        className="custom-drawer"
      >
        <List className="custom-sidebar-list">
          {links.map((parentLink) => (
            <div className="sidebar-list-item-container" key={parentLink.id}>
              <ListItem
                style={listItemStyle}
                className="custom-sidebar-list-item"
              >
                {parentLink.sublist ? (
                  <>
                    <ListItemText
                      primary={parentLink.title}
                      onClick={toggleServicesSublist}
                      style={listItemStyle}
                      className="custom-sidebar-list-item-text"
                    />
                    <div onClick={toggleServicesSublist}>
                      <ArrowDropDownIcon
                        style={{
                          transform: isServicesSublistOpen
                            ? "none"
                            : "rotate(-90deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <NavLink to={parentLink.route} onClick={onClose}>
                    <ListItemText
                      primary={parentLink.title}
                      style={listItemStyle}
                    />
                  </NavLink>
                )}
              </ListItem>
              {parentLink.sublist && isServicesSublistOpen && (
                <List className="custom-sublist">
                  {parentLink.sublist.map((sublink) => (
                    <ListItem key={sublink.title}>
                      <NavLink to={sublink.route} onClick={onClose}>
                        <ListItemText
                          style={sublistTextStyle}
                          primary={sublink.title}
                          className="custom-sublist-text"
                        />
                      </NavLink>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default MobileSidebar;
