import React from "react";
import "./DocumentRequired.css";
const SingleDocument = ({ document }) => {
  const { documentName, list, img } = document;

  return (
    <div className="document-card">
      <div className="document-card-pic">
        <img src={img} alt={documentName} />
      </div>
      <div className="document-card-title">{documentName}</div>
      <div className="document-card-content">
        <div className="document-card-header-for-list">
          <p className="document-list-heading-text">{documentName}</p>
        </div>
        <ul>
          {Object.values(list).map((doc, index) => (
            <li key={index}>{doc}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SingleDocument;
