import React from "react";
import "./reviewSlider.css";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";
import { reviewsData } from "../../utils/ReviewsData/reviewsData";

const ReviewSlider = () => {
  let cards = reviewsData.map((data) => {
    return {
      key: uuidv4(),
      content: (
        <Card
          imagen={data.image}
          name={data.name}
          rating={data.rating}
          review={data.reviewText}
        />
      ),
    };
  });

  return (
    <div className="">
      <Carousel
        cards={cards}
        height="200px"
        width="30%"
        margin="13rem  auto 2rem auto"
        offset={2}
        showArrows={false}
      />
    </div>
  );
};

export default ReviewSlider;
