import React, { useEffect } from "react";
import "./MobileUnsecuredDroplineLimit.css";
import Footer from "../../../Components/MobileScreens/Footer/MobileFooter";
import RequiredDocuments from "../RequiredDocuments/RequiredDocuments";
import {
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
} from "../../../utils/DocumentsRequiredLists/droplineLimitDocuments";
import Aos from "aos";
import PartnerCards from "../HomePage/PartnerCards/PartnerCards";
import { Helmet } from "react-helmet";
import {
  businessElgibleLoanImg,
  featuresPic,
  mobileDropLineFactorsImg,
  mobileUnsecureDroplineTopLogo,
  partner1,
  partner2,
  partner7,
  partner8,
  partner9,
  rectangleFooterImage,
} from "../../../utils/ImagesData/imagesData";

const MobileUnsecuredDroplineLimit = () => {
  const featuresList = [
    "Withdrawal limit reduces each month from initially sanctioned limit",
    "Comes as both secured and unsecured loan",
    "Do not require collateral to be submitted, in case of unsecured Dropline Overdraft facility",
    "Interest rate is charged on a monthly basis but calculated on a daily basis",
    "Borrowing limit can go up to Rs. 15 crores, however, it depends on the Bank’s sole discretion",
    "Borrowed amount is credited to only current accounts",
    "Can be used on a monthly, quarterly, half-yearly or yearly basis",
    "Usually the Dropline limit is assigned for 1 – 15 years, it may vary from bank to bank",
    "One-time processing fee is charged",
    "Do not levy yearly renewal charge",
    "It is a mix of term loan and overdraft facility",
    "Opted mostly by manufacturers, retailers and traders",
  ];

  const fatorsList = [
    "Credit Score/ Credit History",
    "Income/ Financial Stability",
    "Debt to income Ratio",
    "Lender Policies",
  ];

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>Unsecured Dropline Limit - Finconic</title>
        <meta name="description" content="Unsecured Dropline Limit Details" />
      </Helmet>
      <div className="mobileDropline">
        {/* Top Section */}
        <div className="mobileDropline-top">
          <div className="mobileDropline-top-image">
            <img
              src={mobileUnsecureDroplineTopLogo}
              alt="Unsecured Loan Image"
            />
          </div>
          <div className="mobileDropline-top-headings">
            <h2>Unsecured Dropline Limit</h2>
          </div>
          <div className="mobileDropline-top-desc">
            <p>
              The dropline overdraft is almost similar in all the cases except
              in new cases as there is the availability of the limit. This
              withdrawal limit reduces each month from the limit which is
              sanctioned. The calculation of the Interest rate is done on a
              daily basis and it is charged at the month’s end.
            </p>
          </div>
          <div className="mobileDropline-top-button">
            <button
              className="cta-btn-flex"
              onClick={() => (window.location.href = "/contact")}
            >
              Reach Out to Us
            </button>
          </div>
        </div>

        {/*Description */}
        <div className="mobileDropline-desc">
          <p>
            Drop-line Overdraft (DLOD) is a facility granted to the customer by
            the financial institution where businesses can overdraw from their
            current account up to a limit that is agreed upon by the banker.
            Overdraft is one of the most efficient forms of borrowing as one
            needs to pay interest only for the amount of money withdrawn.
          </p>
          <p>
            The dropline overdraft is almost similar in all the cases except in
            new cases as there is the availability of the limit. This withdrawal
            limit reduces each month from the limit which is sanctioned. The
            calculation of the Interest rate is done on a daily basis and it is
            charged at the month’s end. You will be charged only for the amount
            used, So, you can always park your funds in a virtual account
            whenever funds are not in use.
          </p>
        </div>

        {/* Features */}
        <div className="mobileDropline-features">
          <div className="mobileDropline-features-heading">
            <h2>Features of Unsecured Dropline Limit</h2>
          </div>
          <div className="mobileDropline-features-image">
            <img src={featuresPic} alt="" />
          </div>
          <div className="mobileDropline-features-lists">
            <ul>
              {featuresList.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Factors */}
        <div className="mobileDropline-factors">
          <div className="mobileDropline-factors-heading">
            <h2>Factors Affecting the Unsecured Dropline limit Amount</h2>
          </div>
          <div className="mobileDropline-factors-img">
            <img src={mobileDropLineFactorsImg} alt="" />
          </div>
          <div className="mobileDropline-factors-tabs">
            {fatorsList.map((factor, i) => (
              <div key={i} data-aos="fade-up" data-aos-duration="2000">
                {factor}
              </div>
            ))}
          </div>
        </div>

        {/* Eligible for business loan */}
        <div className="mobileDropline-eligible">
          <div className="mobileDropline-eligible-heading">
            <h2>Who is Eligible</h2>
            <span>for the Unsecured Dropline Limit?</span>
          </div>
          <div className="mobileDropline-eligible-img">
            <img src={businessElgibleLoanImg} alt="" />
          </div>
          <div className="mobileDropline-eligible-desc">
            <p>
              Any entrepreneur, proprietor, self-employed professional, a
              private company, or partnership firm will get access to the DLOD
              facility.
            </p>
            <p>
              Unsecured dropline limits are typically available to individuals
              and businesses with a strong credit history and financial
              stability.
            </p>
            <p>
              Eligibility often depends on factors such as credit score, income,
              and repayment capacity.
            </p>
          </div>
        </div>

        {/* Documents Required */}
        <div className="mobileDropline-documentsRequired">
          <div className="mobileDropline-documentsRequired-heading">
            <h2>Documents Required</h2>
          </div>
          <div className="mobileUnsecuredLoan-documentsRequired-lists">
            <RequiredDocuments
              pvtLtdDocumentData={pvtLtdDocumentData}
              partnershipDocumentData={partnershipDocumentData}
              proprietorshipDocumentData={proprietorshipDocumentData}
            />
          </div>
        </div>

        {/* partners list */}
        <div className="partner-list-container-dropline-mobile">
          <img
            src={rectangleFooterImage}
            className="rectangle-footer-image-mobile w-screen "
            alt="background-image-for-partner"
          />
          <h2 className="partner-list-container-dropline-mobile-heading">
            Our Partners
          </h2>
          <div className="company-logos-dropline-mobile">
            <div className="company-logo-dropline-image-mobile">
              <img src={partner1} alt="Partner Image 1" />
            </div>
            <div className="company-logo-dropline-image-mobile">
              <img src={partner2} alt="Partner Image 2" />
            </div>
            <div className="company-logo-dropline-image-mobile">
              <img src={partner7} alt="Partner Image 3" />
            </div>
            <div className="company-logo-dropline-image-mobile">
              <img src={partner8} alt="Partner Image 4" />
            </div>
            <div className="company-logo-dropline-image-mobile">
              <img src={partner9} alt="Partner Image 5" />
            </div>
          </div>
        </div>
      </div>{" "}
      <Footer />
    </>
  );
};

export default MobileUnsecuredDroplineLimit;
