import React from "react";
import ImportCss from "../../Components/ImportCss/ImportCss";
import { Route, Routes } from "react-router-dom";
import ContactUs from "../../Components/Pages/ContactUs/ContactUs";
import HomePage from "../../Components/Pages/HomePage/HomePage";
import BusinessLoan from "../../Components/Pages/BusinessLoan/BusinessLoan";
// import PersonalLoan from "./Components/Pages/PersonalLoan/PersonalLoan";
import ProfessionalLoan from "../../Components/Pages/ProfessionalLoan/ProfessionalLoan";
import LoanAgainstProperty from "../../Components/Pages/LoanAgainstProperty/LoanAgainstProperty";
import UsedCarLoan from "../../Components/Pages/UsedCarLoan/UsedCarLoan";
import HomeLoan from "../../Components/Pages/HomeLone/HomeLoan";
import MSMELoan from "../../Components/Pages/MSMELoan/MSMELoan";
import LoanTransfer from "../../Components/Pages/LoanTransfer/LoanTransfer";
import AboutUS from "../../Components/Pages/AboutUs/AboutUS";
import EmiCalculator from "../../Components/ToolsCalculator/EmiCalculator";

// import ScrollToTop from "./Components/ScrollToTop";
import DroplineLimit from "../../Components/Pages/DroplineLimit/DroplineLimit";
import CGTMSE from "../../Components/Pages/CGTMSE/CGTMSE";
import NotFound from "../../Components/Pages/NotFound/NotFound";
import { Helmet } from "react-helmet";
import Navbar from "../../Components/Navbar/Navbar";
import TermsAndPolicies from "../../Components/Pages/TermsAndPolicies/TermsAndPolicies";

const DesktopMobileRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/business-loan" element={<BusinessLoan />} />
        <Route path="/dropline-limit" element={<DroplineLimit />} />
        <Route path="/cgtmse" element={<CGTMSE />} />
        <Route path="/professional-loan" element={<ProfessionalLoan />} />
        <Route
          path="/loan-against-property"
          element={<LoanAgainstProperty />}
        />
        <Route path="/used-card-loan" element={<UsedCarLoan />} />
        <Route path="/home-loan" element={<HomeLoan />} />
        <Route path="/msme-loan" element={<MSMELoan />} />
        <Route path="/loan-transfer" element={<LoanTransfer />} />
        <Route path="/about" element={<AboutUS />} />
        <Route path="/emi-calculator" element={<EmiCalculator />} />
        <Route path="/terms-and-policies" element={<TermsAndPolicies />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default DesktopMobileRoutes;
