import React from "react";
import "./MobileAboutUs.css";
import MobileTeamMemberCard from "./MobileTeamMemberCard";
import MobileAboutUsLastCards from "./MobileAboutUsLastCards";
import { Helmet } from "react-helmet";
import Footer from "../../../Components/MobileScreens/Footer/MobileFooter";
import { teamMembers } from "../../../utils/TeamsData/teamsData";
import { aboutUsPic } from "../../../utils/ImagesData/imagesData";

const MobileAboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us - Finconic</title>
        <meta name="description" content="About Finconic" />
      </Helmet>
      <div className="mobileAboutUs">
        {/* About Us Top Section */}
        <div className="mobileAboutUsTopSection">
          <div className="mobileAboutUsTopSection-img">
            <img src={aboutUsPic} alt="About us Image" />
          </div>
          <div className="mobileAboutUsTopSection-heading">
            <h3>We’re all about your</h3>
            <h2>FINANCIAL NEEDS</h2>
          </div>

          <div className="mobileAboutUsTopSection-desc">
            <p>
              At Finconic, we're dedicated to addressing your financial
              requirements with precision and care. Whether you seek loans, or
              financial guidance, our seasoned team is here to provide tailored
              solutions. Trust us to empower your financial journey.
            </p>
          </div>
        </div>

        {/* Who We Are In About us */}
        <div className="whoWeAreAboutUs">
          <div className="whoWeAreAboutUs-heading">
            <h2>Who We Are?</h2>
          </div>
          <div className="whoWeAreAboutUs-video">
            <iframe
              src="https://www.youtube.com/embed/8Xzfceq29ts?si=nGVQxkWzAFyV_H5l"
              title="YouTube video player"
              // frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="whoWeAreAboutUs-desc">
            <p>
              A customer-centric brand, specializes in providing comprehensive
              financial solutions to businesses. With a 360-degree approach, we
              expedite access to funds at highly competitive rates. Our unique
              strength lies in connecting businesses with India's premier
              lenders, ensuring they receive swift and tailored financial
              support.
            </p>
            <p>
              we understand the unique needs of each business and work closely
              with them to secure the funding they require. Our mission is to
              facilitate financial success by bridging the gap between
              businesses and top-tier lenders, making the process as seamless
              and efficient as possible.
            </p>
          </div>
        </div>

        {/* Our Vision */}
        <div className="ourVisionAboutUS">
          <div className="ourVisionAboutUS-heading">
            <h2>Our Vision</h2>
          </div>
          <div className="ourVisionAboutUS-desc">
            <p>
              "Empower your enterprise with ease. Finconic offers unsecured
              business loans, flexible dropline limits, and CGTMSE support,
              making access to funds a breeze. Our streamlined approach ensures
              you experience effortless disbursals, transforming your journey
              into one of financial freedom."
            </p>
            <p>
              "Bid farewell to cumbersome paperwork. With Finconic, you can
              achieve your business goals swiftly through a hassle-free,
              one-time documentation process that simplifies the path to
              financial success."
            </p>
          </div>
        </div>

        {/* Our Team */}
        <div className="ourTeamAboutUs">
          <div className="ourTeamAboutUs-heading">
            <h2>Let’s meet our team</h2>
          </div>
          <div className="ourTeamAboutUs-team">
            <div className="ourTeamAboutUs-team-cards">
              {teamMembers.map((team, i) => (
                <MobileTeamMemberCard key={i} profile={team} />
              ))}
            </div>
          </div>
        </div>

        {/* aboutUsCards */}
        <div className="aboutUsCardsSection">
          <div className="reviewsHomePage-top-line-dots">
            <div className="reviewsHomePage-top-dot"></div>
            <div className="reviewsHomePage-top-line"></div>
            <div className="reviewsHomePage-top-dot"></div>
          </div>
          <div className="aboutUsCards-cards">
            <MobileAboutUsLastCards />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MobileAboutUs;
