import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../HomeLone/HomeLone.css";

function HomeLoan() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input
    const phoneNumberDigits = value.replace(/\D/g, "");
    // Limit the phone number to 10 digits
    const limitedPhoneNumber = phoneNumberDigits.slice(0, 10);
    setPhoneNumber(limitedPhoneNumber);
  };
  return (
    <>
      <Navbar />
      <section className="herosection-flex-img-container-md">
        <div className=" inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src="/HeaderImg/home loan (1).png"
              alt=""
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Home</h2>
            <h2 className="heading-h2">Loan</h2>
            <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex">Apply Now</button>
            </div>
          </div>
        </div>
      </section>
      {/* para */}
      <div className="busniness-content-p">
        <p>
          A home loan is the amount of loan borrowed by an individual from the
          bank or financial institutions to purchase, construct or repair the
          home. Loan is provided for a specific time period with pre-agreed
          interest rate. Principal amount of loan is to be repaid by the
          borrower in monthly installments with the amount of interest.
        </p>
        <p>
          The property is taken by the lender as a security in token of the loan
          amount. Property can be residential or commercial in nature. In case
          of default from the borrower, lender has all the legal rights over the
          property.
        </p>
      </div>
      {/* Types of Home Loan in India */}

      <div className="outer-container-home-lone">
        <h2>Types of Home Loan in India </h2>
        <div className="homelone-card">
          <div className="flex-row">
            <div className="flex-flow-col">
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Loan for the Purchase of Land</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      Several Banks and Financial institutions provides loans
                      for the purchase of land. Individual can purchase the land
                      for future construction or for resale. Purchase of Land
                      can be financed up to 90% of the cost of the land
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Loans for Home Purchase</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      This is the most popular type of loan to purchase the new
                      or pre-owned home. Around 85% is financed by the banks
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Loans for Construction of a House</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      This loan is provided for the construction of house. In
                      case the cost of land also to be included in the loan
                      amount then plot must have been purchased within one year.
                      This amount can be disbursed in full at one time or in
                      instalments
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>House Expansion or Extension Loans</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      his loan is given for the construction of additional part
                      to the existing home. It also includes the renovation of
                      the house
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Home Conversion Loans</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      his loan is given for the construction of additional part
                      to the existing home. It also includes the renovation of
                      the house
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* ///////div now */}
            <div className="flex-flow-col">
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Loan for the Purchase of Land</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      Several Banks and Financial institutions provides loans
                      for the purchase of land. Individual can purchase the land
                      for future construction or for resale. Purchase of Land
                      can be financed up to 90% of the cost of the land
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Loans for Home Purchase</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      This is the most popular type of loan to purchase the new
                      or pre-owned home. Around 85% is financed by the banks
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Loans for Construction of a House</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      This loan is provided for the construction of house. In
                      case the cost of land also to be included in the loan
                      amount then plot must have been purchased within one year.
                      This amount can be disbursed in full at one time or in
                      instalments
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>House Expansion or Extension Loans</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      his loan is given for the construction of additional part
                      to the existing home. It also includes the renovation of
                      the house
                    </p>
                  </div>
                </div>
              </div>
              <div className="flip-card" tabIndex="0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <h4>Home Conversion Loans</h4>
                  </div>
                  <div className="flip-card-back">
                    <p>
                      his loan is given for the construction of additional part
                      to the existing home. It also includes the renovation of
                      the house
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* card Property Documents */}
      <div className="container-one">
        <div className="Property-Documents-container">
          <div className="inner-Property-Documents">
            <h2>Property Documents</h2>
            <ul className="inner-Property-Documents li-col">
              <li>
                Sale deed, agreement of sale with the builder (original copy)
              </li>
              <li>
                {" "}
                Land and building tax paid receipts, location sketch of the
                property certified by the revenue authorities, possession
                certificate.
              </li>
              <li>
                Letter of allotment given by the Housing Board/Society/Private
                builder.
              </li>
              <li>
                Original receipts of the advance payments that are made towards
                the purchase of flat.
              </li>
              <li>
                {" "}
                An approved copy of the building plan (key plan/floor plan in
                case of purchase of flats).
              </li>
              <li>
                Original of the land tax paid receipt and possession certificate
                as issued by the revenue authority.
              </li>
              <li>
                {" "}
                Original No objection certificate (NOC) from the housing society
                or builder.
              </li>
              <li> Detailed estimate of the cost of construction of house.</li>
              <li>
                Letter from the builder/society/housing board, stating their
                account number and name of their bankers for the remittance of
                installments.
              </li>
            </ul>
          </div>
          <div
            className="property-doc-img
                "
          >
            <img src=" /Image/7911324 1.png" alt="" />
          </div>
        </div>
      </div>
      {/* rombus */}
      <div className="outer-section-flex-row">
        <h2>Documents Required</h2>
        <div className="card-flex-row-md">
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-2.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-3.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-1.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-4.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HomeLoan;
