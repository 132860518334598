const rootName = "https://finconic-cdn.s3.ap-south-1.amazonaws.com";

export const chatGif = `${rootName}/Image/chatGif.gif`;
export const aboutPic = `${rootName}/Image/accounting_1 1.png`;
export const dataSecurityPic = `${rootName}/Image/data-security.png`;
export const investorPic = `${rootName}/Image/Group 631992.png`;
export const servicesGif = `${rootName}/Image/servicesGif.gif`;
export const whatWeOfferInd = `${rootName}/Image/whatWeOfferIndusry.png`;
export const whatWeOfferMon = `${rootName}/Image/whatWeOfferMoney.png`;
export const whatWeOfferSec = `${rootName}/Image/whatWeOfferSecured.png`;
export const rectangleFooterImage = `${rootName}/Image/rectangleFooterImage.png`;
export const partner1 = `${rootName}/Image/partner-1.png`;
export const partner2 = `${rootName}/Image/partner-2.png`;
export const partner3 = `${rootName}/Image/partner-3.png`;
export const partner4 = `${rootName}/Image/partner-4.png`;
export const partner5 = `${rootName}/Image/partner-5.png`;
export const partner6 = `${rootName}/Image/partner-6.png`;
export const partner7 = `${rootName}/Image/partner-7.png`;
export const partner8 = `${rootName}/Image/partner-8.png`;
export const partner9 = `${rootName}/Image/partner-9.png`;
export const partner10 = `${rootName}/Image/partner-10.png`;
export const partner11 = `${rootName}/Image/partner-11.png`;
export const footerLogo = `${rootName}/Image/footer-logo.png`;
export const faceBookIcon = `${rootName}/Image/Facebook-logo.png`;
export const instagramIcon = `${rootName}/Image/Instagram-logo.png`;
export const linkedInIcon = `${rootName}/Image/LinkedIn-logo.png`;
export const mail1 = `${rootName}/Image/mail1.png`;
export const mail2 = `${rootName}/Image/mail2.png`;
export const companyLogo = `${rootName}/Image/logo.png`;
export const closeIconPopup = `${rootName}/Image/closeIconPopup.png`;
export const aboutUsPic = `${rootName}/Image/about us 1st pic.png`;
export const bgImage = `${rootName}/Image/rectangleFooterImage.png`;
export const avatarPic = `${rootName}/Image/avatar-pic.png`;
export const teamPic1 = `${rootName}/Image/ameet-pic.jpg`;
export const teamPic2 = `${rootName}/Image/kaanan-pic.jpg`;
export const teamPic3 = `${rootName}/Image/ankit-pic.png`;
export const featuresPic = `${rootName}/Image/features-icons.gif`;
export const floatingImage = `${rootName}/Image/card-img.png`;
export const businessLoanTopPic = `${rootName}/Image/unsecured-business-loan.png`;
export const factorsPic = `${rootName}/Image/business-loan-features-pic.png`;
export const cgtmsePic = `${rootName}/Image/cgtmse-top-pic.png`;
export const contactUsPic = `${rootName}/Image/contact-us-page-pic.png`;
export const callContactPageIcon = `${rootName}/Image/callContactPageIcon.png`;
export const mail_Contact_Icon = `${rootName}/Image/mail_Contact_Icon.png`;
export const location_ContactUs_Icon = `${rootName}/Image/location_ContactUs_Icon.png`;
export const repaymentImage = `${rootName}/Image/repayment-schedule-Image-For-bussiness-docs.png`;
export const gstImage = `${rootName}/Image/gst-Image-For-bussiness-docs.png`;
export const bankStatementImage = `${rootName}/Image/bank-statement-Image-For-bussiness-docs.png`;
export const companyKycImage = `${rootName}/Image/company-kyc-Image-For-bussiness-docs.png`;
export const individualKycImage = `${rootName}/Image/individual-kyc-Image-For-bussiness-docs.png`;
export const finanicalsImage = `${rootName}/Image/financials-Image-For-bussiness-docs.png`;
export const droplinePic = `${rootName}/Image/dropline-limit-top.png`;
export const droplineFactors = `${rootName}/Image/dropline-feature.png`;
export const mainImg = `${rootName}/Image/notFoundMainIcon.png`;
export const topImg = `${rootName}/Image/NotFoundSideCloudIcon.png`;
export const bottomImg = `${rootName}/Image/NotFoundSideBottomCloudIcon.png`;
export const reviewAbhayDeepDubey = `${rootName}/Image/reviewAbhayDeepDubey.jpg`;
export const reviewAbhinavGoel = `${rootName}/Image/reviewAbhinavGoel.jpg`;
export const reviewAmit = `${rootName}/Image/reviewAmit.jpg`;
export const ReviewRobinGarg = `${rootName}/Image/ReviewRobinGarg.jpg`;
export const reviewSaurabhWahi = `${rootName}/Image/reviewSaurabhWahie.jpg`;
export const progressBarSuccessIcon = `${rootName}/Image/progressBarSuccessIcon.png`;

// mobile screen images

export const homePageTopIconStocks = `${rootName}/MobileScreenImages/HomePageMobileMainImageStocks.png`;
export const bussinessLogo = `${rootName}/MobileScreenImages/unSecured-bussiness-loan-logo.png`;
export const droplineLogo = `${rootName}/MobileScreenImages/unSecured-dropline-limit-logo.png`;
export const cgtmseLogo = `${rootName}/MobileScreenImages/cgtmse-logo.png`;
export const mobileCGTMSE = `${rootName}/MobileScreenImages/mobileCGTMSE-top-logo.png`;
export const finconicLogo = `${rootName}/MobileScreenImages/finconic-logo.png`;
export const contactUsLogo = `${rootName}/MobileScreenImages/mobile-navbar-contact-us-icon.png`;
export const mainImgForNotFoundMobile = `${rootName}/MobileScreenImages/mainImgForNotFoundMobile.png`;
export const mobileUnsecureDroplineTopLogo = `${rootName}/MobileScreenImages/mobileUnsecureDropline-top-logo.png`;
export const mobileDropLineFactorsImg = `${rootName}/MobileScreenImages/mobileDropLineFactorsImg.png`
export const businessElgibleLoanImg = `${rootName}/MobileScreenImages/businessElgibleLoanImg.png`
export const mobileUnsecureLoanTopLogo = `${rootName}/MobileScreenImages/mobileUnsecureLoan-top-logo.png`;
export const mobileFactorsUSLImage = `${rootName}/MobileScreenImages/mobileFactorsUSLImage.png`

