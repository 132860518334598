import React from "react";

const MobileAboutUsLastCards = () => {
  const cardInfo = [
    {
      id: 1,
      title: "Our Specialization",
      details:
        "Our expertise lies in a streamlined loan disbursement, minimizing paperwork, and providing ongoing support",
    },
    {
      id: 2,
      title: "Seamless Process",
      details:
        "Our loan disbursal process is seamless, ensuring you receive funds promptly and making your financial journey hassle-free.",
    },
    {
      id: 3,
      title: "Less ROI",
      details:
        "Secure funds with the most favorable interest rates for your financial needs, reducing the cost of borrowing significantly.",
    },
    ,
    {
      id: 4,
      title: "Data Security",
      details:
        "Data Security is our core commitment. We employ stringent protocols to safeguard your sensitive information.",
    },
  ];

  return (
    <>
      {cardInfo.map((item) => (
        <div className="home-card-container" data-aos="fade-up" key={item.id}>
          <p className={`home-card-container-heading ${item.id}`}>
            {item.title}
          </p>
          <p className="home-card-container-paragraph">{item.details}</p>
        </div>
      ))}
    </>
  );
};

export default MobileAboutUsLastCards;
