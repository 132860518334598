import React from "react";
import Home from "../../Components/MobileScreens/HomePage/Home";
import MobileAboutUs from "../../Components/MobileScreens/MobileAboutUs/MobileAboutUs";
import MobileContactUs from "../../Components/MobileScreens/MobileContactUs/MobileContactUs";
import { Route, Routes } from "react-router-dom";
import ImportCss from "../../Components/ImportCss/ImportCss";
import MobileNavbar from "../../Components/MobileScreens/MobileNavbar/MobileNavbar";
// import Footer from "../../Components/MobileScreens/Footer/MobileFooter";
import MobileUnsecuredLoan from "../../Components/MobileScreens/MobileUnsecuredLoan/MobileUnsecuredLoan";
import MobileNotFound from "../../Components/MobileScreens/MobileNotFound/MobileNotFound";
import MobileUnsecuredDroplineLimit from "../../Components/MobileScreens/MobileUnsecuredDroplineLimit/MobileUnsecuredDroplineLimit";
import MobileCGTMSE from "../../Components/MobileScreens/MobileCGTMSE/MobileCGTMSE";
import TermsAndPolicies from "../../Components/Pages/TermsAndPolicies/TermsAndPolicies";
import MobileTermsAndPolicies from "../../Components/MobileScreens/MobileTermsAndPolicies/MobileTermsAndPolicies";

const MobileRoutes = () => {
  return (
    <>
      <MobileNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<MobileAboutUs />} />
        <Route path="/contact" element={<MobileContactUs />} />
        <Route path="/business-loan" element={<MobileUnsecuredLoan />} />
        <Route
          path="/dropline-limit"
          element={<MobileUnsecuredDroplineLimit />}
        />
        <Route path="/cgtmse" element={<MobileCGTMSE />} />
        <Route
          path="/terms-and-policies"
          element={<MobileTermsAndPolicies />}
        />
        <Route path="*" element={<MobileNotFound />} />
      </Routes>
    </>
  );
};

export default MobileRoutes;
