import React from "react";
import "./ClientsReviewsBanner.css";
import ReviewSliderDesktop from "../../ReviewSliderDesktop/ReviewSliderDesktop";
const ClientsReviewsBanner = () => {
  return (
    <>
      <section
        className="main-reviews-banner-container"
        aria-label="Client Reviews Section"
      >
        <div className="line-container-box" aria-hidden="true">
          <div className="dot left-dot" aria-hidden="true"></div>
          <div className="line" role="presentation"></div>
          <div className="dot right-dot" aria-hidden="true"></div>
        </div>
        <h2 className="title-line">What our clients say about us</h2>
        <div className="png-container">
          <ReviewSliderDesktop />
        </div>
      </section>
    </>
  );
};

export default ClientsReviewsBanner;
