import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../AboutUs/AboutUs.css";
import { Helmet } from "react-helmet";
import { teamMembers } from "../../../utils/TeamsData/teamsData";
import TeamMemberCard from "./TeamMemberCards";
import {
  aboutUsPic,
  avatarPic,
  bgImage,
} from "../../../utils/ImagesData/imagesData";

function AboutUS() {
  return (
    <section className="about-us-section">
      <Navbar />
      <Helmet>
        <title>About Us -Finconic</title>
        <meta name="description" content="your iconic banking partner" />
      </Helmet>
      {/* 1-card */}

      <section className="main-container-row">
        <div className="flex-container-herosection-row-about">
          <div className="content-flex-row-one-content-about">
            <h1 className="about-us-main-heading">We're all about your</h1>
            <h2 className="about-us-second-heading">FINANCIAL NEEDS</h2>
            <p className="about-us-description-text">
              At Finconic, we're dedicated to addressing your financial
              requirements with precision and care. Whether you seek loans, or
              financial guidance, our seasoned team is here to provide tailored
              solutions. Trust us to empower your financial journey.
            </p>
          </div>
          <div className="about-us-pic-parent">
            <img
              className="about-us-pic"
              src={aboutUsPic}
              alt="Financial Team"
            />
          </div>
        </div>
      </section>

      {/* 2-card */}

      <section className="herosection-flex-row color-gradient">
        <div className="inner-section-one-md-row">
          <div className="content-col-md-h2">
            <div className="content-col-md-left">
              <h2>Who We Are?</h2>
            </div>
            <div className="content-col-md-right">
              <p>
                A customer-centric brand, specializes in providing comprehensive
                financial solutions to businesses. With a 360-degree approach,
                we expedite access to funds at highly competitive rates. Our
                unique strength lies in connecting businesses with India's
                premier lenders, ensuring they receive swift and tailored
                financial support.
              </p>
              <p>
                we understand the unique needs of each business and work closely
                with them to secure the funding they require. Our mission is to
                facilitate financial success by bridging the gap between
                businesses and top-tier lenders, making the process as seamless
                and efficient as possible.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* 3-card */}

      {/* <section className="video-container-section">
        <div className="video-box-container">
          <img className="video-box" src={videoPic} alt="Video Placeholder" />
        </div>
      </section> */}

      <div className="video-container-section">
        <iframe
          height={"420px"}
          width={"847px"}
          style={{
            borderRadius: "10px",
            boxShadow: "4px 4px 20px 10px rgba(0, 0, 0, 0.1)",
          }}
          src="https://www.youtube.com/embed/8Xzfceq29ts?si=nGVQxkWzAFyV_H5l"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      {/* 4-card */}
      <section className="herosection-flex-row ">
        <div className="inner-section-two-md-row">
          <div className="content-col-md-div">
            <div className="content-col-md-top">Our Vision</div>
            <div className="content-col-md-bottom grid grid-cols-12">
              <p className=" col-span-6">
                "Empower your enterprise with ease. Finconic offers unsecured
                business loans, flexible dropline limits, and CGTMSE support,
                making access to funds a breeze. Our streamlined approach
                ensures you experience effortless disbursals, transforming your
                journey into one of financial freedom."
              </p>
              <p className=" col-span-6">
                "Bid farewell to cumbersome paperwork. With Finconic, you can
                achieve your business goals swiftly through a hassle-free,
                one-time documentation process that simplifies the path to
                financial success."
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* 5-card */}

      <section className="team-flipper-section">
        <p className="team-flipper-title">Let’s meet our team</p>
        <div className="team-flipper">
          <div className="flipper-card">
            {teamMembers.map((team, i) => (
              <TeamMemberCard key={i} profile={team} />
            ))}
          </div>
        </div>
      </section>

      {/* 6-card */}

      <section className="herosection-flex-row-features bg-color-md">
        <div className="padding">
          <div className="flex-row-space-between">
            <div className="card-five-card">
              <h2>Our Specialization</h2>
              <p>
                Our expertise lies in a streamlined loan disbursement,
                minimizing paperwork and providing ongoing support.
              </p>
            </div>
            <div className="card-five-card">
              <h2>Seamless Process</h2>
              <p>
                Our loan disbursal process is seamless, ensuring you receive
                funds promptly and making your financial journey hassle-free.
              </p>
            </div>
          </div>
          <div className="position-top">
            <img src={avatarPic} alt="Avatar Image" />
          </div>
          <div className="flex-row-space-between">
            <div className="card-five-card">
              <h2>Less ROI</h2>
              <p>
                Secure funds with the most favorable interest rates for your
                financial needs, reducing the cost of borrowing significantly.
              </p>
            </div>
            <div className="card-five-card">
              <h2>Data Security</h2>
              <p>
                Data Security is our core commitment. We employ stringent
                protocols to safeguard your sensitive information.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* 7-card */}

      <section className="bg-image-card">
        <img
          src={bgImage}
          style={{
            height: "100%",
            marginTop: "-11.5rem",
            marginBottom: "0.5rem",
          }}
          alt="Background image"
        />
      </section>

      <section className="footer-container">
        <Footer />
      </section>
    </section>
  );
}

export default AboutUS;
