import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import SingleDocument from "./SIngleDocument";
import "./DocumentRequired.css";

const DocumentRequired = ({
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
}) => {
  const companyTypes = [
    { id: "pvtLtd", label: "Private Ltd/ Limited", data: pvtLtdDocumentData },
    {
      id: "partnership",
      label: "Partnership/ LLP",
      data: partnershipDocumentData,
    },
    {
      id: "properitorship",
      label: "Individual/ Proprietorship",
      data: proprietorshipDocumentData,
    },
  ];

  const [selectedCompanyType, setSelectedCompanyType] = useState(
    companyTypes[0].id
  );
  const [loading, setLoading] = useState(false);

  const handleCompanyTypeChange = async (companyId) => {
    setLoading(true);
    setSelectedCompanyType(companyId);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setLoading(false);
  };

  return (
    <div className="outer-section-flex-row">
      <h2 className="my-6">Documents Required</h2>
      <div className="grid grid-cols-12">
        <div className="col-span-3 items-center flex flex-col">
          {companyTypes.map((companyType) => (
            <div
              key={companyType.id}
              className={`company-type-option ${
                selectedCompanyType === companyType.id ? "selected" : ""
              }`}
              onClick={() => handleCompanyTypeChange(companyType.id)}
            >
              {companyType.label}
            </div>
          ))}
        </div>
        <div className="col-span-9">
          <div className="documents-list-container">
            {loading ? (
              <div className="documents-list-loader-container">
                <div className="documents-list-loader">
                  <CircularProgress
                    size={100}
                    style={{ color: "#0b3c7f" }}
                    thickness={1.5}
                  />
                </div>
              </div>
            ) : (
              <div className="document-card-container">
                {companyTypes
                  .find((companyType) => companyType.id === selectedCompanyType)
                  ?.data.map((document) => (
                    <SingleDocument key={document.id} document={document} />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentRequired;
