import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../UsedCarLoan/UsedCarLoan.css";
function UsedCarLoan() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input
    const phoneNumberDigits = value.replace(/\D/g, "");
    // Limit the phone number to 10 digits
    const limitedPhoneNumber = phoneNumberDigits.slice(0, 10);
    setPhoneNumber(limitedPhoneNumber);
  };
  return (
    <>
      <Navbar />

      <section className="herosection-flex-img-container-md">
        <div className=" inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src="/HeaderImg/car loan.png"
              alt=""
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Used Car</h2>
            <h2 className="heading-h2">Loan</h2>
            <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex">Apply Now</button>
            </div>
          </div>
        </div>
      </section>
      {/* para */}
      <div className="busniness-content-p">
        <p>
          This type of car loan can be taken to purchase a pre-owned or used car
          from the pre owner of the car. The car must not be older than 5 years
          and should not exceed 10 years at the time of maturity of loan.
        </p>
      </div>
      {/* rotating card */}
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col">
            <h1>Features of Used Car Loan</h1>
            <ul>
              <li>Interest Rate from 12-18% p.a</li>
              <li>70-85% financing at value of the used car</li>
              <li>Easy payable EMI</li>
              <li>Tenure from 1-5 years</li>
              <li>Quick Disbursal</li>
            </ul>
          </div>
          <div className="image-md">
            <img
              className="img-1
                    "
              src="/CardImg/Component 50.png"
              alt=""
            />
            <img className="img-2" src="/CardImg/01.png" alt="" />
          </div>
        </div>
      </div>
      {/* floating card */}
      <div className="container-flex-row-bg-color">
        <div className="inner-container-one">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Used Car Loan?</h2>
            <ul className="list-items">
              <li>
                Individuals, proprietorships, companies, partnership firms,
                trusts and societies
              </li>
              <li>Loan Amount - 1 Lac to 5 Crore.</li>
              <li>Minimum age of 24 years.</li>
              <li> Maximum age of 60 years.</li>
              <li>Total working experience minimum 2years.</li>
              <li>Experience in current employment/business minimum 2 year.</li>
            </ul>
          </div>
          <div className="card-image-flex floating">
            <img src="/Image/card-img.png" alt="" />
          </div>
        </div>
      </div>
      {/* rombus card */}
      <div className="outer-section-flex-row">
        <h2>Documents Required</h2>
        <div className="card-flex-row-md">
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-2.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-3.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-1.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-4.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UsedCarLoan;
