import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "./DroplineLimit.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import {
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
} from "../../../utils/DocumentsRequiredLists/droplineLimitDocuments";
import DocumentRequired from "../DocumentRequired/DocumentRequired";
import {
  droplineFactors,
  droplinePic,
  featuresPic,
  floatingImage,
  partner1,
  partner2,
  partner7,
  partner8,
  partner9,
  rectangleFooterImage,
} from "../../../utils/ImagesData/imagesData";

const DroplineLimit = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="dropline-limit-container-section">
      <Helmet>
        <title>Dropline Limit - Finconic</title>
        <meta name="description" content="Contact us" />
      </Helmet>
      <Navbar />

      <section className="herosection-flex-img-container-md-dropline">
        <div className="inner-container-flex-row">
          <div className=" img-section-left-dropline">
            <img src={droplinePic} alt="" />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Unsecured Dropline Limit</h2>
            <p>
              The dropline overdraft is almost similar in all the cases except
              in new cases as there is the availability of the limit. This
              withdrawal limit reduces each month from the limit which is
              sanctioned. The calculation of the Interest rate is done on a
              daily basis and it is charged at the month’s end.
            </p>
            {/* <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex-apply">Apply Now</button>
            </div> */}
            <div className="div-main-btn-container-dropline mt-20">
              <button
                className="cta-btn-flex-dropline"
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                Reach Out to Us{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="dropline-content-p">
        <p>
          Drop-line Overdraft (DLOD) is a facility granted to the customer by
          the financial institution where businesses can overdraw from their
          current account up to a limit that is agreed upon by the banker.
          Overdraft is one of the most efficient forms of borrowing as one needs
          to pay interest only for the amount of money withdrawn.
        </p>
        <p>
          The dropline overdraft is almost similar in all the cases except in
          new cases as there is the availability of the limit. This withdrawal
          limit reduces each month from the limit which is sanctioned. The
          calculation of the Interest rate is done on a daily basis and it is
          charged at the month’s end. You will be charged only for the amount
          used, So, you can always park your funds in a virtual account whenever
          funds are not in use.
        </p>
      </div>
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col pt-10">
            <h1>Features of Unsecured Dropline Limit</h1>
            <ul className="text-content-flex-col-list">
              <li>
                Withdrawal limit reduces each month from initially sanctioned
                limit
              </li>
              <li>Comes as both secured and unsecured loan</li>
              <li>
                Do not require collateral to be submitted, in case of unsecured
                Dropline Overdraft facility
              </li>
              <li>
                Interest rate is charged on a monthly basis but calculated on a
                daily basis
              </li>
              <li>
                Borrowing limit can go up to Rs. 15 crores, however, it depends
                on the Bank’s sole discretion
              </li>
              <li>Borrowed amount is credited to only current accounts</li>
              <li>
                Can be used on a monthly, quarterly, half-yearly or yearly basis
              </li>
              <li>
                Usually the Dropline limit is assigned for 1 – 15 years, it may
                vary from bank to bank
              </li>
              <li>One-time processing fee is charged</li>
              <li>Do not levy yearly renewal charge</li>
              <li>It is a mix of term loan and overdraft facility</li>
              <li>Opted mostly by manufacturers, retailers and traders</li>
            </ul>
          </div>
          <div className="image-md">
            <img src={featuresPic} alt="Features Image" />
          </div>
        </div>
      </div>
      <div className="outer-section-flex-row-dropline card-one">
        <h2>Factors Affecting the Unsecured Dropline Limit Amount</h2>
        <div className="card-one-flex-dropline">
          <div className="card-img-one-md-dropline">
            <img src={droplineFactors} alt="" />
          </div>
          <div className="card-container-box-dropline" data-aos="fade-left">
            <div className="one-card" data-aos="fade-left">
              <h3>Credit Score/ Credit History</h3>
            </div>
            <div className="two-card" data-aos="fade-left">
              <h3>Income/ Financial Stability</h3>
            </div>
            <div className="three-card" data-aos="fade-left">
              <h3>Debt to income Ratio</h3>
            </div>
            <div className="four-card" data-aos="fade-left">
              <h3>Lender Policies</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-flex-row">
        <div className="inner-container">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Unsecured Dropline Limit?</h2>
            <p>
              Any entrepreneur, proprietor, self-employed professional, a
              private company, or partnership firm will get access to the DLOD
              facility.
            </p>
            <p>
              Unsecured dropline limits are typically available to individuals
              and businesses with a strong credit history and financial
              stability.
            </p>
            <p>
              Eligibility often depends on factors such as credit score, income,
              and repayment capacity.
            </p>
          </div>
          <div className="card-image-flex floating">
            <img src={floatingImage} alt="floating image" />
          </div>
        </div>
      </div>

      <DocumentRequired
        pvtLtdDocumentData={pvtLtdDocumentData}
        partnershipDocumentData={partnershipDocumentData}
        proprietorshipDocumentData={proprietorshipDocumentData}
      />
      <div className="partner-list-container-dropline">
        <img
          src={rectangleFooterImage}
          className="rectangle-footer-image w-screen "
          alt=""
        />
        <h2 className="">Our Partners</h2>
        <div className="company-logos-dropline">
          <div className="company-logo-image-desktop">
            <img src={partner1} alt="Partner Image 1" />
          </div>
          <div className="company-logo-image-desktop">
            <img src={partner2} alt="Partner Image 2" />
          </div>
          <div className="company-logo-image-desktop">
            <img src={partner7} alt="Partner Image 3" />
          </div>
          <div className="company-logo-image-desktop">
            <img src={partner8} alt="Partner Image 4" />
          </div>
          <div className="company-logo-image-desktop">
            <img src={partner9} alt="Partner Image 5" />
          </div>
        </div>
      </div>
      <section className="dropline-limit-footer-container">
        <Footer />
      </section>
    </section>
  );
};

export default DroplineLimit;
