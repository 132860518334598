import React from "react";
import "./reviewSliderDesktop.css";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
import Carousel from "./Carousel";
import { reviewsData } from "../../utils/ReviewsData/reviewsData";

const ReviewSlider = () => {
  let cards = reviewsData.map((data) => {
    return {
      key: uuidv4(),
      content: (
        <Card
          imagen={data.image}
          name={data.name}
          rating={data.rating}
          review={data.reviewText}
        />
      ),
    };
  });
  return (
    <div className="reviw-slider-desktop">
      <Carousel cards={cards} margin="0 auto" offset={2} showArrows={false} />
    </div>
  );
};

export default ReviewSlider;
