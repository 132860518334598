import React, { useEffect, useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "./BusinessLoan.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import DocumentRequired from "../DocumentRequired/DocumentRequired";
import {
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
} from "../../../utils/DocumentsRequiredLists/businessLoanDocuments";
import {
  businessLoanTopPic,
  factorsPic,
  featuresPic,
  floatingImage,
} from "../../../utils/ImagesData/imagesData";

const BusinessLoan = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <section className="unsecured-business-loan-container-section">
      <Helmet>
        <title>Unsecured Bussiness Loan - Finconic</title>
        <meta name="description" content="Contact us" />
      </Helmet>
      <Navbar />

      <section className="herosection-flex-img-container-md">
        <div className="inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src={businessLoanTopPic}
              alt="business loan top pic"
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Unsecured Business Loan</h2>
            <p>
              In today’s dynamic business environment, funds may be required in
              a business for the purpose of funding a startup or for the
              expansion purpose.
            </p>

            <div className="div-main-btn-container">
              <button
                className="cta-btn-flex"
                onClick={() => {
                  window.location.href = "/contact";
                }}
              >
                Reach Out to Us{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="business-content-p">
        <p>
          Business loan is the loan amount which is to be used specifically for
          business purposes. In today’s dynamic business environment, funds may
          be required in a business for the purpose of funding a startup or for
          the expansion purpose. A business loan is a debt which is repayable
          back with the interest in the pre agreed period of time and with
          certain terms and conditions.
        </p>
        <p>
          High competition in the economy has generated the high demand of funds
          for the smooth running of business. Business Loan fulfills the fund
          requirement of any business either short term or long term. Business
          loan ensures the smooth cash flow in the business and helps to face
          the business challenges from time to time.
        </p>
      </div>
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col">
            <h1>Features of Unsecured Business Loan</h1>
            <ul className="text-content-flex-col-list">
              <li>Quick approval</li>
              <li>Low rate of interest</li>
              <li>Attractive bundled offerings</li>
              <li>No Collateral</li>
              <li>Less Documentation</li>
              <li>Long term & short term financing</li>
              <li>Flexible Tenure</li>
              <li>Large Capital</li>
            </ul>
          </div>
          <div className="image-md">
            <img src={featuresPic} alt="" />
          </div>
        </div>
      </div>
      <div className="outer-section-flex-row card-one">
        <h2>Factors Affecting the Business Loan Amount</h2>
        <div className="card-one-flex">
          <div className="card-img-one-md">
            <img src={factorsPic} alt="Factors pic" />
          </div>
          <div className="card-container-box" data-aos="fade-left">
            <div className="one-card" data-aos="fade-left">
              <h3>Repayment Capacity</h3>
            </div>
            <div className="two-card" data-aos="fade-left">
              <h3>Average Profit earned from the business</h3>
            </div>
            <div className="three-card" data-aos="fade-left">
              <h3>Cash flow of the business</h3>
            </div>
            <div className="four-card" data-aos="fade-left">
              <h3>Years of existence of the business</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-flex-row">
        <div className="inner-container">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Business Loan?</h2>
            <p>
              Private Limited / Limited companies, Partnership firms / LLP,
              Proprietorship firm or an Individual
            </p>
            <p>Business must have at least 3 years of existence</p>
            <p>ITR filing for 2 years</p>
          </div>
          <div className="card-image-flex floating">
            <img src={floatingImage} alt="floating image" />
          </div>
        </div>
      </div>

      <DocumentRequired
        pvtLtdDocumentData={pvtLtdDocumentData}
        partnershipDocumentData={partnershipDocumentData}
        proprietorshipDocumentData={proprietorshipDocumentData}
      />

      <section className="business-loan-footer-container">
        <Footer />
      </section>
    </section>
  );
};

export default BusinessLoan;
