import React, { useState } from "react";
// import Animation from '../../CircularAnimation/Animation';
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../ProfessionalLoan/ProfessionalLoan.css";
function ProfessionalLoan() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input
    const phoneNumberDigits = value.replace(/\D/g, "");
    // Limit the phone number to 10 digits
    const limitedPhoneNumber = phoneNumberDigits.slice(0, 10);
    setPhoneNumber(limitedPhoneNumber);
  };
  return (
    <>
      <Navbar />

      <section className="herosection-flex-img-container-md">
        <div className=" inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src="/HeaderImg/professional loan (1).png"
              alt=""
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Professional Loan</h2>
            <p>
              Professional is a person who is professionally qualified in a
              specialized activity like doctors, chartered accountants,
              engineers etc.
            </p>
            <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex">Apply Now</button>
            </div>
          </div>
        </div>
      </section>
      {/* para */}
      <div className="busniness-content-p">
        <p>
          Professional is a person who is professionally qualified in a
          specialized activity like doctors, chartered accountants, engineers
          etc.
        </p>
        <p>
          This loan is meant for self-employed persons who are professional by
          qualification. Professional loan helps to grow the business.
          Professional loan is a personal unsecured loan which means it do not
          require any collateral security to apply for a loan. Though collateral
          is not mandatory but in case if any collateral is provided it can
          raise the loan amount or decrease the interest rate or may increase
          the loan tenure. It takes minimum efforts to apply and quick approval.
        </p>
      </div>
      {/* <div className='container-outer'>
                <img src="/Image/Groupimg.png" alt="" />
            </div> */}
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col">
            <h1>Features of Professional Loan</h1>
            <ul>
              <li>Quick approval</li>
              <li>Low rate of interest</li>
              <li>Attractive bundled offerings</li>
              <li>No Collateral</li>
              <li>Less Documentation</li>
              <li>Long tern & short term financing</li>
              <li>Flexible Tenure</li>
              <li>Large Capital</li>
            </ul>
          </div>
          <div className="image-md">
            <img
              className="img-1
                    "
              src="/CardImg/Component 50.png"
              alt=""
            />
            <img className="img-2" src="/CardImg/01.png" alt="" />
          </div>
        </div>
      </div>
      <div></div>
      {/* card-floatcard */}
      <div className="container-flex-row-bg-color">
        <div className="inner-container-one">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Professional Loan?</h2>
            <p>Age should be 18-65 years</p>
            <p>
              Individual/Joint borrowers/partnership
              firms/companies/proprietorships can apply for this loan
            </p>
            <p>
              Professionals who are already into the practice of their
              profession/trade with appropriate licenses.
            </p>
            <p>
              In case of Group of individual, every member of the group must
              practice the profession with license.
            </p>
            <p>
              Professional means the people having diploma/degree to skillfully
              practice the profession like doctors, lawyers, chartered
              accountants etc.
            </p>
          </div>
          <div className="card-image-flex floating">
            <img src="/Image/card-img.png" alt="" />
          </div>
        </div>
      </div>
      <div className="outer-cont-er-md">
        <div className="card-container-one-md">
          <h2>Loans for Doctors</h2>
          <p>
            This business loan is provided to doctors who are practicing or
            giving consultation in government/private clinic or hospitals with
            at least 4 years of past experience. Qualification degree should be
            MBBS/BDS/MS/MD/MDS.
          </p>

          <p>Features</p>
          <p>
            Loan amount can be used to expand or renovate your hospital/clinic
            or to purchase new equipment etc.
          </p>
          <p>
            Loan Amount can be taken up to Rs. 1 crore without any security and
            Rs. 2 crore with mortgage.
          </p>
          <p>Loan tenure will be 1 â€“ 5 years.</p>
          <p>Interest rate starting from 9% p.a.</p>
        </div>
        <div className="card-container-one-md">
          <h2>Loans for Doctors</h2>
          <p>
            This business loan is provided to doctors who are practicing or
            giving consultation in government/private clinic or hospitals with
            at least 4 years of past experience. Qualification degree should be
            MBBS/BDS/MS/MD/MDS.
          </p>
          <p>Features</p>
          <p>
            Loan amount can be used to expand or renovate your hospital/clinic
            or to purchase new equipment etc.
          </p>
          <p>
            Loan Amount can be taken up to Rs. 1 crore without any security and
            Rs. 2 crore with mortgage.
          </p>
          <p>Loan tenure will be 1 â€“ 5 years.</p>
          <p>Interest rate starting from 9% p.a.</p>
        </div>
        <div className="card-container-one-md">
          <h2>Loans for Doctors</h2>
          <p>
            This business loan is provided to doctors who are practicing or
            giving consultation in government/private clinic or hospitals with
            at least 4 years of past experience. Qualification degree should be
            MBBS/BDS/MS/MD/MDS.
          </p>
          <p>Features</p>
          <p>
            Loan amount can be used to expand or renovate your hospital/clinic
            or to purchase new equipment etc.
          </p>
          <p>
            Loan Amount can be taken up to Rs. 1 crore without any security and
            Rs. 2 crore with mortgage.
          </p>
          <p>Loan tenure will be 1 â€“ 5 years.</p>
          <p>Interest rate starting from 9% p.a.</p>
        </div>
      </div>
      {/* card-hexa */}
      <div className="outer-section-flex-row">
        <h2>Documents Required</h2>
        <div className="card-flex-row-md">
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-2.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-3.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-1.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-4.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProfessionalLoan;
