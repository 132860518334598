import React from 'react'

const DropDownIcon1 = () => {
  return (
    <svg
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.5 6L0.602886 0.75H8.39711L4.5 6Z" fill="#0B3C7F" />
    </svg>
  );
}

export default DropDownIcon1