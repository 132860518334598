import React from "react";
import "../HomePage/HomePage.css";
import CardInfo from "../../Card-Info/CardInfo";
import Card from "../../Card/Card";
import Footer from "../../Footer/Footer";
// import Herosection from "../../HeroSection/Herosection";
// import Work from "../../HowItWork/Work";
// import InfoSec from "../../InformationSection/InfoSec";
import Navbar from "../../Navbar/Navbar";
// import OurService from "../../Our-Service/OurService";
// import Subscribe from '../../Subscribe/Subscribe';
import ContentCard from "../../ContentCardSlide/ContentCard";
// import AutoSlider from "../../AutoSlider/AutoSlider";
import HomePageBanner from "../../Banners/HomePageBanner/HomePageBanner";
import ServicesBanner from "../../Banners/ServicesBanner/ServicesBanner";
import AboutUsBanner from "../../Banners/AboutUsBanner/AboutUsBanner";
import ClientsReviewsBanner from "../../Banners/ClientsReviewsBanner/ClientsReviewsBanner";
import { Helmet } from "react-helmet";

function HomePage() {
  return (
    <div>
      <Helmet>
        <title>Home - Finconic</title>
        <meta name="description" content="Home" />
      </Helmet>
      <div className="bg-one">
        <Navbar />
        {/* <Herosection/> */}
        {/* <AutoSlider /> */}
        <HomePageBanner />
        <div className="card-flex-row">
          <div className="style-card">
            <CardInfo />
          </div>
        </div>
        <ServicesBanner />
        <AboutUsBanner />
        {/* <Card /> */}
        {/* <InfoSec /> */}
        {/* <Work /> */}
        <ClientsReviewsBanner />
        <ContentCard />
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
