import {
  ReviewRobinGarg,
  reviewAbhayDeepDubey,
  reviewAbhinavGoel,
  reviewAmit,
  reviewSaurabhWahi,
} from "../ImagesData/imagesData";

export const reviewsData = [
  {
    name: "Abhay Deep Dubey",
    rating: 4.4,
    image: reviewAbhayDeepDubey,
    reviewText:
      "I can't say enough about my fantastic experience with Finconic. They made the loan application process a breeze, and I got the funds I needed in no time. The team was responsive and attentive to my needs, and the interest rates were very competitive. I highly recommend Finconic to anyone in need of financial support",
  },
  {
    name: "Abhinav goel",
    rating: 3.5,
    image: reviewAbhinavGoel,
    reviewText:
      "Finconic provided me with a loan when I needed it most. The application process was relatively easy, and the approval was quick. The only reason I'm not giving a full 5 stars is because the interest rate was a bit higher than I had hoped for. However, their customer service was excellent, and I felt supported throughout the process",
  },
  {
    name: "Amit",
    rating: 3.7,
    image: reviewAmit,
    reviewText:
      "Finconic truly exceeded my expectations. I was hesitant about getting a loan, but their transparency and professionalism put my mind at ease. I appreciated that they offered various loan options. The entire team was incredibly friendly and helpful. I would absolutely recommend Finconic to anyone looking for a reliable loan provider",
  },
  {
    name: "Saurabh Wahi",
    rating: 5,
    image: reviewSaurabhWahi,
    reviewText:
      "Finconic exceeded my expectations. Their loan services are efficient, trustworthy, and customer-centric. A highly recommended choice for anyone seeking financial solutions.",
  },
  {
    name: "Robin Garg",
    rating: 4.7,
    image: ReviewRobinGarg,
    reviewText:
      "My experience with Finconic was decent. They processed my loan application relatively quickly, and I got the funds I needed. However, their interest rates were on the higher side.",
  },
];
