import React, { useEffect } from "react";
import Footer from "../../../Components/MobileScreens/Footer/MobileFooter";
import RequiredDocuments from "../RequiredDocuments/RequiredDocuments";
import {
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
} from "../../../utils/DocumentsRequiredLists/businessLoanDocuments";
import "./mobileUnsecuredLoan.css";
import Aos from "aos";
import { Helmet } from "react-helmet";
import {
  businessElgibleLoanImg,
  featuresPic,
  mobileFactorsUSLImage,
  mobileUnsecureLoanTopLogo,
} from "../../../utils/ImagesData/imagesData";

const MobileUnsecuredLoan = () => {
  const featuresList = [
    "Quick approval",
    "Low rate of interest",
    "Attractive bundled offerings",
    "No Collateral",
    "Less Documentation",
    "Long term and Short term financing",
    "Flexible Tenure",
    "Large Capital",
  ];

  const fatorsList = [
    "Repayment Capacity",
    "Average Profit earned from the business",
    "Cash flow of the business",
    "Years of existence of the business",
  ];

  const companies = ["company 1", "company 2", "company 3", "company 4"];

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>Unsecured Loan - Finconic</title>
        <meta name="description" content="Unsecured Business Loan Details" />
      </Helmet>

      <div className="mobileUnsecuredLoan">
        {/* Top Section */}
        <div className="mobileUnsecuredLoan-top">
          <div className="mobileUnsecuredLoan-top-image">
            <img src={mobileUnsecureLoanTopLogo} alt="Unsecured Loan Image" />
          </div>
          <div className="mobileUnsecuredLoan-top-headings">
            <h2>Unsecured Business Loan</h2>
          </div>
          <div className="mobileUnsecuredLoan-top-desc">
            <p>
              In today’s dynamic business environment, funds may be required in
              a business for the purpose of funding a startup or for the
              expansion purpose.
            </p>
          </div>
          <div className="mobileUnsecuredLoan-top-button">
            <button
              className="cta-btn-flex"
              onClick={() => (window.location.href = "/contact")}
            >
              Reach Out to Us
            </button>
          </div>
        </div>

        {/*Description */}
        <div className="mobileUnsecuredLoan-desc">
          <p>
            Business loan is the loan amount which is to be used specifically
            for business purposes. In today’s dynamic business environment,
            funds may be required in a business for the purpose of funding a
            startup or for the expansion purpose. A business loan is a debt
            which is repayable back with the interest in the pre agreed period
            of time and with certain terms and conditions.
          </p>
          <p>
            High competition in the economy has generated the high demand of
            funds for the smooth running of business. Business Loan fulfills the
            fund requirement of any business either short term or long term.
            Business loan ensures the smooth cash flow in the business and helps
            to face the business challenges from time to time.
          </p>
        </div>

        {/* Features */}
        <div className="mobileUnsecuredLoan-features">
          <div className="mobileUnsecuredLoan-features-heading">
            <h2>Features of Unsecured Business Loan</h2>
          </div>
          <div className="mobileUnsecuredLoan-features-image">
            <img src={featuresPic} alt="" />
          </div>
          <div className="mobileUnsecuredLoan-features-lists">
            <ul>
              {featuresList.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Factors */}
        <div className="mobileUnsecuredLoan-factors">
          <div className="mobileUnsecuredLoan-factors-heading">
            <h2>Factors Affecting the Business Loan Amount</h2>
          </div>
          <div className="mobileUnsecuredLoan-factors-img">
            <img src={mobileFactorsUSLImage} alt="" />
          </div>
          <div className="mobileUnsecuredLoan-factors-tabs">
            {fatorsList.map((factor, i) => (
              <div key={i} data-aos="fade-up" data-aos-duration="2000">
                {factor}
              </div>
            ))}
          </div>
        </div>

        {/* Eligible for business loan */}
        <div className="mobileBusinessLoan-eligible">
          <div className="mobileBusinessLoan-eligible-heading">
            <h2>Who is Eligible</h2>
            <span>for the Business Loan?</span>
          </div>
          <div className="mobileBusinessLoan-eligible-img">
            <img src={businessElgibleLoanImg} alt="" />
          </div>
          <div className="mobileBusinessLoan-eligible-desc">
            <p>
              Private Limited / Limited companies, Partnership firms / LLP,
              Proprietorship firm or an Individual
            </p>
            <p>Business must have at least 3 years of existence</p>
            <p>ITR filing for 2 years</p>
          </div>
        </div>

        {/* Documents Required */}
        <div className="mobileUnsecuredLoan-documentsRequired">
          <div className="mobileUnsecuredLoan-documentsRequired-heading">
            <h2>Documents Required</h2>
          </div>
          <div className="mobileUnsecuredLoan-documentsRequired-lists">
            <RequiredDocuments
              pvtLtdDocumentData={pvtLtdDocumentData}
              partnershipDocumentData={partnershipDocumentData}
              proprietorshipDocumentData={proprietorshipDocumentData}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MobileUnsecuredLoan;
