import React from "react";
import Navbar from "../../Navbar/Navbar";
import "./home.css";
import HomeCard from "./HomeCard";
import { NavLink } from "react-router-dom";
import ReviewSlider from "../../ReviewSlider/ReviewSlider";
import PartnerCards from "../../MobileScreens/HomePage/PartnerCards/PartnerCards";
import { Helmet } from "react-helmet";
import Footer from "../../../Components/MobileScreens/Footer/MobileFooter";
import {
  aboutPic,
  chatGif,
  servicesGif,
  dataSecurityPic,
  homePageTopIconStocks,
  bussinessLogo,
  droplineLogo,
  cgtmseLogo
} from "../../../utils/ImagesData/imagesData";

const Home = () => {
  const navLinks = [
    {
      id: 1,
      text: "Unsecured Business Loan",
      to: "/business-loan",
      img: bussinessLogo,
    },
    {
      id: 2,
      text: "Unsecured Dropline Limit",
      to: "/dropline-limit",
      img: droplineLogo,
    },
    {
      id: 3,
      text: "CGTMSE",
      to: "/cgtmse",
      img: cgtmseLogo,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Home - Finconic</title>
        <meta name="description" content="Home" />
      </Helmet>
      <div className="mobileHome">
        <div>
          {/* Main Top */}
          <div className="homePageTopSection relative">
            <img
              src={homePageTopIconStocks}
              alt="Home page Image"
              className="px-8 mt-[6rem] m-auto h-[36rem] w-[40rem]"
            />
            <div className="homePageTopSectionHeading">
              <h2 className="homePageTopSectionHeading-1">
                Empowering Enterprises
              </h2>
              <h2 className="homePageTopSectionHeading-2 mt-4 mb-2">
                Need financial support?
              </h2>
              <h2 className="homePageTopSectionHeading-3">We Got Your Back</h2>
            </div>
            <div className="homePageTopSectionDescription px-16 mt-5">
              <p>
                Access unsecured business loans, flexible dropline limits and
                CGTMSE support effortlessly. Experience seamless disbursals and
                streamline your journey with hassle-free one-time documentation.
              </p>
            </div>
            <button
              className="homePageTopSectionButton"
              onClick={() => (window.location.href = "/contact")}
            >
              Reach Out to Us
            </button>
          </div>
          {/* Cards */}
          <div className=" flex flex-col justify-center items-center mt-[-7rem] -z-10">
            <HomeCard />
          </div>

          {/* Services */}
          <div className="serviceHomePage">
            <div className="servicesMainTagLine">
              <div className="serviceMainTagLine-left-dot serviceMainTagLine-dot"></div>
              <div className="serviceMainTagLine-left-line serviceMainTagLine-line"></div>
              <div className="mx-6 serviceMainTagLine-heading">
                Our Services
              </div>
              <div className="serviceMainTagLine-left-line serviceMainTagLine-line"></div>
              <div className="serviceMainTagLine-left-dot serviceMainTagLine-dot"></div>
            </div>
            <div className="serviceHomePage-content">
              <div className="serviceHomePage-content-desc">
                <h2 className="serviceHomePage-content-desc-heading">
                  What We Offer
                </h2>
                <p className="serviceHomePage-content-desc-para">
                  "Empowering Enterprises: Access unsecured business loans,
                  flexible dropline limits and CGTMSE support effortlessly.
                  Experience seamless disbursals and streamline your journey
                  with hassle-free one-time documentation."
                </p>
              </div>
              <div className="serviceHomePage-content-img">
                <img src={servicesGif} alt="" />
              </div>
            </div>
            <div className="serviceHomePage-links">
              {navLinks.map((link) => (
                <NavLink
                  to={link.to}
                  key={link.id}
                  onMouseEnter={() => handleMouseEnter(link.img)}
                  onMouseLeave={() => handleMouseLeave()}
                  className={"flex items-center"}
                >
                  <img
                    src={link.img}
                    alt="icons"
                    className="serviceHomePage-links-img"
                  />
                  <div className="serviceHomePage-links-text">{link.text}</div>
                </NavLink>
              ))}
            </div>
          </div>

          {/* About Us  */}
          <div className="aboutUsHomePage mt-10">
            <div className="servicesMainTagLine">
              <div className="serviceMainTagLine-left-dot serviceMainTagLine-dot"></div>
              <div className="serviceMainTagLine-left-line serviceMainTagLine-line"></div>
              <div className="mx-6 serviceMainTagLine-heading">About Us</div>
              <div className="serviceMainTagLine-left-line serviceMainTagLine-line"></div>
              <div className="serviceMainTagLine-left-dot serviceMainTagLine-dot"></div>
            </div>
            <div className="aboutUsHomePageContent mt-16">
              <div className="aboutUsHomePageContent-headings">
                <h4>Get to know more about </h4>
                <h2 className=" uppercase">FINCONIC</h2>
              </div>
              <div className="aboutUsHomePageContent-img">
                <img src={aboutPic} alt="About us image" />
              </div>
              <div className="aboutUsHomePageContent-desc">
                <p>
                  We focus on client service with integrated solutions to
                  arrange funds for the client’s business expansion or for any
                  other reason best known to them. We are driven by a dynamic
                  and multi disciplinary team of professionals.
                </p>
                <p>
                  We work on various products such as Business loans, OD
                  facility, CGTMSE etc. At Finconic we love working with
                  organizations from all walks of life no matter the amount of
                  fund required.
                </p>
              </div>
            </div>
          </div>

          {/* Data Security  */}
          <div className="dataSecurityHomePage mt-6">
            <div className="dataSecurityHomePage-heading">
              <h2>Data Security in our First Priority</h2>
            </div>
            <div className="dataSecurityHomePage-Img">
              <img src={dataSecurityPic} alt="Data Security Image" />
            </div>
            <div className="dataSecurityHomePage-desc">
              <p>
                At Finconic, data security reigns supreme. It's not just a
                priority; it's our core commitment. We employ cutting-edge
                technology and stringent protocols to safeguard your sensitive
                information.
              </p>
              <p>
                Trust us to keep your data locked down, ensuring your peace of
                mind while you focus on your financial goals. Your security is
                our paramount concern.
              </p>
            </div>
          </div>

          {/* Speed Support */}
          <div className="speedSupportHomePage mt-20">
            <div className="speedSupportHomePage-heading">
              Get Speedy Support
            </div>
            <div className="speedSupportHomePage-img">
              <img src={chatGif} alt="Speed Support Image" />
            </div>
            <div className="speedSupportHomePage-desc">
              <p>
                "At Finconic, we pride ourselves on delivering fast and reliable
                financial solutions. Whether you need a loan or financial
                guidance, we're here to provide quick and dependable assistance.
                Trust Finconic for prompt, expert support to meet your financial
                needs."
              </p>
            </div>
            <div className="speedSupportHomePage-button">
              <button
                className="cta-btn-flex"
                onClick={() => (window.location.href = "/contact")}
              >
                Contact Us Now
              </button>
            </div>
          </div>

          {/*Reviews For Home Page - What Our Client says */}
          <div className="reviewsHomePage mt-16">
            <div className="reviewsHomePage-top-line-dots">
              <div className="reviewsHomePage-top-dot"></div>
              <div className="reviewsHomePage-top-line"></div>
              <div className="reviewsHomePage-top-dot"></div>
            </div>
            <div className="reviewsHomePage-content mt-12">
              <div className="reviewsHomePage-heading">
                <h2>What our clients say about us</h2>
              </div>
              <div className="reviewsHomePage-slider mt-[-14.5rem]">
                <ReviewSlider />
              </div>
              <div>
                <PartnerCards />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
