import React, { useState, useRef, useEffect } from "react";
import "../ContactUs/ContactUs.css";
import Navbar from "../../Navbar/Navbar";
import { Toast } from "../../Toast/Toast";
import { MutatingDots } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import { useJsApiLoader } from "@react-google-maps/api";
import { FaUserAlt } from "react-icons/fa";
import { MdPhoneInTalk, MdEmail } from "react-icons/md";
import FooterCopyright from "../../Footer/FooterCopyright";
import { Helmet } from "react-helmet";
import ProgressBar from "../../ProgressBar/ProgressBar";
import axiosRequest from "../../../ApiRequests/Axios/axiosCaller";
import ReCAPTCHA from "react-google-recaptcha";
import {
  callContactPageIcon,
  contactUsPic,
  location_ContactUs_Icon,
  mail_Contact_Icon,
} from "../../../utils/ImagesData/imagesData";
import Footer from "../../Footer/Footer";

function ContactUs() {
  const recaptcha = useRef();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCLzmS5gALImEIuB_H9wmePTOvuxFAsLoI",
  });

  const formRef = useRef(null);
  const SuccessMsg = () => {
    toast.success("your information is successfully submitted", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const ErrorMsg = (msg = "your information not submitted") => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  <MutatingDots
    height="100"
    width="100"
    color="#4fa94d"
    secondaryColor="#4fa94d"
    radius="12.5"
    ariaLabel="mutating-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />;
  const IndianPhoneNumberRegex = /^(\+91[-s]?)?[0]?(91)?[6789]\d{0,9}$/;
  const IndianEmailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const firstNameRegex = /^[a-zA-Z.\s]+$/;
  const lastNameRegex = /^[a-zA-Z]+$/;
  const [firstName, setFirstName] = useState("");
  const [firstNamelabel, setFirstNamelabel] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNamelabel, setLastNamelabel] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberLabel, setPhoneNumberLabel] = useState(false);
  const [email, setEmail] = useState("");
  const [emailLabel, setEmailLabel] = useState(false);
  const [message, setMessage] = useState("");
  const [messageLabel, setMessageLabel] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [formdata, setFormdata] = useState({
    inputField1: "",
    inputField2: "",
    inputField3: "",
    inputField4: "",
    inputField5: "",
  });

  const [progress, setProgress] = useState(false);
  const [isButton, setIsButton] = useState(true);
  const [success, setSuccess] = useState(false);
  const [onClose, setOnClose] = useState(false);

  let data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phoneNumber,
    messageContent: message,
  };

  async function fetchData(event) {
    event.preventDefault();
    setProgress(true);
    setIsButton(false);
    // changable for recaptcha
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setProgress(false);
      ErrorMsg("Please fill the reCaptcha form");
      return;
    }
    // changable for recaptcha

    try {
      const response = await axiosRequest("post", "api/form/contactus", data);
      if (response.data) {
        // SuccessMsg();
        setOnClose(true);
        setSuccess(true);
        setIsButton(true);
        // setProgress(false);
        recaptcha.current.reset();
        setTimeout(() => {
          setOnClose(false);
          setSuccess(false);
          setIsButton(false);
          setProgress(false);
        }, 5000);
      } else {
        setOnClose(true);
        setProgress(false);
        recaptcha.current.reset();
        setTimeout(() => {
          setOnClose(false);
          setSuccess(false);
          setIsButton(false);
          setProgress(false);
        }, 3000);
        ErrorMsg();
      }
    } catch (error) {
      recaptcha.current.reset();
      ErrorMsg("Internal Server error");
      setProgress(false);
      setTimeout(() => {
        setOnClose(false);
        setSuccess(false);
        setIsButton(false);
        setProgress(false);
      }, 3000);
    }

    setFormdata({
      inputField1: "",
      inputField2: "",
      inputField3: "",
      inputField4: "",
      inputField5: "",
    });

    formRef.current.reset();

    setTimeout(() => {
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmail("");
      setMessage("");
    }, 500);
  }

  const handleFirstNameChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length > 100) {
      setFirstNameError("First name should not exceed 100 characters");
    } else {
      if (!firstNameRegex.test(inputValue) && inputValue.length > 0) {
        setFirstNameError("Please enter a valid name");
      } else {
        setFirstName(inputValue);
        setFirstNameError("");
      }
    }
  };

  const handleLastNameChange = (event) => {
    const inputValue = event.target.value.trim();
    if (inputValue.length > 100) {
      setLastNameError("Last name should not exceed 100 characters");
    } else if (!lastNameRegex.test(inputValue) && inputValue.length > 0) {
      setLastNameError("Please enter a valid name");
    } else {
      setLastName(inputValue);
      setLastNameError("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");

    if (value.length <= 10) {
      setPhoneNumber(value);

      if (value.length !== 0 && !IndianPhoneNumberRegex.test(value)) {
        setPhoneNumberError("Please enter a valid phone number");
      } else {
        setPhoneNumberError("");
      }
    }
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setEmailError("");
  };

  const handleMessageChange = (event) => {
    if (event.target.value.length > 450) {
      setMessageError("Please write your messsage within 450 words");
      return;
    }
    setMessage(event.target.value);

    if (event.target.value === "") {
      setMessageError("Please enter a message");
    } else {
      setMessageError("");
    }
  };

  const handleFirstNameLabel = () => {
    setFirstNamelabel(true);
  };

  // const handleLastNameFocus = () => {
  //   if (!firstNameRegex.test(firstName)) {
  //     setFirstNameError("Please enter a valid name");
  //   }
  // };

  const handleLastNameLabel = () => {
    setLastNamelabel(true);
  };

  // const handlePhoneNumberFocus = () => {
  //   if (!lastNameRegex.test(lastName)) {
  //     setLastNameError("Please enter a valid name");
  //   }
  // };

  const handlePhoneNumberLabel = () => {
    setPhoneNumberLabel(true);
  };

  // const handleEmailFocus = () => {
  //   if (!IndianPhoneNumberRegex.test(phoneNumber)) {
  //     setPhoneNumberError("Please enter a valid phone number");
  //   }
  // };

  const handleEmailLabel = () => {
    setEmailLabel(true);
    if (phoneNumber.length < 10) {
      setPhoneNumberError("Please enter a valid phone number");
    }
  };

  const handleMessageFocus = (event) => {
    const inputValue = event.target.value;
    if (inputValue !== "" && !IndianEmailRegex.test(inputValue)) {
      setEmailError("Please enter a valid email address");
    }
    // if (!IndianEmailRegex.test(email)) {
    //   setEmailError("Please enter a valid email address");
    // }
  };

  const handleMessageLabel = () => {
    setMessageLabel(true);
  };

  const openGoogleMaps = () => {
    // const location = "9/28+3rd+Floor,East+Patel+Nagar,New+Delhi,Delhi+110008";
    window.open(`https://maps.app.goo.gl/H4VqqivzFjQCJ3YG6`);
  };

  const openDialPad = () => {
    window.location.href = "tel:+919582189429";
  };

  return (
    isLoaded && (
      <div className="bg-[#fafafa]">
        {progress && (
          <>
            {onClose && (
              <button
                className=" absolute  w-screen h-screen z-[1100]"
                onClick={() => {
                  setProgress(false);
                  setOnClose(false);
                }}
              ></button>
            )}
            <ProgressBar
              isButton={isButton}
              success={success}
              setProgress={setProgress}
            />
          </>
        )}
        <Helmet>
          <title>Contact Us - Finconic</title>
          <meta name="description" content="Contact us" />
        </Helmet>
        <Navbar />
        <div className="bg-[#fafafa]">
          <div className="contact-us-container">
            <div className="flex-direction-col">
              <div className="outer-div">
                <div className="flex flex-col gap-8 form-headlines">
                  <h2>GET IN TOUCH</h2>
                  <h4>
                    Whether you’re curious about the product, facing some issue
                    or want a casual chit-chat, Our friendly team is here for
                    you.{" "}
                  </h4>
                  <h5>Just fill out the form below</h5>
                </div>

                <form
                  className="form-container "
                  autoComplete="off"
                  ref={formRef}
                  onSubmit={fetchData}
                  // action="htttp://httpbin.org/post"
                  // method="POST" //changable for backend reCaptcha
                >
                  <div className="flex-col gap-msone-custom">
                    <div className="input-fullname">
                      <div className="">
                        <label
                          htmlFor="firstNameId"
                          className={`absolute z-[95] text-[0.9rem] mt-1 ml-16 transition-all ${
                            firstNamelabel ? "text-[#A1A1A1] " : " hidden"
                          } `}
                        >
                          First name
                        </label>
                        <div>
                          <FaUserAlt className="icon-styles" />
                          <input
                            name="inputField1"
                            required
                            type="text"
                            id="firstNameId"
                            pattern="[A-Za-z. ]+"
                            inputMode="none"
                            value={firstName}
                            placeholder={`${
                              firstNamelabel ? "" : "First Name"
                            }`}
                            onChange={handleFirstNameChange}
                            className="input-fname-styles "
                            // onBlur={handleLastNameFocus}
                            onFocus={handleFirstNameLabel}
                            aria-label="Enter your First Name"
                          />
                          {firstNameError && (
                            <div
                              style={{
                                color: "red",
                                padding: "0.5rem",
                                paddingBottom: 0,
                              }}
                            >
                              {firstNameError}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="lastNameId"
                          className={`absolute z-[95] text-[0.9rem] mt-1 ml-16 transition-all ${
                            lastNamelabel ? "text-[#A1A1A1] " : " hidden"
                          } `}
                        >
                          Last name
                        </label>
                        <div>
                          <FaUserAlt className="icon-styles" />
                          <input
                            name="inputField1"
                            required
                            id="lastNameId"
                            type="text"
                            value={lastName}
                            placeholder={`${lastNamelabel ? "" : "Last Name"}`}
                            onChange={handleLastNameChange}
                            className="input-lname-styles "
                            // onBlur={handlePhoneNumberFocus}
                            aria-label="Enter your Last Name"
                            onFocus={handleLastNameLabel}
                          />
                          {lastNameError && (
                            <div
                              style={{
                                color: "red",
                                padding: "0.5rem",
                                paddingBottom: 0,
                              }}
                            >
                              {lastNameError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <br />
                    <div>
                      <label
                        htmlFor="phoneNameId"
                        className={`absolute z-[95] text-[0.9rem] mt-[0.8rem] ml-16 transition-all ${
                          phoneNumberLabel ? "text-[#A1A1A1] " : " hidden"
                        } `}
                      >
                        Phone number
                      </label>
                      <div className="input-contact">
                        <MdPhoneInTalk className="phone-icon-styles my-1 mt-3" />
                        <span className="country-code-span my-1 mt-3 h-[4.4rem]">
                          +91
                        </span>
                        <input
                          name="inputField2"
                          required
                          type="tel"
                          value={phoneNumber}
                          maxLength={10}
                          placeholder={`${
                            phoneNumberLabel ? "" : "9999999999"
                          }`}
                          pattern="\(\d{3}\) \d{3}\-\d{4}"
                          onChange={handlePhoneNumberChange}
                          className="input-contact-styles my-1 mt-[0.67rem]"
                          // onBlur={handleEmailFocus}
                          aria-label="Enter your phone number"
                          id="phoneNameId"
                          onFocus={handlePhoneNumberLabel}
                        />
                        {phoneNumberError && (
                          <div
                            style={{
                              color: "red",
                              padding: "0.5rem",
                              paddingBottom: 0,
                            }}
                          >
                            {phoneNumberError}
                          </div>
                        )}
                      </div>
                    </div>
                    <br />
                    <div>
                      <label
                        htmlFor="emailId"
                        className={`absolute z-[95] text-[0.9rem] mt-3 ml-16 transition-all ${
                          emailLabel ? "text-[#A1A1A1] " : " hidden"
                        } `}
                      >
                        Email
                      </label>
                      <div className="input-email">
                        <MdEmail className="email-icon-styles mt-2" />
                        <input
                          name="inputField3 "
                          required
                          type="email"
                          value={email}
                          placeholder={`${emailLabel ? "" : "abc@gmail.com"}`}
                          onChange={handleEmailChange}
                          className="input-email-styles mt-2"
                          onBlur={handleMessageFocus}
                          aria-label="Enter your email"
                          id="emailId"
                          onFocus={handleEmailLabel}
                        />
                        {emailError && (
                          <div
                            style={{
                              color: "red",
                              padding: "0.5rem",
                              paddingBottom: 0,
                            }}
                          >
                            {emailError}
                          </div>
                        )}
                      </div>
                    </div>
                    <br />

                    <div>
                      <label
                        htmlFor="phoneNameId"
                        className={`absolute z-[150] text-[0.9rem] mt-1 ml-16 transition-all ${
                          messageLabel ? "hidden" : " hidden"
                        } `}
                      ></label>
                      <div className="input-text ">
                        <textarea
                          name="inputField4"
                          value={message}
                          placeholder={`${"Type your message here or just say Hi!"}`}
                          onChange={handleMessageChange}
                          className="input-text-styles mt-3"
                          aria-label="Enter your message"
                          id="lastNameId"
                          onFocus={handleMessageLabel}
                        />
                        {messageError && (
                          <div
                            style={{
                              color: "red",
                              padding: "0.5rem",
                              paddingBottom: 0,
                            }}
                          >
                            {messageError}
                          </div>
                        )}
                      </div>
                    </div>

                    <br />
                    <div className="flex justify-center items-center mt-3">
                      {/* <div
                        className="g-recaptcha"
                        data-sitekey="6LdmfFgoAAAAAFMFepjtvgn5sjsWXRYzTwFO7ACy"
                      ></div> */}
                      <ReCAPTCHA
                        ref={recaptcha}
                        sitekey={"6LdmfFgoAAAAAFMFepjtvgn5sjsWXRYzTwFO7ACy"}
                      />
                    </div>
                    <div className="btn-position">
                      <button
                        className={`contact-submit-btn ${
                          !(
                            firstName.length === 0 ||
                            lastName.length === 0 ||
                            email.length === 0 ||
                            phoneNumber.length === 0 ||
                            phoneNumber.length < 10 ||
                            message.length === 0
                          )
                            ? "cta-btn-flex"
                            : "contact_submit_button_disabled"
                        }`}
                        type="submit"
                        disabled={
                          firstName.length === 0 ||
                          lastName.length === 0 ||
                          email.length === 0 ||
                          phoneNumber.length === 0 ||
                          phoneNumber.length < 10 ||
                          message.length === 0
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex flex-col justify-center  mt-[-8rem] contact-right-side  ">
                <div className="flex flex-col justify-center items-center   ">
                  <div className=" px-10 w-[70%] contact-right-side-img">
                    <img src={contactUsPic} className="w-[100%]" />
                  </div>
                </div>
                <div className="mt-[-5rem] px-[14rem] flex flex-col gap-6 contact-info">
                  <div className="flex gap-4 ">
                    <img
                      src={callContactPageIcon}
                      className="h-10 w-10 image_shadow_contact_icon cursor-pointer"
                      alt="Calling Icon"
                      onClick={openDialPad}
                    />
                    <div className="flex gap-2 justify-center items-center mt-[-0.6rem] cursor-pointer">
                      <span className="text-[#0B3C7F] font-for-contact-details">
                        +91
                      </span>
                      <div
                        className="text-[#0B3C7F] font-for-contact-details"
                        onClick={openDialPad}
                      >
                        9582189429
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 ">
                    <a href="mailto:info@finconic.com">
                      <img
                        src={mail_Contact_Icon}
                        className="h-10 w-10 image_shadow_contact_icon cursor-pointer"
                        alt="Email icon"
                      />
                    </a>
                    <div className="flex gap-2 justify-center items-center mt-[-0.5rem] cursor-pointer">
                      <a
                        href="mailto:info@finconic.com"
                        className="text-[#0B3C7F] font-for-contact-details underline"
                      >
                        info@finconic.com{" "}
                      </a>
                    </div>
                  </div>

                  <div className="flex gap-4 ">
                    <img
                      src={location_ContactUs_Icon}
                      className="h-10 w-10 image_shadow_contact_icon cursor-pointer"
                      alt="Location icon"
                      onClick={() => openGoogleMaps()}
                    />
                    <div
                      className="flex gap-2  justify-center items-center mt-[-0.3rem] cursor-pointer"
                      onClick={() => openGoogleMaps()}
                    >
                      <div className="text-[#0B3C7F] font-for-contact-details">
                        9/28 3rd Floor, East Patel Nagar, New Delhi, Delhi
                        110008
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <div
            className=""
            style={{
              marginTop: "29rem",
              border: "0.1px solid transparent",
            }}
          >
            <Footer />
          </div>
        </div>
      </div>
    )
  );
}

export default ContactUs;
