import React from "react";
import "../Card/Card.css";

function Card() {
  return (
    <>
      {/* <div className='decoration-top'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#EB45FE" fillOpacity="1" d="M0,32L120,69.3C240,107,480,181,720,181.3C960,181,1200,107,1320,69.3L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg> </div> */}
      <div className="main-card-container-flex-side">
        <div className="inner-card-container-flex-side">
          <div className="card-container-written-content-flex-col">
            <div className="writen-container-one">
              <h2 className="heading-one">
                Data Security is our First Priority
              </h2>
              <p className="pera-1">
                The pandemic and related shutdown wreaked havoc on businesses,
                so Congress passed multiple stimulus acts to distribute relief
                quickly.
              </p>
              <p className="pera-1">
                The two programs that got all the media attention were the PPP
                and EIDL programs. Most didn’t even know about the ERC Program,
                and even if so, didn’t think they qualified for it (even though
                it was as good or better than PPP).
              </p>
            </div>
            <div className="writen-container-two">
              <h2 className="heading-one">
                Co-landing powered by innovative techology and API based
                architecture
              </h2>
              <p className="pera-1">
                The ERC Program has been amended which can help more businesses
                qualify.
              </p>
              <p className="pera-1">
                {" "}
                Unlike PPP, there is no limit on max amount you can receive. ERC
                is getting more attention as business owners realize they can
                still take advantage.
              </p>
            </div>
          </div>
          <div className="image-card-one">
            <img className="top" src="/Image/data security.png" alt=" " />
          </div>
        </div>
      </div>
    </>
  );
}
export default Card;
