import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Rating } from "@mui/material";
import "./card.css";

function Card({ imagen, name, rating, review }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    height: "160px",
    width: "300px",
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div
      className={"card"}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className="flex items-center justify-between ">
        <div className="reviewer-desc  mt-4 mr-4">
          <img src={imagen} alt="Review Logo" className="  " />
          <p>{name}</p>
        </div>
        <p className="reviewer-review">“{review}”</p>
      </div>
      <div className={`relative Card_btnn__md6Cn`}>
        <Rating
          name="read-only"
          value={rating}
          defaultValue={3.5}
          precision={0.5}
          readOnly
          className="rating-stars"
          sx={
            {
              // position:'absolute',
              // right:"0",
              // bottom:"0"
            }
          }
        />
      </div>
    </animated.div>
  );
}

export default Card;
