import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import NewsLetterAcceptPopUp from "../NewsLetterAcceptPopUp/NewsLetterAcceptPopUp";
import {
  faceBookIcon,
  footerLogo,
  instagramIcon,
  linkedInIcon,
  mail1,
  mail2,
} from "../../utils/ImagesData/imagesData";
import { buttonState } from "../../utils/StateData/stateData";
function Footer() {
  const [showImg, setshowImg] = useState(mail1);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setshowImg(mail1);
  };

  const buttonRef = useRef();

  const handleClick = () => {
    buttonRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      toast.error("Please enter an email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      setshowImg(mail2);
      handleOpen();
    }
  };

  const openGoogleMaps = () => {
    // const location = "9/28+3rd+Floor,East+Patel+Nagar,New+Delhi,Delhi+110008";
    window.open(`https://maps.app.goo.gl/H4VqqivzFjQCJ3YG6`);
  };

  const openDialPad = () => {
    window.location.href = "tel:+919582189429";
  };

  const { pathname } = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <div className="footer-main-container">
      <NewsLetterAcceptPopUp
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        setOpen={setOpen}
        setshowImg={setshowImg}
        email={email}
        setEmail={setEmail}
      />
      <div className="internal-flex-flow-col">
        <div>
          <ul className="list-row-item">
            <li className="heading">Legal</li>
            <Link
              onClick={() => {
                scrollToTop()
                buttonState.state = "privacy";
              }}
              to="/terms-and-policies"
            >
              <li className=" cursor-pointer subheading-for-footer">
                Privacy Policy
              </li>
            </Link>
            <Link
              onClick={() => {
                scrollToTop()
                buttonState.state = "terms";
              }}
              to="/terms-and-policies"
            >
              <li className=" cursor-pointer subheading-for-footer">
                Terms of Use
              </li>
            </Link>

            {/* <li className=" cursor-pointer subheading-for-footer">
              Corporate Info 
            </li> */}
          </ul>
        </div>
        <div>
          <ul className="list-row-item">
            <li className="heading">Product</li>
            <p
              onClick={() => {
                if (pathname !== "/business-loan") {
                  window.location.href = "/business-loan";
                } else {
                  window.location.href = "#";
                }
              }}
            >
              <li className="product-list-name  cursor-pointer subheading-for-footer">
                Unsecured Business Loan
              </li>
            </p>
            <p
              onClick={() => {
                if (pathname !== "/dropline-limit") {
                  window.location.href = "/dropline-limit";
                } else {
                  window.location.href = "#";
                }
              }}
            >
              <li className="product-list-name  cursor-pointer subheading-for-footer">
                Unsecured Dropline Limit
              </li>
            </p>
            <p
              onClick={() => {
                if (pathname !== "/cgtmse") {
                  window.location.href = "/cgtmse";
                } else {
                  window.location.href = "#";
                }
              }}
            >
              <li className="product-list-name  cursor-pointer subheading-for-footer">
                CGTMSE
              </li>
            </p>
          </ul>
        </div>
        <div>
          <ul className="list-row-item">
            <li className="heading">Explore</li>

            <Link onClick={scrollToTop} to="/about">
              <li className="product-list-name  cursor-pointer subheading-for-footer">
                About Us
              </li>
            </Link>
            <Link onClick={scrollToTop} to="/contact">
              <li className="product-list-name  cursor-pointer subheading-for-footer">
                Contact Us
              </li>
            </Link>
            {/* <Link to="/">
              <li className="product-list-name  cursor-pointer subheading-for-footer">
                Achievements
              </li>
            </Link> */}
          </ul>
        </div>

        <div>
          <ul className="list-row-item">
            <li className="heading">OFFICE LOCATION</li>
            <li
              className=" cursor-pointer subheading-for-footer"
              onClick={() => openGoogleMaps()}
            >
              9/28 3rd Floor, East Patel Nagar, New Delhi, Delhi 110008
            </li>
            <li
              className=" cursor-pointer subheading-for-footer"
              onClick={openDialPad}
            >
              +91 9582189429
            </li>
            <a
              href="mailto:info@finconic.com"
              className=" cursor-pointer subheading-for-footer"
            >
              info@finconic.com
            </a>
          </ul>
        </div>

        <div>
          <ul>
            <li className="heading">Social Media</li>
            <div className="flex-icon-display">
              <a
                href="https://www.linkedin.com/company/finconicofficial"
                target="_blank"
              >
                {" "}
                <li>
                  <img
                    className="img-one"
                    src={linkedInIcon}
                    alt="linkedin-icon "
                    style={{ cursor: "pointer" }}
                  />
                </li>
              </a>
              <a
                href="https://www.instagram.com/finconic_official/"
                target="_blank"
              >
                <li>
                  <img
                    className="img-one "
                    src={instagramIcon}
                    alt="instagram-logo "
                    style={{ cursor: "pointer" }}
                  />
                </li>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554406600601"
                target="_blank"
              >
                <li>
                  <img
                    className="img-one"
                    src={faceBookIcon}
                    alt="facebook-logo "
                    style={{ cursor: "pointer" }}
                  />
                </li>
              </a>
            </div>
          </ul>
        </div>
      </div>
      <div className=" ">
        <div className="flex justify-between gap-[10rem] items-center">
          <ul className="flex-footer-row">
            {/* <li>ISO Certification</li> */}
            <li className=" ">© 2023 Finconic. All rights reserved.</li>
            {/* <li>© 2023 Finconic. All rights reserved.</li> */}
          </ul>
          <div className=" ">
            <div className="email-flex-row">
              <h2 className="heading">News letter</h2>
              <form className="email-flex" onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  className="bg-[#0b3c7f]"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <a>
                  <img onClick={handleClick} src={showImg} alt="mail-icon " />
                </a>
                <button
                  type="submit"
                  ref={buttonRef}
                  style={{ display: "none" }}
                ></button>
              </form>
            </div>
            <div className="img-col-logo ml-[19rem] ">
              <Link onClick={scrollToTop} to="/">
                <img src={footerLogo} alt="footer-logo " />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
