import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../LoanTransfer/LoanTransfer.css";

function LoanTransfer() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input
    const phoneNumberDigits = value.replace(/\D/g, "");
    // Limit the phone number to 10 digits
    const limitedPhoneNumber = phoneNumberDigits.slice(0, 10);
    setPhoneNumber(limitedPhoneNumber);
  };
  return (
    <>
      <Navbar />
      <section className="herosection-flex-img-container-md">
        <div className=" inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src="/HeaderImg/loan transfer.png"
              alt=""
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Loan Transfer</h2>
            <h2 className="heading-h2"></h2>
            <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex">Apply Now</button>
            </div>
          </div>
        </div>
      </section>
      {/* content */}
      <div className="busniness-content-p">
        <div className="btn-class">
          <button className="btn-1">Secured Loan Transfer</button>
          <button className="btn-2">Unsecured Loan Transfer</button>
        </div>

        <p>
          Balance Transfer is a concept of allowing the borrower to transfer the
          balance loan amount from one bank or financial institution to any
          other bank or financial institution for better terms & conditions.
        </p>
        <p>
          Top Up is a facility provided to borrower, under which individual can
          borrow a certain extra amount of money over his existing loan.
        </p>
      </div>
      {/* card Why to Contact us for balance transfer and top up options? */}
      <div className="OUTER-SECTION-transfer">
        <div className="inside-container-flex-md">
          <h3>Why to Contact us for balance transfer and top up options?</h3>
          <div className="card-content-flex-row">
            <div className="card-md-one">
              <p>
                We have a very wide range of product options with better
                interest and excellent support services.
              </p>
            </div>
            <div className="card-md-one">
              <p>
                For the Top ups we will arrange the higher top ups and even
                lower rates by suggesting you the better ways to restructure the
                loan scheme.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* card-label */}
      <div className="section-head">
        <div className="outer-section-flex-row card-one">
          <div className="allignment">
            <h2>Why we should go for Balance Transfer?</h2>
            <div className="card-one-flex">
              <div className="card-img-one-md-img">
                <img src="/Image/receive_file 1.png" alt="" />
              </div>
              <div className="card-container-box-one">
                <div className="flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h4>Loan for the Purchase of Land</h4>
                    </div>
                    <div className="flip-card-back">
                      <p>
                        Several Banks and Financial institutions provides loans
                        for the purchase of land. Individual can purchase the
                        land for future construction or for resale. Purchase of
                        Land can be financed up to 90% of the cost of the land
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h4>Loan for the Purchase of Land</h4>
                    </div>
                    <div className="flip-card-back">
                      <p>
                        Several Banks and Financial institutions provides loans
                        for the purchase of land. Individual can purchase the
                        land for future construction or for resale. Purchase of
                        Land can be financed up to 90% of the cost of the land
                      </p>
                    </div>
                  </div>
                </div>{" "}
                <div className="flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h4>Loan for the Purchase of Land</h4>
                    </div>
                    <div className="flip-card-back">
                      <p>
                        Several Banks and Financial institutions provides loans
                        for the purchase of land. Individual can purchase the
                        land for future construction or for resale. Purchase of
                        Land can be financed up to 90% of the cost of the land
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h4>Loan for the Purchase of Land</h4>
                    </div>
                    <div className="flip-card-back">
                      <p>
                        Several Banks and Financial institutions provides loans
                        for the purchase of land. Individual can purchase the
                        land for future construction or for resale. Purchase of
                        Land can be financed up to 90% of the cost of the land
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <h4>Loan for the Purchase of Land</h4>
                    </div>
                    <div className="flip-card-back">
                      <p>
                        Several Banks and Financial institutions provides loans
                        for the purchase of land. Individual can purchase the
                        land for future construction or for resale. Purchase of
                        Land can be financed up to 90% of the cost of the land
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* floating */}
      <div className="container-flex-row">
        <div className="inner-container">
          <div className="written-content-col-flow">
            <h2>
              points must always be considered before going for the balance
              transfer & top ups
            </h2>
            <p>Always check for the new offers.</p>
            <p>Read all the terms and conditions carefully.</p>
            <p>Analyze the options from all aspects as per the requirements.</p>
            <p>Be careful for the other costs involved.</p>
            <p>
              Always compares the offers and choose the one with the best
              potential savings.
            </p>
          </div>
          <div className="card-image-flex floating">
            <img src="/Image/card-img.png" alt="" />
          </div>
        </div>
      </div>
      {/* rombus card */}
      <div className="outer-section-flex-row">
        <h2>Documents Required</h2>
        <div className="card-flex-row-md" data-aos="fade-up">
          <div className="card-img-md">
            <img src="/Image/Card-2.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-3.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-1.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-4.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LoanTransfer;
