import {
  bankStatementImage,
  companyKycImage,
  finanicalsImage,
  gstImage,
  individualKycImage,
  repaymentImage,
} from "../ImagesData/imagesData";

export const pvtLtdDocumentData = [
  {
    id: 1,
    documentName: "Company KYC",
    list: {
      doc1: "Company PAN card",
      doc2: "All states GST registration certificate ",
      doc3: "MOA & AOA",
      doc4: "Udyam registration certificate with annexures ",
      doc5: "Latest list of directors and shareholding pattern",
      doc6: "Ownership Proof - Latest electricity bill/water bill/house tax/registry copy in the name of company/directors or shareholder",
    },
    img: companyKycImage,
  },
  {
    id: 2,
    documentName: "Individual KYC",
    list: {
      doc1: "All the active director’s/shareholder’s passport size Photo",
      doc2: "Director’s/shareholder’s copy of PAN card ",
      doc3: "Director’s/shareholder’s aadhar card with updated residence address",
    },
    img: individualKycImage,
  },
  {
    id: 3,
    documentName: "GST",
    list: {
      doc1: "All states GST 3B returns F.Y 2022-23 and F.Y 2023-24",
    },
    img: gstImage,
  },
  {
    id: 4,
    documentName: "Financials",
    list: {
      doc1: "Company ITR and computation of income copies of latest 3 financial years.",
      doc2: "Tax audit report of latest 3 financial years.",
      doc3: "Independent audit report of latest 3 financial years.",
      doc4: "Directors report of latest 3 financial years.",
      doc5: "Complete balance sheet and profit and loss statement along with there notes and annexures of latest 3 financial years.",
    },
    img: finanicalsImage,
  },
  {
    id: 5,
    documentName: "Bank Statements",
    list: {
      doc1: "Last 12 months complete bank statement of all the companies current accounts, overdraft or cash credit accounts in PDF format",
    },
    img: bankStatementImage,
  },
  {
    id: 6,
    documentName: "Repayment Schedule",
    list: {
      doc1: "All the active loans repayment schedules and overdraft or cash credit sanction letters.",
    },
    img: repaymentImage,
  },
];

export const partnershipDocumentData = [
  {
    id: 1,
    documentName: "Company KYC",
    list: {
      doc1: "Company PAN card",
      doc2: "All states GST registration certificate ",
      doc3: "Udyam registration certificate with annexures ",
      doc4: "Complete chain of partnership deeds ",
      doc5: "Ownership Proof - Latest electricity bill/water bill/house tax/registry copy in the name of company/directors or shareholder",
    },
    img: companyKycImage,
  },
  {
    id: 2,
    documentName: "Individual KYC",
    list: {
      doc1: "All the active partners passport size photo",
      doc2: "Partner’s copy of PAN card",
      doc3: "Partner’s aadhar card with updated residence address",
    },
    img: individualKycImage,
  },
  {
    id: 3,
    documentName: "GST",
    list: {
      doc1: "All states GST 3B returns F.Y 2022-23 and F.Y 2023-24",
    },
    img: gstImage,
  },
  {
    id: 4,
    documentName: "Financials",
    list: {
      doc1: "Company ITR and computation of income copies of latest 3 financial years.",
      doc2: "Tax audit report of latest 3 financial years.",
      doc3: "Independent audit report of latest 3 financial years.",
      doc4: "Complete balance sheet and profit and loss statement along with there notes and annexures of latest 3 financial years.",
    },
    img: finanicalsImage,
  },
  {
    id: 5,
    documentName: "Bank Statements",
    list: {
      doc1: "Last 12 months complete bank statement of all the companies current accounts, overdraft or cash credit accounts in PDF format ",
    },
    img: bankStatementImage,
  },
  {
    id: 6,
    documentName: "Repayment Schedule",
    list: {
      doc1: "All the active loans repayment schedules and overdraft or cash credit sanction letters.",
    },
    img: repaymentImage,
  },
];

export const proprietorshipDocumentData = [
  {
    id: 1,
    documentName: "Company KYC",
    list: {
      doc1: "All states GST registration certificate ",
      doc2: "Udyam registration certificate with annexures ",
      doc3: "Ownership Proof - Latest Electricity Bill/Water Bill/House Tax/Registry copy in the name of Entity/Properitor",
    },
    img: companyKycImage,
  },
  {
    id: 2,
    documentName: "Individual KYC",
    list: {
      doc1: "Propreitor's passport size photo",
      doc2: "Propreitor's copy of PAN card",
      doc3: "Propreitor's aadhar card with updated residence address",
    },
    img: individualKycImage,
  },
  {
    id: 3,
    documentName: "GST",
    list: {
      doc1: "All states GST 3B returns F.Y 2022-23 and F.Y 2023-24",
    },
    img: gstImage,
  },
  {
    id: 4,
    documentName: "Financials",
    list: {
      doc1: "Propreitors ITR and computation of income copies of latest 3 financial years.",
      doc2: "Tax audit report of latest 3 financial years.",
      doc3: "Complete balance sheet and profit and loss statement along with there notes and annexures of latest 3 financial years.",
    },
    img: finanicalsImage,
  },
  {
    id: 5,
    documentName: "Bank Statements",
    list: {
      doc1: "Last 12 months complete bank statement of all the companies current accounts, overdraft or cash credit accounts in PDF format ",
    },
    img: bankStatementImage,
  },
  {
    id: 6,
    documentName: "Repayment Schedule",
    list: {
      doc1: "All the active loans repayment schedules and overdraft or cash credit sanction letters.",
    },
    img: repaymentImage,
  },
];
