import { teamPic1, teamPic2, teamPic3 } from "../ImagesData/imagesData";
export const teamMembers = [
  {
    id: 1,
    name: "Ameet Siingh",
    designation: "Founder & CEO",
    description:
      "Ameet Siingh is a seasoned professional in the finance industry, with over 12 years of experience that has shaped his career and vision. As the Founder and CEO of Finconic, he has dedicated his expertise to revolutionizing the financial sector. Ameet Siingh's commitment to ethical and transparent financial services is at the heart of his vision for a more secure and trustworthy financial industry.",
    image: teamPic1,
  },
  {
    id: 2,
    name: "Kaanan Siingh",
    designation: "Co-founder & CBO",
    description:
      "Kaanan, our Director of Client Relations, is your financial confidante. She's like your personal financial GPS, always ready to answer your questions and provide tailored solutions. Think of her as your financial superhero, ensuring that every piece of your financial puzzle falls into place, paving the way to your financial success.",
    image: teamPic2,
  },
  {
    id: 3,
    name: "Ankit Pandey",
    designation: "Co-founder & CTO",
    description:
      "As the visionary CTO of Finconic, a fintech marvel, our leader boasts over a decade of extraordinary expertise in shaping, crafting, nurturing, and safeguarding intricate projects. With a blend of innovation and precision, they orchestrate our technological symphony for a future where finance meets digital artistry.",
    image: teamPic3,
  },
];
