import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import "../ToolsCalculator/EmiCalculator.css";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import slideMark from "../Data/SlideMark";
import { GoPerson } from "react-icons/go";
import { BsCarFrontFill } from "react-icons/bs";
import { MdHome } from "react-icons/md";

function EmiCalculator() {
  const [Aprinciple, setAprinciple] = useState(100000);
  const [Intrest, setIntrest] = useState(10);
  const [duration, setDuration] = useState(5);
  const maxPrincipleAmount = 20000000;
  const maxIntrest = 25;
  const maxduration = 35;
  const intr = Intrest / 1200;
  const Duration = duration * 12;
  const emi = (Aprinciple * intr) / (1 - Math.pow(1 / (1 + intr), Duration));
  const totalAmountOfCredit =
    (emi / intr) * (1 - Math.pow(1 + intr, -Duration));
  const totalAmountp = Duration * emi;
  const totalIntrest = totalAmountp - totalAmountOfCredit;
  const totalAmount = Number(totalIntrest) + Number(Aprinciple);

  function handleAprincipleChange(e) {
    const value = e.target.value;
    if (value >= 1 && value <= 20000000) setAprinciple(value);
    else setAprinciple("");
  }

  function handleIntrestChange(e) {
    setIntrest(e.target.value);
  }

  function handleDurationChange(e) {
    setDuration(e.target.value);
  }

  return (
    <>
      <Navbar />
      <div className="bg-color-emi">
        <section className="emi-calculator-outer">
          <div className="emi-calculator-inner">
            <h1>Loan Calculator</h1>
            <div className="chip-card">
              <div className="chip-card-md">
                <div className="chip-card-ms">
                  <GoPerson />
                  <h2>Personal Loan</h2>
                </div>
                <div className="chip-card-ms">
                  <MdHome />
                  <h2>Home Loan</h2>
                </div>
                <div className="chip-card-ms">
                  <BsCarFrontFill />
                  <h2>Car Loan</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="emi-calculatar-container">
          <div className="inner-emi-container">
            <div className="Input-container">
              {/* emi calculator */}
              <div className="emi-flex-outer flex gap-4 p-2 items-center">
                <div className="flex-emi-slider">
                  <div className="input-flex-contao-iner">
                    <div className="label-md">
                      <label className="Aprinciple">Loan Amount:</label>
                    </div>

                    <div className="flex justify-between">
                      <div className="flex-col">
                        <input
                          className="range"
                          type="range"
                          min="1"
                          max={maxPrincipleAmount}
                          step="10000"
                          value={Aprinciple}
                          onChange={handleAprincipleChange}
                        />

                        <div className=" span-flex">
                          <span>0L</span>
                          <span>25L</span>
                          <span>50L</span>
                          <span>75L</span>
                          <span>1cr</span>
                          <span>1.25cr</span>
                          <span>1.50cr</span>
                          <span>1.75cr</span>
                          <span>2cr</span>
                        </div>
                      </div>
                      <div className="flex-col-md">
                        <input
                          className="inputField"
                          value={Aprinciple}
                          placeholder="Loan"
                          type="number"
                          onChange={handleAprincipleChange}
                        />
                        {/* <span style={{color:"red"
                }}>{Aprinciple}</span> */}
                      </div>
                    </div>
                  </div>

                  <div className="input-flex-contao-iner">
                    <label className="Intrest">Interest Rate:</label>

                    <div className="flex justify-between">
                      <div className="flex-col">
                        <input
                          className="range"
                          type="range"
                          min="1"
                          max={maxIntrest}
                          step="0.25"
                          value={Intrest}
                          onChange={handleIntrestChange}
                        />
                        <div className="span-flex">
                          <span>0</span>
                          <span>5</span>
                          <span>7.5</span>
                          <span>10</span>
                          <span>12.5</span>
                          <span>15</span>
                          <span>17.5</span>
                          <span>20</span>
                          <span>22.5</span>
                          <span>25</span>
                        </div>
                      </div>
                      <input
                        className="inputField"
                        value={Intrest}
                        type="number"
                        placeholder="Intrest"
                        onChange={handleIntrestChange}
                      />
                      {/* <span>{Intrest}%</span> */}
                    </div>
                  </div>

                  <div className="input-flex-contao-iner">
                    <label className="duration">Loan duration (years):</label>
                    <div className="flex justify-between">
                      <div className="flex-col">
                        <input
                          className="range"
                          type="range"
                          min="1"
                          max={maxduration}
                          step="1"
                          value={duration}
                          onChange={handleDurationChange}
                        />
                        <div className="span-flex">
                          <span>0</span>
                          <span>5</span>
                          <span>10</span>
                          <span>15</span>
                          <span>20</span>
                          <span>25</span>
                          <span>30</span>
                          <span>35</span>
                        </div>
                      </div>
                      <input
                        className="inputField"
                        placeholder="Year"
                        value={duration}
                        type="number"
                        onChange={handleDurationChange}
                      />
                      {/* <span>{duration} years</span> */}
                    </div>
                  </div>
                </div>
                <div className="pie-chart-emi">
                  <div className="display-flex-pie p-6">
                    <div className=" pie-chart-md">
                      <Doughnut
                        data={{
                          labels: ["Intrest", "Amount"],

                          datasets: [
                            {
                              data: [totalIntrest, totalAmount],
                              label: ["Total Intrest", "Total amount"],
                              backgroundColor: ["#0B3C7F", "#E9AF43"],

                              spacing: 0,
                              weight: 300,
                              hoverOffset: 2,
                              borderRadius: 1,
                              borderJoinStyle: "round",
                              cutout: "80%",
                            },
                          ],
                        }}
                      />
                    </div>
                    <div className=" flex-col pie-chart-md m-2 p-4">
                      <div className="flex-col">
                        <div className="amount-output-flex">
                          <img
                            className="color-div"
                            src="/HeaderImg/Ellipse 32.png"
                            alt=""
                          />
                          <h2>Principal Amount</h2>
                          <h2>₹{Aprinciple}</h2>
                        </div>
                        <div className="amount-output-flex">
                          <img
                            className="color-div"
                            src="/HeaderImg/Ellipse 33.png"
                            alt=""
                          />
                          <h2>Interest Amount</h2>
                          <h2>₹ {Math.round(totalIntrest)}</h2>
                        </div>
                        <div className="amount-output-flex">
                          <img
                            className="color-div"
                            src="/HeaderImg/Ellipse 33.png"
                            alt=""
                          />
                          <h2>Total Amount </h2>
                          <h2>₹ {Math.round(totalAmount)}</h2>
                        </div>
                        {/* <div className='amount-output-flex'>
                        <img className='color-div' src="/HeaderImg/Ellipse 33.png" alt="" />
                        <h2>Total Intrest</h2>
                        <h2>₹ {Math.round(Intrest)}</h2>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* khjd */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmiCalculator;
