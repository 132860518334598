import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./newsLetterAcceptPopUp.css";
import axiosRequest from "../../ApiRequests/Axios/axiosCaller";
import { toast } from "react-toastify";
import { closeIconPopup, mail1 } from "../../utils/ImagesData/imagesData";

const NewsLetterAcceptPopUp = ({
  open,
  handleClose,
  handleOpen,
  setOpen,
  email,
  setEmail,
  setshowImg,
}) => {
  const [loading, setLoading] = useState(false);

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#FFFFFF",
    border: "none",
    borderRadius: "5px",
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    color: "#656565",
  };

  const textStyle = {
    fontFamily: "Inter, sans-serif",
    fontSize: "1.3rem",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    mt: 2,
  };

  let data = {
    email: email,
  };

  const handleAccept = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let response = await axiosRequest(
        "post",
        "api/form/subscribe-to-newsletter",
        data
      );
      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setOpen(false);
        setshowImg(mail1);
        setEmail("");
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setOpen(false);
        setshowImg(mail1);
        setEmail("");
      }
    } catch (error) {
      toast.error("Internal Server error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setshowImg(mail1);
      setEmail("");
      
      setOpen(false);
      setshowImg(mail1);
      setEmail("");
    } finally {
      setLoading(false);
      setshowImg(mail1);
      setEmail("");

      setOpen(false);
      setshowImg(mail1);
      setEmail("");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxStyle}>
          <Typography id="modal-modal-description" sx={textStyle}>
            By selecting the option below, you grant Finconic your consent to
            deliver our exclusive newsletters, special offers, and the latest
            updates directly to your inbox.
          </Typography>
          <button
            disabled={loading}
            onClick={(e) => handleAccept(e)}
            className="cta-btn-flex mt-16 mx-auto"
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Accept"
            )}
          </button>
          <img
            src={closeIconPopup}
            className="absolute top-4 right-3 h-10 w-10"
            alt="close popup"
            onClick={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default NewsLetterAcceptPopUp;
