import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Collapse,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./RequiredDocuments.css";

const RequiredDocuments = ({
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
}) => {
  const [selectedCompany, setSelectedCompany] = useState("pvtLtd");
  const [openSublist, setOpenSublist] = useState("");
  const [loading, setLoading] = useState(false);

  const companyTypes = [
    {
      id: "pvtLtd",
      label: "Private Ltd/ Limited",
      data: pvtLtdDocumentData,
    },
    {
      id: "partnership",
      label: "Partnership/ LLP",
      data: partnershipDocumentData,
    },
    {
      id: "properitorship",
      label: "Individual/ Proprietorship",
      data: proprietorshipDocumentData,
    },
  ];

  const handleCompanyChange = async (company) => {
    setLoading(true);
    setSelectedCompany(company);
    setOpenSublist("");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setLoading(false);
  };

  const handleDocumentClick = (documentName) => {
    setOpenSublist(openSublist === documentName ? "" : documentName);
  };

  return (
    <div className="required-documents-container">
      <FormControl fullWidth className="form-control-select-list">
        <Select
          labelId="company-label"
          id="company-select"
          value={selectedCompany}
          onChange={(e) => handleCompanyChange(e.target.value)}
          className="custom-select"
        >
          {companyTypes.map((company) => (
            <MenuItem
              key={company.id}
              value={company.id}
              sx={{ fontSize: "1.6rem" }}
            >
              {company.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List className="custom-list">
        {loading ? (
          <div className="document-listn-mobile-loader-container">
            <CircularProgress
              size={60}
              style={{ color: "#0b3c7f" }}
              thickness={2}
            />
          </div>
        ) : (
          companyTypes.map((company) => (
            <div key={company.id}>
              {selectedCompany === company.id && (
                <Collapse in timeout="auto" unmountOnExit>
                  <List className="custom-lists">
                    {company.data.map((document) => (
                      <div key={document.id}>
                        <ListItem
                          onClick={() =>
                            handleDocumentClick(document.documentName)
                          }
                          className={`custom-list-item ${
                            openSublist === document.documentName
                              ? "expanded"
                              : ""
                          }`}
                        >
                          <ListItemText primary={document.documentName} />
                          <IconButton>
                            {openSublist === document.documentName ? (
                              <ExpandMoreIcon style={{ color: "#0b3c7f" }} />
                            ) : (
                              <ChevronRightIcon />
                            )}
                          </IconButton>
                        </ListItem>
                        <Collapse
                          in={openSublist === document.documentName}
                          timeout="auto"
                          unmountOnExit
                          className="custom-collapse-list"
                        >
                          <List
                            component="div"
                            disablePadding
                            className="custom-sub-sublist"
                          >
                            {Object.values(document.list).map((item, index) => (
                              <ListItem
                                key={index}
                                className="custom-sub-list-item"
                              >
                                <ListItemText primary={`- ${item}`} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </div>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))
        )}
      </List>
    </div>
  );
};

export default RequiredDocuments;
