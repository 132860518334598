import React, { useEffect } from "react";
import "./MobileCGTMSE.css";
import Aos from "aos";
import PartnerCards from "../HomePage/PartnerCards/PartnerCards";
import { Helmet } from "react-helmet";
import Footer from "../../../Components/MobileScreens/Footer/MobileFooter";

import RequiredDocuments from "../RequiredDocuments/RequiredDocuments";
import {
  pvtLtdDocumentData,
  partnershipDocumentData,
  proprietorshipDocumentData,
} from "../../../utils/DocumentsRequiredLists/cgtmseDocuments";
import CgtmseTable from "../../CgtmseTable/CgtmseTable";
import {
  featuresPic,
  mobileCGTMSE,
  partner10,
  partner11,
  rectangleFooterImage,
} from "../../../utils/ImagesData/imagesData";

const MobileCGTMSE = () => {
  const featuresList = [
    "Collateral-free Credit",
    "Low rate of interest",
    "Attractive bundled offerings",
    "Less Documentation",
    "Long term & short term financing",
    "Flexible Tenure",
    "Large Capital",
  ];

  const fatorsList = [
    "Credit Score/ Credit History",
    "Income/ Financial Stability",
    "Debt to income Ratio",
    "Lender Policies",
  ];

  const companies = ["company 1", "company 2", "company 3", "company 4"];

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>CGTMSE - Finconic</title>
        <meta name="description" content="CGTMSE Details" />
      </Helmet>

      <div className="mobileCGTMSE">
        {/* Top Section */}
        <div className="mobileCGTMSE-top">
          <div className="mobileCGTMSE-top-image">
            <img src={mobileCGTMSE} alt="Unsecured Loan Image" />
          </div>
          <div className="mobileCGTMSE-top-headings">
            <h2>CGTMSE</h2>
          </div>
          <div className="mobileCGTMSE-top-desc">
            <p>
              The Credit Guarantee Fund Trust for Micro and Small Enterprises
              (CGTMSE) is a scheme in India that was launched to facilitate
              collateral-free credit to micro and small enterprises (MSEs)
            </p>
          </div>
          <div className="mobileCGTMSE-top-button">
            <button
              className="cta-btn-flex"
              onClick={() => (window.location.href = "/contact")}
            >
              Reach Out to Us
            </button>
          </div>
        </div>

        {/*Description */}
        <div className="mobileCGTMSE-desc">
          <p>
            The Credit Guarantee Fund Trust for Micro and Small Enterprises
            (CGTMSE) is a scheme in India that was launched to facilitate
            collateral-free credit to micro and small enterprises (MSEs). It was
            established by the Government of India in association with the Small
            Industries Development Bank of India (SIDBI) and the Ministry of
            Micro, Small, and Medium Enterprises (MSMEs).
          </p>
        </div>

        {/* Features */}
        <div className="mobileCGTMSE-features">
          <div className="mobileCGTMSE-features-heading">
            <h2>Features of CGTMSE</h2>
          </div>
          <div className="mobileCGTMSE-features-image">
            <img src={featuresPic} alt="" />
          </div>
          <div className="mobileCGTMSE-features-lists">
            <ul>
              {featuresList.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
          </div>
        </div>

        {/* Factors */}
        {/* <div className="mobileCGTMSE-factors">
          <div className="mobileCGTMSE-factors-heading">
            <h2>Factors Affecting the CGTMSE Amount</h2>
          </div>
          <div className="mobileCGTMSE-factors-img">
            <img src={mobileFactorsCGTMSEImage} alt="" />
          </div>
          <div className="mobileCGTMSE-factors-tabs">
            {fatorsList.map((factor, i) => (
              <div key={i} data-aos="fade-up" data-aos-duration="2000">
                {factor}
              </div>
            ))}
          </div>
        </div> */}

        {/* Eligible for business loan */}
        {/* <div className="mobileCGTMSE-eligible">
          <div className="mobileCGTMSE-eligible-heading">
            <h2>Who is Eligible</h2>
            <span>for the CGTMSE?</span>
          </div>
          <div className="mobileCGTMSE-eligible-img">
            <img src={businessElgibleLoanImg} alt="" />
          </div>
          <div className="mobileCGTMSE-eligible-desc">
            <p>
              Any entrepreneur, proprietor, self-employed professional, a
              private company, or partnership firm will get access to the DLOD
              facility.
            </p>
          </div>
        </div> */}

        {/* CGTMSE Table */}
        <div className="cgtmse-table-container-mobile">
          <CgtmseTable />
        </div>

        {/* Documents Required */}
        <div className="mobileCGTMSE-documentsRequired">
          <div className="mobileCGTMSE-documentsRequired-heading">
            <h2>Documents Required</h2>
          </div>
          <div className="mobileUnsecuredLoan-documentsRequired-lists">
            <RequiredDocuments
              pvtLtdDocumentData={pvtLtdDocumentData}
              partnershipDocumentData={partnershipDocumentData}
              proprietorshipDocumentData={proprietorshipDocumentData}
            />
          </div>
        </div>

        {/* partners cards */}
        <div className="partner-list-container-cgtmse-mobile">
          <img
            src={rectangleFooterImage}
            className="rectangle-footer-image-cgtmse-mobile w-screen "
            alt="background-image-for-partner"
          />
          <h2 className="partner-list-container-cgtmse-mobile-heading">
            Our Partners
          </h2>
          <div className="company-logos-cgtmse-mobile">
            <div className="company-logo-cgtmse-image-mobile">
              <img src={partner10} alt="Partner Image 1" />
            </div>
            <div className="company-logo-cgtmse-image-mobile">
              <img src={partner11} alt="Partner Image 2" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MobileCGTMSE;
