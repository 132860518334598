import React from "react";
import "../ContentCardSlide/ContentCard.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  partner6,
  rectangleFooterImage,
} from "../../utils/ImagesData/imagesData";
function ContentCard() {
  const sliderImages = [
    { id: 1, src: partner1, alt: "Our partner-1" },
    { id: 2, src: partner2, alt: "Our partner-2" },
    { id: 3, src: partner3, alt: "Our partner-3" },
    { id: 4, src: partner4, alt: "Our partner-4" },
    { id: 5, src: partner5, alt: "Our partner-5" },
    { id: 6, src: partner6, alt: "Our partner-6" },
  ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <>
      <div className="outer-container-card relative">
        <img
          src={rectangleFooterImage}
          className="absolute w-screen mt-[-3.6rem]"
          alt=""
          style={{ height: "100%" }}
          role="presentation"
          aria-hidden="true"
        />
        <div className="mt-[6rem] z-[100]">
          <h2 className="content-cards-heading">Our Partners</h2>
          <Slider {...settings}>
            {sliderImages.map((image) => (
              <div key={image.id} className="company-logo-image-destop">
                <img src={image.src} alt={image.alt} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default ContentCard;
