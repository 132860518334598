import React, { useState } from "react";
import { investorPic } from "../../../utils/ImagesData/imagesData";
import "./HomePageBanner.css"
const HomePageBanner = () => {
  return (
    <>
      <section className="main-container-row">
        <div className="flex-container-herosection-row">
          <div className="content-flex-row-one-content">
            <h1 className="main-heading" aria-level="1">
              Empowering Enterprises
            </h1>
            <h2 className="second-heading" aria-level="2">
              Need financial support?
            </h2>
            <h3 className="third-heading" aria-level="3">
              We Got Your Back
            </h3>
            <p className="description-text">
              Access unsecured business loans, flexible dropline limits and
              CGTMSE support effortlessly. Experience seamless disbursals and
              streamline your journey with hassle-free one-time documentation.
            </p>
            <div className="div-main-btn-container">
              <button
                className="cta-btn-flex"
                onClick={() => {
                  window.location.href = "/contact";
                }}
                aria-label="Reach Out to Us"
              >
                Reach Out to Us{" "}
              </button>
            </div>
          </div>
          <div className="parent">
            <img className="image1" src={investorPic} alt="Invester Logo" />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePageBanner;
