import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./MobileFooter.css";
import NewsLetterAcceptPopUp from "../../NewsLetterAcceptPopUp/NewsLetterAcceptPopUp";
import {
  faceBookIcon,
  instagramIcon,
  linkedInIcon,
  mail1,
  mail2,
} from "../../../utils/ImagesData/imagesData";
import { buttonState } from "../../../utils/StateData/stateData";

const MobileFooter = () => {
  const [showImg, setshowImg] = useState(mail1);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setshowImg(mail1);
  };

  const buttonRef = useRef();

  const handleClick = () => {
    buttonRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setshowImg(mail2);
    handleOpen();
  };
  const openGoogleMaps = () => {
    // const location = "9/28+3rd+Floor,East+Patel+Nagar,New+Delhi,Delhi+110008";
    window.open(`https://maps.app.goo.gl/H4VqqivzFjQCJ3YG6`);
  };

  const openDialPad = () => {
    window.location.href = "tel:+919582189429";
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <NewsLetterAcceptPopUp
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        setOpen={setOpen}
        setshowImg={setshowImg}
        email={email}
        setEmail={setEmail}
      />
      <section className="mobile-footer-container-section">
        {/* top section */}

        <div className="mobile-footer-lists-flex-container">
          <div className="legal-list-container">
            <ul className="list-row-item">
              <li className="heading">Legal</li>
              <Link
                onClick={() => {
                  scrollToTop;
                  buttonState.state = "privacy";
                }}
                to="/terms-and-policies"
              >
                <li className=" cursor-pointer subheading-for-footer">
                  Privacy Policy
                </li>
              </Link>
              <Link
                onClick={() => {
                  scrollToTop;
                  buttonState.state = "terms";
                }}
                to="/terms-and-policies"
              >
                <li className=" cursor-pointer subheading-for-footer">
                  Terms of Use
                </li>
              </Link>
              {/* <li className=" cursor-pointer product-list-name-mobile">
                Corporate Info
              </li> */}
            </ul>
          </div>
          <div className="products-list-container">
            <ul className="list-row-item">
              <li className="heading">Product</li>
              <NavLink onClick={scrollToTop} to="/business-loan">
                <li className="product-list-name-mobile  cursor-pointer ">
                  Unsecured Business Loan
                </li>
              </NavLink>
              <NavLink onClick={scrollToTop} to="/dropline-limit">
                <li className="product-list-name-mobile  cursor-pointer ">
                  Unsecured Dropline Limit
                </li>
              </NavLink>
              <NavLink onClick={scrollToTop} to="/cgtmse">
                <li className="product-list-name-mobile  cursor-pointer ">
                  CGTMSE
                </li>
              </NavLink>
            </ul>
          </div>
          <div className="explore-list-container">
            <ul className="list-row-item">
              <li className="heading">Explore</li>
              <NavLink onClick={scrollToTop} to="/about">
                <li className="product-list-name-mobile  cursor-pointer ">
                  About Us
                </li>
              </NavLink>
              <NavLink onClick={scrollToTop} to="/contact">
                <li className="product-list-name-mobile  cursor-pointer ">
                  Contact Us
                </li>
              </NavLink>
              {/* <NavLink to="/careers">
              <li className="product-list-name-mobile  cursor-pointer ">
                Careers
              </li>
            </NavLink> */}
            </ul>
          </div>
        </div>

        {/* middle section */}

        <div className="location-list-container">
          <ul className="list-row-item">
            <li className="heading">OFFICE LOCATION</li>
            <li
              className=" cursor-pointer product-list-name-mobile"
              onClick={() => openGoogleMaps()}
            >
              9/28 3rd Floor, East Patel Nagar, New Delhi, Delhi 110008
            </li>
            <li
              className=" cursor-pointer product-list-name-mobile"
              onClick={openDialPad}
            >
              +91 9582189429
            </li>
            <a
              href="mailto:info@finconic.com"
              className=" cursor-pointer product-list-name-mobile"
            >
              info@finconic.com
            </a>
          </ul>
        </div>

        {/* news  Letter section */}
        <div className="email-flex-row-mobile">
          <h2 className="heading-news-letter">News letter</h2>
          <form className="email-flex" onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email address"
              className="bg-[#164484]"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <a>
              <img onClick={handleClick} src={showImg} alt=" " />
            </a>
            <button
              type="submit"
              ref={buttonRef}
              style={{ display: "none" }}
            ></button>
          </form>
        </div>

        {/* bottom section */}

        <div className="social-media-list-container">
          <ul>
            <li className="heading">Social Media</li>
            <div className="flex-icon-display">
              <a
                href="https://www.linkedin.com/company/finconicofficial"
                target="_blank"
              >
                {" "}
                <li>
                  <img
                    className="img-one"
                    src={linkedInIcon}
                    alt=" linkedin-logo"
                    style={{ cursor: "pointer" }}
                  />
                </li>
              </a>

              <a
                href="https://www.instagram.com/finconic_official/"
                target="_blank"
              >
                <li>
                  <img
                    className="img-one"
                    src={instagramIcon}
                    alt="instagram-logo "
                    style={{ cursor: "pointer" }}
                  />
                </li>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61554406600601"
                target="_blank"
              >
                <li>
                  <img
                    className="img-one "
                    src={faceBookIcon}
                    alt=" facebook-logo"
                    style={{ cursor: "pointer" }}
                  />
                </li>
              </a>
            </div>
          </ul>
        </div>

        {/* copyright section */}

        <div className="copyright-text-container">
          <p>© 2023 Finconic. All rights reserved.</p>
        </div>
      </section>
    </div>
  );
};

export default MobileFooter;
