import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./CgtmseTable.css";

const columns = [
  {
    id: "category",
    label: "Category (Including Trading Activity)",
    minWidth: 170,
    width: 200,
    color: "#0b3c7f",
    fontWeight: 500,
    fontSize: "1.4rem",
    zIndex: 1,
  },
  {
    id: "limit1",
    label: "Upto ₹ 5 lakh",
    minWidth: 100,
    width: 200,
    color: "#0b3c7f",
    align: "left",
    fontWeight: 500,
    fontSize: "1.4rem",
    zIndex: 1,
  },
  {
    id: "limit2",
    label: "Above ₹ 5 Lakh & upto ₹ 50 Lakh",
    minWidth: 170,
    width: 200,
    color: "#0b3c7f",
    align: "left",
    fontWeight: 500,
    fontSize: "1.4rem",
    zIndex: 1,
  },
  {
    id: "limit3",
    label: "Above ₹ 50 Lakh & upto ₹ 500 Lakh",
    minWidth: 170,
    width: 200,
    color: "#0b3c7f",
    align: "left",
    fontWeight: 500,
    fontSize: "1.4rem",
    zIndex: 1,
  },
];

function createData(category, limit1, limit2, limit3) {
  return { category, limit1, limit2, limit3 };
}

const rows = [
  createData("Micro Enterprises", "85%", "75%", "75%"),
  createData(
    "MSEs located in North East Region (including Sikkim, UT of Jammu & Kashmir and UT of Ladakh)",
    "80%",
    "80%",
    "75%"
  ),
  createData(
    "Women entrepreneurs / SC/ST entrepreneurs / MSEs situated in Aspirational District / ZED certified MSEs / Person with Disability (PwD) / MSE promoted by Agniveers",
    "85%",
    "85%",
    "85%"
  ),
  createData("All other category of borrowers", "75%", "75%", "75%"),
];

export default function CgtmseTable() {
  return (
    <div className="cgtmse-table-container">
      <Paper sx={{ width: "90%" }}>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="right"
                  colSpan={4}
                  style={{
                    color: "#0b3c7f",
                    fontWeight: 600,
                    fontSize: "1.4rem",
                    zIndex: 1,
                  }}
                >
                  <p className="data-table-heading">
                    {" "}
                    Maximum extent of Gurantee Coverage, Where guranteed credit
                    facility is
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      width: column.width,
                      color: column.color,
                      fontWeight: column.fontWeight,
                      fontSize: column.fontSize,
                      zIndex: column.zIndex,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow hover tabIndex={-1} key={i}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            color: "#656565",
                            fontWeight: 500,
                            fontSize: "1.2rem",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
