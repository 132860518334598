import React from "react";

const FooterCopyright = () => {
  const style = {
    width: "100%",
    color: "white",
    fontSize: "1rem", // Adjusted font size for tablets
    fontWeight: "600",
    lineHeight: "24px",
    wordWrap: "break-word",
  };

  return (
    <div className="bg-[#0B3C7F] w-full py-6 px-2 flex items-center absolute md:relative bottom-0">
      <p style={style} className="ml-4 md:ml-24">
        © Copyright Finconic. All Rights Reserved
      </p>
    </div>
  );
};

export default FooterCopyright;
