import React, { useState } from "react";

const MobileTeamMemberCard = ({ profile }) => {
  return (
    <div className="card-mobile relative">
      <div className="content-mobile">
        <div className="front-mobile">
          <img src={profile.image} alt="" />
        </div>
        <div className="front-mobile-desc">
          <div className="front-mobile-desc-description">{profile.name}</div>
          <div className="front-mobile-desc-designation">
            {profile.designation}
          </div>
        </div>
        <div className="back-mobile">
          <div className="MobileTeamMemberCard-hover-card">
            <div className="MobileTeamMemberCard-hover-card-img">
              <img src={profile.image} alt="" />
            </div>
            <div className="MobileTeamMemberCard-hover-card-desc">
              <div>
                <p className="MobileTeamMemberCard-hover-card-desc-name z-1">
                  {profile.name}
                </p>
                <p className="MobileTeamMemberCard-hover-card-desc-desigination z-1">
                  {profile.designation}
                </p>
                <p className="MobileTeamMemberCard-hover-card-desc-description z-1">
                  {profile.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileTeamMemberCard;
