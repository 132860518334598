import React from "react";
import "./PrivacyPolicies.css";
const PrivacyPolicies = () => {
  return (
    <div className="privacy-policies-container">
      <h1 className="privacy-policies-container-heading">Privacy Policy</h1>
      <div className="privacy-policies-effective-date-text-container">
        {/* <h1 className="privacy-policies-effective-date-heading">
          Effective Date: 24-10-2023
        </h1> */}
        <p className="privacy-policies-effective-date-text">
          Finconic First Private Limited hereinafter named as Finconic respects
          the privacy, confidentiality and security of the Personal Information
          (that resides with the Finconic) of its users and customers and take
          every possible steps to safeguard this information.
        </p>
      </div>
      {/* para 1 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          What is Personal Information
        </h1>
        <div className="privacy-policies-personal-info-text">
          <p>
            Personal Information means any information that relates to a
            physical person, which either directly or indirectly is capable of
            identifying such person.
          </p>

          <p>
            The{" "}
            <a className="terms-and-policy-finconic-link" href="/">
              https://www.finconic.com
            </a>{" "}
            provides you the general information and does not deem to constitute
            any recommendation to purchase any product or service. We strongly
            advise that independent professional advice be obtained before you
            purchase any product and/or service via{" "}
            <a className="terms-and-policy-finconic-link" href="/">
              https://www.finconic.com
            </a>{" "}
            .
          </p>

          <p>
            This Privacy Policy includes how we protect the personal information
            of our users or clients, provided to us through {"  "}
            <a className="terms-and-policy-finconic-link" href="/">
              https://www.finconic.com
            </a>{" "}
            and when also covers Finconic’s treatment of any personally
            identifiable information that its business partners share with
            Finconic. This policy is not applicable to third party companies
            that Finconic does not own or control, or to people that Finconic
            does not employ or manage.
          </p>
        </div>
      </div>

      {/* para 2 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          Information We Collect
        </h1>
        <div className="privacy-policies-personal-info-text">
          We collects your personal information when you register or make
          enquiry for any product/service, when you visit {"  "}
          <a className="terms-and-policy-finconic-link" href="/">
            https://www.finconic.com
          </a>{" "}
          when you enter promotions or sweepstakes. When you register with{" "}
          <a className="terms-and-policy-finconic-link" href="/">
            https://www.finconic.com
          </a>
          , we may ask for your name, time zone, zip/postal code, country,
          state, city, email, contact number, birth date and gender, employment
          detail and income detail, PAN No. etc. By registration you authorize us
          to contact you through sms/email/call for your login details and
          alerts or some advertising messages/emails. If at any time you wish to
          stop these sms/emails/calls, you have to write us an email at info@finconic.com 
          with your mobile number and we will delete your number
          from the alerts list.
        </div>
      </div>

      {/* para 3 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          Why We Collect Information
        </h1>
        <div className="privacy-policies-personal-info-text">
          <li>To contact you for replying to your queries.</li>
          <li>
            To support you on your problems related to our business and
            services.
          </li>
          <li>To expand our business.</li>
          <li>
            To understand our customer interests and improve our products and
            services
          </li>
          <li>Information from cookie to collect website statistics.</li>
          <li>To understand audience activity on the website.</li>
          <li>
            To carry out our business contracts with our business partners.
          </li>
          <li>For our marketing campaigns with personalized offers.</li>
        </div>
      </div>

      {/* para 4 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          Third Party Product/Links
        </h1>
        <div className="privacy-policies-personal-info-text">
          Please remember, we does not examine whether the advertisers are good,
          reputable or quality sellers of goods / service providers. You must
          satisfy yourself about all relevant aspects prior to availing of the
          terms of service.{" "}
          <a className="terms-and-policy-finconic-link" href="/">
            https://www.finconic.com
          </a>{" "}
          has also not negotiated or discussed any terms of engagement with any
          of the advertisers. The same should be done by you. Purchasing of
          goods or availing of services from advertisers shall be at your own
          risk.
          <br />
          Please note that we do not investigate, represent or endorse the
          accuracy, legality, legitimacy of the service providers who advertise
          with us. We do not endorse, sponsor or recommend any product or
          service of the third party.
        </div>
      </div>

      {/* para 5 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          Period of Use of Personal Information
        </h1>
        <div className="privacy-policies-personal-info-text">
          Period for which we can keep your personal information is maximum of
          10 years from the date our contract ends in case of existing customers
          and up to 5 years from the date you last contacted us in case of
          others.
        </div>
      </div>

      {/* para 6 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          Information Sharing
        </h1>
        <div className="privacy-policies-personal-info-text">
          Finconic will not sell or rent your personal information to anyone
          other than as specifically noted herein. However, we may share your
          data within our business channels for the purpose mentioned above
          only. We will not share your information to the third party for the
          sake of any marketing campaign. We are using many services from
          various but trusted third parties for analyses and marketing of our
          business like our business partners, service vendors, our agents, our
          contractors etc. We can share your data with these business partners
          and also share your personal data to the government for the purposes
          of national security and any other matters of public importance. We
          may also disclose your information in case of detection of any
          indulgence in fraud or in any other circumstances where we find it
          necessary in good faith to protect our rights.
        </div>
      </div>

      {/* para 7 */}
      <div className="privacy-policies-personal-info-text-container">
        <h1 className="privacy-policies-personal-info-heading">
          Privacy Policy Changes
        </h1>
        <div className="privacy-policies-personal-info-text">
          We keep the right to make changes to this policy as per the new
          technological and regulatory requirements. In case, we make any
          changes to this policy, we may notify the user by displaying a
          prominent announcement on the Home Page of Website for thirty (30)
          days, being deemed sufficient notification. If you have not used the
          Site for more than 30 days, you are advised to check and read the
          policies properly before further use.
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicies;
