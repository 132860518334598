import React, { useState } from "react";
import "./TermsAndPolicies.css";
import Navbar from "../../Navbar/Navbar";
import PrivacyPolicies from "./PrivacyPolicies/PrivacyPolicies";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import { buttonState } from "../../../utils/StateData/stateData";
import Footer from "../../Footer/Footer";

const TermsAndPolicies = () => {
  const [activeButton, setActiveButton] = useState(buttonState.state);

  return (
    <>
      <Navbar />

      <div className="terms-and-policies-container">
        <div className="terms-and-policies-buttons-container">
          <div
            className={`privacy-policy-button ${
              activeButton === "privacy" ? "active" : ""
            }`}
            onClick={() => setActiveButton("privacy")}
          >
            <h1 className="privacy-policy-button-heading">Privacy Policy</h1>
          </div>
          <hr />
          <div
            className={`terms-of-use-button ${
              activeButton === "terms" ? "active" : ""
            }`}
            onClick={() => setActiveButton("terms")}
          >
            <h1 className="terms-of-use-button-heading">Terms of Use</h1>
          </div>
        </div>
        <div className="terms-and-policies">
          {activeButton === "privacy" ? <PrivacyPolicies /> : null}
          {activeButton === "terms" ? <TermsAndConditions /> : null}
        </div>
      </div>

      <div className="terms-and-policies-footer-container">
        <Footer />
      </div>
    </>
  );
};

export default TermsAndPolicies;
