import React, { useEffect, useState } from "react";
import "./App.css";

import { Helmet } from "react-helmet";
import ScrollToTop from "./Components/ScrollToTop";

import DesktopMobileRoutes from "./utils/Routes/DesktopMobileRoutes";
import MobileRoutes from "./utils/Routes/MobileRoutes";
function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const screenWidth = window.innerWidth;

    const isMobileDevice =
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    const isSmallScreen = screenWidth < 768;

    setIsMobile(isMobileDevice && isSmallScreen);
  }, []);

  return (
    <>
      <div className="App">
        <Helmet>
          <title>Finconic</title>
          <meta name="description" content="your iconic banking partner" />
        </Helmet>
        <ScrollToTop />
        {!isMobile ? (
          <DesktopMobileRoutes /> // routes only for Desktop and tablets
        ) : (
          <MobileRoutes /> // Routes only for mobile
        )}
      </div>
    </>
  );
}
export default App;
