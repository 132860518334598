import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-and-condition-container">
      <h1 className="terms-and-condition-heading">Terms of Use</h1>
      <div className="terms-and-condition-descriptions-text-container">
        <p className="terms-and-condition-descriptions-text">
          <a className="terms-and-policy-finconic-link" href="/">
            https://www.finconic.com
          </a>{" "}
          is a website (the "Site") operated by Finconic First Private Ltd.
          Finconic provides its services to you, subject to the following
          conditions. Before you use the site, you must read and accept all of
          the linked Terms of Use and Privacy Policy. Use of any functionality
          of the site constitutes acceptance of this Terms of Use. In case of
          any conflict between this Terms of Use with any other documents, the
          Terms of Use will control for the purposes of usage of the Site. If
          you do not agree to Terms of Use and the Privacy Policy, you should
          not use this site in any way.
        </p>
        <p className="terms-and-condition-descriptions-text">
          We reserve the right to review and make changes to the current TOU,
          without any prior notice.
        </p>
        <p
          className="terms-and-condition-descriptions-text"
          style={{ fontWeight: "600" }}
        >
          PLEASE READ AND UNDERSTAND THE PRIVACY POLICY AND TERMS OF USE
          EVERYTIME YOU VISIT OUR WEBSITE.
        </p>
        <p className="terms-and-condition-descriptions-text">
          This document/agreement is an electronic record in terms of
          Information Technology Act, 2000 and generated by a computer system
          and does not require any physical or digital signatures. This document
          is published in accordance with the provisions of Rule 3 of the
          Information Technology (Intermediaries guidelines) 2011, that provides
          for the due diligence to be exercised for the access or usage of this
          Website.
        </p>
      </div>

      <div className="terms-and-condition-container">
        {/* para 1 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            1. Description of Services
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              In this website, Finconic provides users the information about
              Financial and Utility Services including Loans, Credit Cards,
              investments etc. You are responsible for obtaining access to the
              site and that access may involve third-party fees (such as
              Internet service provider or airtime charges). By using this
              website and providing the personal information, you hereby agree
              that you are interested in purchasing the services that you have
              selected and Finconic may contact you either electronically or
              through phone, to understand your interest in the selected
              products and services.
            </p>
          </div>
        </div>

        {/* para 2 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            2. License and Site Access
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Finconic grants a limited license to access and make personal use
              of this Site and the Service. This license does not include any
              downloading or copying of any kind of information for any of the
              commercial or public purpose, caching, unauthorized links,
              uploading, posting, or transmitting any content that user do not
              have a right to make available, or transmitting any material that
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the functionality
              of any computer software or hardware or telecommunications
              equipment; Any unauthorized use shall terminate the permission or
              license granted to user by Finconic.
            </p>
          </div>
        </div>

        {/* para 3 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            3. Privacy Policy
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              It is apparent that, by using this website you are giving your
              permission to use of your personal data as mentioned in our
              Privacy Policy.{" "}
            </p>
            <p style={{ fontWeight: "600" }}>
              PLEASE READ THE PRIVACY POLICY BEFORE USING ANY OF THE SERVICES.
            </p>
          </div>
        </div>

        {/* para 4 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            4. Eligibility
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              The service is available only to adults having the age of 18 or
              above.
            </p>
          </div>
        </div>

        {/* para 5 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            5. Your Account
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              By using this website, you represent that you are of legal age and
              capable to form a binding contract. You further represent that you
              are not a person barred from receiving services under the laws of
              India or other applicable jurisdiction. You are authorizing to use
              this Site to make legal purchases for you or for another person
              for whom you are legally authorized to act. You also agree to
              provide true, accurate, current and complete information about
              yourself. If you provide any wrong or untrue information Finconic
              has the right to refuse any and all current or future use of the
              Site (or any portion thereof).
            </p>
            <p>
              You are also solely responsible for the security and
              confidentiality of your LOG IN ID and Password of your account on
              this website and Finconic shall not be liable for any unauthorized
              access of your account.
            </p>
          </div>
        </div>

        {/* para 6 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            6. Content
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Hereby you agree to take the full responsibility for all the
              content including any data and information shared or submitted by
              you on this website. By submitting such content, you grant
              Finconic the royalty free and non-exclusive license to display,
              modify, use, copy or sublicense such content or part thereof.
            </p>
            <p>
              You are prohibited to add any unlawful, defamatory, abusive,
              threatening, commercial, misappropriate, or any other violating
              the law.
            </p>
            <p>
              Finconic always try to ensure that the content, data and
              information on its website are accurate In case of any inaccuracy
              we try to correct errors or omissions as soon as practicable after
              being notified of them. But, we are not responsible in any way if
              the data, material and information on the website is found to be
              inaccurate or if there are any errors or omissions in the data,
              material and information and you should be aware that such
              information may be inaccurate, incomplete or out of date. We are
              not responsible for any losses or damages arising from an
              inability to access the websites, or from use of the websites or
              from reliance on the data transmitted using the website where such
              losses or damages are caused by any event beyond our reasonable
              control including as a result of the nature of electronic
              transmission of data over the internet.
            </p>
            <li className="terms-and-container-sublist">
              Exclusion of liability for third party products and services
            </li>
            <p>
              You can apply for and buy a number of products and services via
              our website. Here it is expressly clarified that we do not provide
              any of the products and services that appear on the website. All
              these products and services are from third parties over whom we do
              not have any control. You should satisfy yourself that you wish to
              purchase those products or services before contracting with the
              third parties. We are not responsible for any products or services
              or information provided by third party providers.
            </p>
            <li className="terms-and-container-sublist">
              {" "}
              Exclusion of liability for Advertisements
            </li>
            <p>
              The advertisements that is displayed on this website are delivered
              on our behalf by a third party. These parties are independent 3rd
              parties and we have no control over them. We try to act in good
              faith in providing a comparison service and allowing advertisers
              to advertise on it. We are not responsible for their services
              advertised on this site.
            </p>
          </div>
        </div>

        {/* para 7 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            7. License Disclaimer
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Nothing on any Finconic’s website shall be construed as conferring
              any license under any of Finconic’s or any third party's
              intellectual property rights, whether by estoppel, implication, or
              otherwise.
            </p>
          </div>
        </div>

        {/* para 8 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            8. Local Laws
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Finconic controls and operates this Website from its headquarters
              in Delhi, India and makes no representation that the content on
              this website is appropriate or available for use in other
              locations. If you use this Website from other locations, you are
              responsible for compliance with applicable local laws including
              but not limited to the export and import regulations of other
              countries.
            </p>
            <p
              style={{
                color: "#656565",
                fontWeight: "600",
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
              }}
            >
              Jurisdiction Clause:
            </p>
            <p>
              The courts of Delhi will have exclusive jurisdiction to adjudicate
              any dispute arising under or in connection with this Agreement.
            </p>
          </div>
        </div>

        {/* para 9 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            9. Limitation of Liability
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              You expressly agree that Finconic, affiliates, officers,
              employees, agents, partners and licensors shall not be liable to
              anybody for any direct, indirect, incidental, special, or any
              other kind of damages, including, but not limited to, damages for
              loss of profits, goodwill, or other intangible losses, resulting
              from use of this site, or any related services.
            </p>
            <p>
              If, however in any case, Finconic or its Affiliates are found
              liable for any loss or damage which arises out of any of the
              occurrences described above, then the liability of Finconic and/or
              Its Affiliates will in no event exceed, in the aggregate, the
              greater of the service fees you paid to Finconic in connection
              with such transaction(s) on this Site, or Rupees One Hundred only
              (INR 100).
            </p>
          </div>
        </div>

        {/* para 10 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            10. Indemnity
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              You agree to indemnify Finconic (and its officers, directors,
              agents, subsidiaries, joint ventures, and employees) against any
              claims, causes of action, demands, recoveries, losses & damages,
              fines & penalties or other costs or expenses of any kind or
              nature, including reasonable attorneys' fees, or arising out of or
              related to your breach of this Terms of Use, your violation of any
              law or the rights of a third party, or your use of the Site.
            </p>
          </div>
        </div>

        {/* para 11 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            11. Electronic Communication
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Use of this Site, an electronic communication with Finconic gives
              your consent to receive communications from Finconic
              electronically. Finconic may communicate with you by email or by
              posting notices on the Site or by phone or usually available means
              of communication.
            </p>
          </div>
        </div>

        {/* para 12 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            12. Electronic Communication
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              We here want to alert you that In case you are asked for any
              personal information by any third party links on our website,
              please spend some time to read and understand the privacy policy
              of that third party before sharing the information. Finconic don’t
              accept any liability for the privacy policies of others.
            </p>
          </div>
        </div>

        {/* para 13 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            13. Password Protected/Secure Areas
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Password protected areas of the Site is restricted to authorized
              users only. Any Unauthorized attempt to access these areas of the
              Site may be subject to prosecution.
            </p>
          </div>
        </div>

        {/* para 14 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            14. Password Protected/Secure Areas
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Finconic reserves the right to make changes to the Website,
              Policies and Agreements, this Terms of Use and the Privacy Policy
              at any time without any prior notice. Thus you are advised to
              check and read the policies properly before further use.
            </p>
          </div>
        </div>

        {/* para 15 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            15. Trademarks/Content/Software Programs
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              The trademarks, logos and service marks ("Marks") displayed on the
              Site are the property of Finconic and other parties. All Marks,
              information and content including any software programs available
              on or through the Site ("Content") is protected by copyright.
              Users are prohibited from modifying, copying, distributing,
              transmitting, displaying, publishing, selling, licensing, creating
              derivative works or using any Content available on or through this
              Site for any commercial or public purposes.
            </p>
          </div>
        </div>

        {/* para 16 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            16. Survival of Terms After Agreement Ends
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              Any general legal principles to the contrary, any provision of
              this ToU that imposes or contemplates continuing obligations on a
              party will survive the expiration or termination of this ToU.
            </p>
          </div>
        </div>

        {/* para 17 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            17. General
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              If any of the above conditions are deemed invalid, void, or
              unenforceable by any reason, the parties agree that the court
              should attempt to give effect to the party's intentions as
              reflected in the provision, and the unenforceable condition shall
              not affect the enforceability of any remaining condition. Headings
              are for reference purposes only and do not limit the scope of such
              section. This ToU and the relationship between you and Finconic
              will be governed by the laws of the India. Except as otherwise
              expressly provided in this ToU, there shall be no third-party
              beneficiaries to this ToU. This ToU constitutes the entire
              agreement between you and Finconic and governs your use of the
              Site, superseding any prior agreements between you and Finconic
              with respect to the Site.
            </p>
          </div>
        </div>
      </div>

      {/* cookie policies*/}
      <div className="coockie-policies-container">
        <h1 className="cookie-policies-heading">Cookie Policy</h1>
        <div className="cookie-policies-description">
          <p>
            A Cookie is a small file which is sent from our website and stored
            in your computer while you visit our website through a web browser.
            Due to which we can get the information on “how you have used our
            website”. We use this statistic to study and analyze trends,
            administer website, track user movements around the website, study
            user’s browsing habits and enhance the user’s experience.
          </p>
        </div>
        {/* para 1 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            Types of Cookies
          </h1>
          <div className="terms-and-condition-personal-info-text">
            {/* type 1 */}
            <li className="terms-and-container-sublist">Session Cookie</li>
            <p>
              Session Cookie exists in temporary memory only for the current
              session when the user navigates the website. These are usually
              deleted by the browser itself at the time of termination of
              session.
            </p>

            {/* type 2 */}
            <li className="terms-and-container-sublist">Persistent Cookies</li>
            <p>
              Persistent Cookies are stored in your computer for a particular
              time period assigned to them.
            </p>
            <p>
              It means that during its life span whenever when user visits the
              website, it will send the information about the activity to the
              server to which website belongs.
            </p>
          </div>
        </div>

        {/* para 2 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            Accept or Refuse Cookies
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              You can always accept or refuse from{" "}
              <a className="terms-and-policy-finconic-link" href="/">
                https://www.finconic.com
              </a>{" "}
              by changing your browser settings. If you reject cookies, you may
              still access our website but with limited rights.
            </p>
          </div>
        </div>
      </div>

      {/* 3p content */}
      <div className="third-party-content-container">
        <h1 className="third-party-content-heading">Third Party Content</h1>
        <div className="third-party-content-description">
          <p>
            We may share many information from third party like articles,
            reports, statistics etc. By visiting our website your agree to the
            fact that these third party content and information are made visible
            only for the sake of general information and not created by us. Thus
            we are unbiased and don’t make recommendations.
          </p>
        </div>
        {/* para 1 */}
        <div className="terms-and-condition-personal-info-text-container">
          <h1 className="terms-and-condition-personal-info-heading">
            Jurisdiction Clause:
          </h1>
          <div className="terms-and-condition-personal-info-text">
            <p>
              The courts of Delhi will have exclusive jurisdiction to adjudicate
              any dispute arising under or in connection with this Agreement.
            </p>
          </div>
        </div>
      </div>

      {/* disclaimer */}
      <div className="disclaimer-container">
        <h1 className="disclaimer-heading">Disclaimer</h1>
        <div className="disclaimer-description">
          <p>
            This website may contain inaccuracies and typographical and clerical
            errors.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
