import React, { useState } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../MSMELoan/MSMELoan.css";

function MSMELoan() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input
    const phoneNumberDigits = value.replace(/\D/g, "");
    // Limit the phone number to 10 digits
    const limitedPhoneNumber = phoneNumberDigits.slice(0, 10);
    setPhoneNumber(limitedPhoneNumber);
  };

  return (
    <>
      <Navbar />
      {/*     
      <div className='herosection-flex-img-container-md'>
        <div className='hero-img-md-card-img'>
          <img src="/Image/msme loan 1.png" alt=" " />
        </div>
        <div className='content-section-md-flex-size-md'>
          <div className='md'><h2> </h2>
            <h2>MSME Loan</h2>
            <h2></h2></div>

          <div className='section-input-flex'>
            <img src="/Icon/download 2.png" alt="" />
            <input type="number" placeholder='+91 99-9999-9999' />
            <button className='cta-btn-flex size' >Apply Now</button>
          </div>

        </div>
      </div> */}
      <section className="herosection-flex-img-container-md">
        <div className=" inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src="/HeaderImg/msme loan (1).png"
              alt=""
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> MSME Loan</h2>
            <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex">Apply Now</button>
            </div>
          </div>
        </div>
      </section>
      {/* content */}
      <div className="busniness-content-p">
        <p>
          MSME loan is the loan amount which is to be used specifically for
          business purposes. In todayâ€™s dynamic business environment, funds
          may be required in a business for the purpose of funding a startup or
          for the expansion purpose. A business loan is a debt which is
          repayable back with the interest in the pre agreed period of time and
          with certain terms and conditions.
        </p>
        <p>
          High competition in the economy has generated the high demand of funds
          for the smooth running of business. MSME Loan fulfills the fund
          requirement of any business either short term or long term. MSME loan
          ensures the smooth cash flow in the business and helps to face the
          business challenges from time to time.
        </p>
      </div>
      {/* rotating imag-animation */}
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col">
            <h1>Features of MSME Loan</h1>
            <ul>
              <li>Quick approval</li>
              <li>Low rate of interest</li>
              <li>Attractive bundled offerings</li>
              <li>No Collateral</li>
              <li>Less Documentation</li>
              <li>Long tern & short term financing</li>
              <li>Flexible Tenure</li>
              <li>Large Capital</li>
            </ul>
          </div>
          <div className="image-md">
            <img
              className="img-1
                    "
              src="/CardImg/Component 50.png"
              alt=""
            />
            <img className="img-2" src="/CardImg/01.png" alt="" />
          </div>
        </div>
      </div>
      {/* card-label */}
      <div className="section-head">
        <div className="outer-section-flex-row card-one">
          <div className="allignment">
            <h2>Factors Affecting the Business Loan Amount</h2>
            <div className="card-one-flex">
              <div className="card-img-one-md-img">
                <img src="/Image/bsns_man_5 1.png" alt="" />
              </div>
              <div className="card-container-box">
                <div className="one-card">
                  <h3>Repayment Capacity</h3>
                </div>
                <div className="two-card">
                  <h3>Average Profit earned from the business</h3>
                </div>
                <div className="three-card">
                  <h3>Cash flow of the business</h3>
                </div>
                <div className="four-card">
                  <h3>Years of existence of the business</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* floating */}
      <div className="container-flex-row">
        <div className="inner-container">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Business Loan?</h2>
            <p>
              Sole proprietorship firm/Partnership Firms/Any registered
              Company/Self Employed Professionals
            </p>
            <p>Business must have at least 3 years of existence</p>
            <p>ITR filing for at least 1 year</p>
          </div>
          <div className="card-image-flex floating">
            <img src="/Image/card-img.png" alt="" />
          </div>
        </div>
      </div>
      {/* rombus card */}
      <div className="outer-section-flex-row">
        <h2>Documents Required</h2>
        <div className="card-flex-row-md" data-aos="fade-up">
          <div className="card-img-md">
            <img src="/Image/Card-2.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-3.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-1.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-4.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MSMELoan;
