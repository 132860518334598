import axios from "axios";

const apiHostname =
  process.env.REACT_APP_API_HOSTNAME || "https://www.finconic.com";
const apiPathPrefix = process.env.REACT_APP_API_PATH_PREFIX || "gateway";
const apiPathWeb = process.env.REACT_APP_API_WEB || "finconicwebapi";

// const base_url = "http://192.168.29.9:8080/"; // Replace with your base URL
const base_url = `${apiHostname}/${apiPathPrefix}/${apiPathWeb}/`;

const axiosRequest = async (method, url, data = null, isForm = false) => {
  const headers = {
    "Content-Type": isForm ? "multipart/form-data" : "application/json",
  };

  const config = {
    method: method,
    url: `${base_url}${url}`,
    // headers: headers,
    data: data,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    throw new Error("Something went wrong");
  }
};

export default axiosRequest;
