import React, { useState } from "react";
import "../../Pages/TermsAndPolicies/TermsAndPolicies.css";
import MobileFooter from "../Footer/MobileFooter";
import MobileNavbar from "../MobileNavbar/MobileNavbar";
import PrivacyPolicies from "../../Pages/TermsAndPolicies/PrivacyPolicies/PrivacyPolicies";
import TermsAndConditions from "../../Pages/TermsAndPolicies/TermsAndConditions/TermsAndConditions";
import { buttonState } from "../../../utils/StateData/stateData";

const MobileTermsAndPolicies = () => {
  const [activeButton, setActiveButton] = useState(buttonState.state);

  return (
    <>
      <div className="terms-and-policies-container">
        <div className="terms-and-policies-buttons-container">
          <div
            className={`privacy-policy-button ${
              activeButton === "privacy" ? "active" : ""
            }`}
            onClick={() => setActiveButton("privacy")}
          >
            <h1 className="privacy-policy-button-heading">Privacy Policy</h1>
          </div>
          <hr />
          <div
            className={`terms-of-use-button ${
              activeButton === "terms" ? "active" : ""
            }`}
            onClick={() => setActiveButton("terms")}
          >
            <h1 className="terms-of-use-button-heading">Terms of Use</h1>
          </div>
        </div>
        <div className="terms-and-policies">
          {activeButton === "privacy" ? <PrivacyPolicies /> : null}
          {activeButton === "terms" ? <TermsAndConditions /> : null}
        </div>
      </div>

      <MobileFooter />
    </>
  );
};

export default MobileTermsAndPolicies;
