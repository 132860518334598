import React from "react";
import Navbar from "../../Navbar/Navbar";
import "./notFound.css";
import { bottomImg, mainImg, topImg } from "../../../utils/ImagesData/imagesData";

const NotFound = () => {
  return (
    <div>
      <Navbar />
      <div className="relative h-screen w-screen flex  items-center">
        <img
          src={topImg}
          alt="Not Found Cloud image"
          className=" absolute top-0 h-[25.1rem] w-[30.5rem] left-0 z-[-1] "
        />
        <img
          src={mainImg}
          alt="Not Found Image"
          className=" w-[99.754rem] h-[45.131rem] absolute top-[7.321rem] left-[6.688rem] ml-[9rem] mt-[3rem] "
        />
        <img
          src={bottomImg}
          alt="Not Found Cloud image"
          className=" absolute bottom-0 right-0 h-[30.591rem] w-[30.25rem]"
        />
        <div className=" absolute right-72 flex justify-center items-center flex-col mt-[-4rem] mr-[8rem]">
          <h2 className="   text-[#0B3C7F] notFoundText">Page Not Found</h2>
          <h3 className="    text-[#656565] m-auto notFoundSmallText mt-2 ">
            This page doesn’t exist or was removed! We suggest you go back to
            home.
          </h3>
          <button
            className="notFoundButton cta-btn-flex"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Go back Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
