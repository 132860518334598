import React, { useState, useEffect, useRef } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import "../Navbar/Navbar.css";
import { NavLink, useLocation } from "react-router-dom";
import DropDownIcon1 from "../../assets/icons/DropDownIcon1";
import { companyLogo } from "../../utils/ImagesData/imagesData";

function Navbar() {
  const [show, setShow] = useState(false);
  const [navbarBackground, setNavbarBackground] = useState(
    `linear-gradient(180deg, rgba(228, 240, 255, 0.8) 0%, #FFF 100%)`
  );
  const [navbarWidth, setNavbarWidth] = useState("94%");
  const [navbarBorderRadius, setNavbarBorderRadius] = useState("1.5rem");
  const [navbarTop, setNavbarTop] = useState("1rem");
  const { pathname } = useLocation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const baseColor = "linear-gradient(180deg, #E4F0FF 0%, #FFF 100%)";

      if (currentScrollY > 100) {
        // set the background color to a dark gray when the user scrolls
        setNavbarBackground(baseColor);
        setNavbarWidth("101%");
        setNavbarBorderRadius("0");
        setNavbarTop("0");
      } else {
        // set the background color with reduced opacity when the user scrolls back to the top
        const transparentColor = "rgba(228, 240, 255, 0.8)";
        setNavbarBackground(`${transparentColor}, ${baseColor}`);
        setNavbarWidth("94%");
        setNavbarBorderRadius("1.5rem");
        setNavbarTop("1rem");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // remove the event listener when the Navbar component unmounts
    };
  }, []);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <section className="nav-bar-con-tainer z-[50]">
      <div className="mid-content">
        <div
          style={{
            background: navbarBackground,
            width: navbarWidth,
            borderRadius: navbarBorderRadius,
            top: navbarTop,
          }}
          className="navbar-container-flex-md"
        >
          <div className="image-flex-list-row">
            <div className="image-flex-logo">
              <NavLink onClick={scrollToTop} to="/">
                <img src={companyLogo} alt="Company Logo" />
              </NavLink>
            </div>
            <ul className="list-container-flex-li">
              <div className="flow-one" style={{ zIndex: "1200" }}>
                <div className="flex-tag-a " onClick={() => setShow(!show)}>
                  <button className="hover:border-b-2 hover:border-[#0b3c7f]">
                    Services
                  </button>
                  <DropDownIcon1 />
                </div>
                {show ? (
                  <ul
                    className="dropdown-menu "
                    ref={menuRef}
                    style={{ zIndex: "1200" }}
                  >
                    <NavLink to="/business-loan" style={{ zIndex: "1200" }}>
                      <li
                        className="products-dropdown-list-items"
                        style={{ zIndex: "1200" }}
                      >
                        Unsecured Business Loan
                      </li>
                    </NavLink>
                    <NavLink to="/dropline-limit" style={{ zIndex: "1200" }}>
                      <li
                        className="products-dropdown-list-items"
                        style={{ zIndex: "1200" }}
                      >
                        Unsecured Dropline Limit
                      </li>
                    </NavLink>
                    <NavLink to="/cgtmse" style={{ zIndex: "1200" }}>
                      <li
                        className="products-dropdown-list-items"
                        style={{ zIndex: "1200" }}
                      >
                        CGTMSE
                      </li>
                    </NavLink>
                  </ul>
                ) : null}
              </div>
              <NavLink to="/about">
                <li className="hover:border-b-2 hover:border-[#0b3c7f]">
                  About Us
                </li>
              </NavLink>
              <NavLink to="/contact">
                {" "}
                <li className="hover:border-b-2 hover:border-[#0b3c7f]">
                  Contact Us
                </li>{" "}
              </NavLink>
            </ul>
          </div>
          {/* <button className="list-container-li-sl">Log In</button> */}
        </div>
      </div>
    </section>
  );
}
export default Navbar;
