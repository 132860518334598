import React from "react";
import "./AboutUsBanner.css";
import {
  aboutPic,
  chatGif,
  dataSecurityPic,
} from "../../../utils/ImagesData/imagesData";

const AboutUsBanner = () => {
  return (
    <>
      <section
        className="about-us-banner-container"
        aria-label="About Us Section"
      >
        <div className="about-us-tagline-container" role="banner">
          <h1 className="about-us-tagline-text">About Us</h1>
          <div className="dot left-dot" aria-hidden="true"></div>
          <div className="about-us-line" role="presentation"></div>
          <div className="dot right-dot" aria-hidden="true"></div>
        </div>
        <div className="main-card-container-flex-side">
          <div className="inner-card-container-flex-side">
            <div className="card-container-written-content-flex-col">
              <div className="writen-container-one">
                <h2 className="heading-one">Get to know more about</h2>
                <h1 className="heading-two">FINCONIC</h1>
                <p className="pera-1">
                  We focus on client service with integrated solutions to
                  arrange funds for the client’s business expansion or for any
                  other reason best known to them. We are driven by a dynamic
                  and multi disciplinary team of professionals.
                </p>
                <p className="pera-2">
                  We work on various products such as Business loans, OD
                  facility, CGTMSE etc. At Finconic we love working with
                  organizations from all walks of life no matter the amount of
                  fund required.
                </p>
              </div>
            </div>
            <div className="about-pic-card">
              <img className="about-pic" src={aboutPic} alt="About Us" />
            </div>
          </div>
        </div>
        <div className="main-card-container-flex-side-two">
          <div className="inner-card-container-flex-side">
            <div className="card-container-written-content-flex-col">
              <div className="writen-container-one">
                <h2 className="heading-one">
                  Data Security is our First Priority
                </h2>
                <p className="pera-1">
                  At Finconic, we prioritize the security of your data. Our
                  commitment is underscored by our{" "}
                  <span style={{ fontWeight: "600" }}>ISO 27001:2022</span>{" "}
                  certification, a testament to the stringent controls we've put
                  in place.
                </p>
                <p className="pera-2">
                  Our information is safeguarded through industry-leading
                  measures, setting the gold standard for data protection. Trust
                  us to keep your data safe – it's not just a promise; it's our
                  certified commitment to your peace of mind.
                </p>
              </div>
            </div>
            <div className="image-card-two">
              <img className="top" src={dataSecurityPic} alt="Data Security" />
            </div>
          </div>
        </div>
        <div className="main-card-container-flex-side-three">
          <div className="inner-card-container-flex-side">
            <div className="card-container-written-content-flex-col">
              <div className="writen-container-one">
                <h2 className="heading-three">Get Speedy Support</h2>
                <p className="pera-1">
                  "At Finconic, we pride ourselves on delivering fast and
                  reliable financial solutions. Whether you need a loan or
                  financial guidance, we're here to provide quick and dependable
                  assistance. Trust Finconic for prompt, expert support to meet
                  your financial needs."
                </p>
              </div>
              <div className="div-main-contact-us-btn-container">
                <button
                  className="ctu-btn-flex"
                  onClick={() => {
                    window.location.href = "/contact";
                  }}
                >
                  Contact Us Now
                </button>
              </div>
            </div>
            <div className="chat-gif-card">
              <img className="top" src={chatGif} alt="Get Speedy Support" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsBanner;
