import React, { useState } from "react";
import "../LoanAgainstProperty/LoanAgainstProperty.css";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

function LoanAgainstProperty() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    // Remove any non-numeric characters from the input
    const phoneNumberDigits = value.replace(/\D/g, "");
    // Limit the phone number to 10 digits
    const limitedPhoneNumber = phoneNumberDigits.slice(0, 10);
    setPhoneNumber(limitedPhoneNumber);
  };
  return (
    <>
      <Navbar />

      <section className="herosection-flex-img-container-md">
        <div className=" inner-container-flex-row">
          <div className=" img-section-left">
            <img
              src="/HeaderImg/loan against property (1).png"
              alt=""
              className="img-one-md-p"
            />
          </div>
          <div className="section-content-p">
            <h2 className="heading-h2"> Loan Against</h2>
            <h2 className="heading-h2">Property</h2>
            <div className="section-input-flex">
              <div className="flag-input-container">
                <div className="flag-india-heading">
                  <img src="/Icon/download 2.png" alt="" />
                  <h4>+91</h4>{" "}
                </div>
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="Mobile Number"
                  autoComplete="off"
                />
              </div>
              <button className="cta-btn-flex">Apply Now</button>
            </div>
          </div>
        </div>
      </section>
      {/* card-animation */}
      <div className="section-one">
        <div className="animation-card-container">
          <div className="text-content-flex-col">
            <h1>Features of MSME Loan</h1>
            <ul>
              <li>Quick approval</li>
              <li>Low rate of interest</li>
              <li>Attractive bundled offerings</li>
              <li>No Collateral</li>
              <li>Less Documentation</li>
              <li>Long tern & short term financing</li>
              <li>Flexible Tenure</li>
              <li>Large Capital</li>
            </ul>
          </div>
          <div className="image-md">
            <img
              className="img-1
                    "
              src="/CardImg/Component 50.png"
              alt=""
            />
            <img className="img-2" src="/CardImg/01.png" alt="" />
          </div>
        </div>
      </div>
      {/* card-float */}
      <div className="container-flex-row-bg-color">
        <div className="inner-container-one">
          <div className="written-content-col-flow">
            <h2>Who is Eligible for the Professional Loan?</h2>
            <p>Age should be 18-65 years</p>
            <p>
              Individual/Joint borrowers/partnership
              firms/companies/proprietorships can apply for this loan
            </p>
            <p>
              Professionals who are already into the practice of their
              profession/trade with appropriate licenses.
            </p>
          </div>
          <div className="card-image-flex floating">
            <img src="/Image/a 5.png" alt="" />
          </div>
        </div>
      </div>
      {/* card
       */}
      <div className="outer-cont-er-md">
        <div className="card-container-one-md">
          <h2>Loans for Doctors</h2>
          <p>
            This business loan is provided to doctors who are practicing or
            giving consultation in government/private clinic or hospitals with
            at least 4 years of past experience. Qualification degree should be
            MBBS/BDS/MS/MD/MDS.
          </p>

          <p>Features</p>
          <p>
            Loan amount can be used to expand or renovate your hospital/clinic
            or to purchase new equipment etc.
          </p>
          <p>
            Loan Amount can be taken up to Rs. 1 crore without any security and
            Rs. 2 crore with mortgage.
          </p>
          <p>Loan tenure will be 1 â€“ 5 years.</p>
          <p>Interest rate starting from 9% p.a.</p>
        </div>
        <div className="card-container-one-md">
          <h2>Loans for Doctors</h2>
          <p>
            This business loan is provided to doctors who are practicing or
            giving consultation in government/private clinic or hospitals with
            at least 4 years of past experience. Qualification degree should be
            MBBS/BDS/MS/MD/MDS.
          </p>
          <p>Features</p>
          <p>
            Loan amount can be used to expand or renovate your hospital/clinic
            or to purchase new equipment etc.
          </p>
          <p>
            Loan Amount can be taken up to Rs. 1 crore without any security and
            Rs. 2 crore with mortgage.
          </p>
          <p>Loan tenure will be 1 â€“ 5 years.</p>
          <p>Interest rate starting from 9% p.a.</p>
        </div>
      </div>
      {/* arrow-card */}
      <div>
        <ul className="box">
          <li className="box-item ">Process</li>
          <li className="box-item ">Submission of Documents</li>
          <li className="box-item ">Sanctioning of Loan</li>
          <li className="box-item ">Acceptance of Loan</li>
          <li className="box-item ">Disbursement of Loan</li>
        </ul>
      </div>

      <div className="outer-section-flex-row">
        <h2>Documents Required</h2>
        <div className="card-flex-row-md">
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-2.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-3.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-1.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-4.png" alt=" " />
          </div>
          <div className="card-img-md" data-aos="fade-up">
            <img src="/Image/Card-5.png" alt=" " />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LoanAgainstProperty;
