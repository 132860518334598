const slideMark = {
  MarkAmount: [
    { value: 0, label: "0" },
    { value: 2500000, label: "25L" },
    { value: 5000000, label: "50L" },
    { value: 7500000, label: "75L" },
    { value: 10000000, label: "100L" },
    { value: 12500000, label: "125L" },
    { value: 15000000, label: "150L" },
    { value: 17500000, label: "175L" },
    { value: 20000000, label: "200L" },
  ],
  MarkIntrest: [
    { value: 0, label: "0" },
    { value: 5, label: "5%" },
    { value: 10, label: "10%" },
    { value: 15, label: "15%" },
    { value: 20, label: "20%" },
    { value: 25, label: "25%" },
    { value: 30, label: "30%" },
  ],
  markDuration: [
    { value: 0, label: "0" },
    { value: 5, label: "5yr" },
    { value: 10, label: "10yr" },
    { value: 15, label: "15yr" },
    { value: 20, label: "20yr" },
    { value: 25, label: "25yr" },
    { value: 30, label: "30yr" },
  ],
};

export default slideMark;
