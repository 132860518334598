import React from "react";
function HomeCard() {
  const cardInfo = [
    {
      id: 1,
      title: "Experience",
      details:
        "We provide the best experience in terms of application, Eligibility check or Customer service.",
    },
    {
      id: 2,
      title: "Services",
      details:
        "We provide the best services in terms of Loan comparison, Online application & Smooth disbursal.",
    },
    {
      id: 3,
      title: "Reliability",
      details:
        "We prioritize Transparency, Data security and Customer service before and after the process.",
    },
  ];

  return (
    <>
      {cardInfo.map((item) => (
        <div className="home-card-container" data-aos="fade-up" key={item.id}>
          <p className={`home-card-container-heading ${item.id}`}>
            {item.title}
          </p>
          <p className="home-card-container-paragraph">{item.details}</p>
        </div>
      ))}
    </>
  );
}

export default HomeCard;
