import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./ServicesBanner.css";
import {  servicesGif, whatWeOfferInd, whatWeOfferMon, whatWeOfferSec } from "../../../utils/ImagesData/imagesData";

const ServicesBanner = () => {
  const [img, setImg] = useState(servicesGif);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (img) => {
    setIsHovered(true);
    setImg(img);
  };

  const handleMouseLeave = () => {
    setImg(servicesGif);
    setIsHovered(false);
  };

  const navLinks = [
    {
      id: 1,
      text: "Unsecured Business Loan",
      to: "/business-loan",
      img: whatWeOfferSec,
    },
    {
      id: 2,
      text: "Unsecured Dropline Limit",
      to: "/dropline-limit",
      img: whatWeOfferMon,
    },
    {
      id: 3,
      text: "CGTMSE",
      to: "/cgtmse",
      img: whatWeOfferInd,
    },
  ];

  return (
    <>
      <section className="main-banner-container">
        <div className="tagline-container">
          <div className="tagline-text" aria-level={1}>
            Our Services
          </div>
          <div className="dot left-dot" aria-hidden="true"></div>
          <div className="line" aria-hidden="true"></div>
          <div className="dot right-dot" aria-hidden="true"></div>
        </div>
        <div className="container-box-services-banner">
          <div className="content-box-services-banner">
            <p className="first-headline-services-banner">What We Offer</p>
            <p className="description-texts-services-banner">
              "Empowering Enterprises: Access unsecured business loans, flexible
              dropline limits, and CGTMSE support effortlessly. Experience
              seamless disbursals and streamline your journey with hassle-free
              one-time documentation."
            </p>
            <div className="nav-links-services-banner">
              {navLinks.map((link) => (
                <NavLink
                  to={link.to}
                  key={link.id}
                  onMouseEnter={() => handleMouseEnter(link.img)}
                  onMouseLeave={() => handleMouseLeave()}
                >
                  <div className="services-heading-link">{link.text}</div>
                </NavLink>
              ))}
            </div>
          </div>

          <div className="gif-container">
            <img
              src={img}
              alt="Unsecured Business Loan, Unsecured Dropline Limit, CGTMSE gif"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesBanner;
