import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { Rating } from "@mui/material";

function Card({ imagen, name, rating, review }) {
  const [show, setShown] = useState(false);

  const props3 = useSpring({
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)",
  });
  return (
    <animated.div
      className={Styles.desktopCard}
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className={Styles.cardContent}>
        <div className={Styles.cardContentLeft}>
          <img src={imagen} alt="Review Logo" />
          <p>{name}</p>
        </div>
        <div className={Styles.cardContentRight}>
          <p>“{review}”</p>
        </div>
      </div>
      <div className={` relative ${Styles.btnn}`}>
        <Rating
          name="read-only"
          value={rating}
          defaultValue={3.5}
          precision={0.5}
          readOnly
          size="large"
        />
      </div>
    </animated.div>
  );
}

export default Card;
