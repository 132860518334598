import React from "react";

const TeamMemberCard = ({ profile }) => {
  return (
    <div className="card-desktop relative">
      <div className="content-desktop">
        <div className="front-desktop">
          <img src={profile.image} alt="profile-pic" />
        </div>
        <div className="front-desktop-desc">
          <div className="front-desktop-desc-description">{profile.name}</div>
          <div className="front-desktop-desc-designation">
            {profile.designation}
          </div>
        </div>
        <div className="back-desktop">
          <div className="desktopTeamMemberCard-hover-card">
            <div className="desktopTeamMemberCard-hover-card-img">
              <img src={profile.image} alt="profile-pic" />
            </div>
            <div className="desktopTeamMemberCard-hover-card-desc">
              <div>
                <p className="desktopTeamMemberCard-hover-card-desc-name z-1">
                  {profile.name}
                </p>
                <p className="desktopTeamMemberCard-hover-card-desc-desigination z-1">
                  {profile.designation}
                </p>
                <p className="desktopTeamMemberCard-hover-card-desc-description z-1">
                  {profile.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
