import "./MobileContactUs.css";
import React, { useState, useContext, useRef, useEffect } from "react";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { FaUserAlt } from "react-icons/fa";
import { MdPhoneInTalk, MdEmail } from "react-icons/md";
import { Helmet } from "react-helmet";
import ProgressBar from "../../ProgressBar/ProgressBar";
import FooterCopyrightMobile from "../Footer/FooterCopyrightMobile";
import ReCAPTCHA from "react-google-recaptcha";
import {
  callContactPageIcon,
  mail_Contact_Icon,
  location_ContactUs_Icon,
} from "../../../utils/ImagesData/imagesData";
import axiosRequest from "../../../ApiRequests/Axios/axiosCaller";
import MobileFooter from "../Footer/MobileFooter";

const MobileContactUs = () => {
  const recaptcha = useRef();
  const formRef = useRef(null);
  const SuccessMsg = () => {
    toast.success("your information is successfully submitted", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const ErrorMsg = (msg = "your information not submitted") => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  <MutatingDots
    height="100"
    width="100"
    color="#4fa94d"
    secondaryColor="#4fa94d"
    radius="12.5"
    ariaLabel="mutating-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />;
  const IndianPhoneNumberRegex = /^(\+91[-s]?)?[0]?(91)?[6789]\d{0,9}$/;
  const IndianEmailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const firstNameRegex = /^[a-zA-Z.\s]+$/;
  const lastNameRegex = /^[a-zA-Z]+$/;
  const [firstName, setFirstName] = useState("");
  const [firstNamelabel, setFirstNamelabel] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNamelabel, setLastNamelabel] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberLabel, setPhoneNumberLabel] = useState(false);
  const [email, setEmail] = useState("");
  const [emailLabel, setEmailLabel] = useState(false);
  const [message, setMessage] = useState("");
  const [messageLabel, setMessageLabel] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [formdata, setFormdata] = useState({
    inputField1: "",
    inputField2: "",
    inputField3: "",
    inputField4: "",
    inputField5: "",
    // Add more input fields as needed
  });

  const [progress, setProgress] = useState(false);
  const [isButton, setIsButton] = useState(true);
  const [success, setSuccess] = useState(false);
  const [onClose, setOnClose] = useState(false);

  var data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phoneNumber,
    messageContent: message,
  };

  async function fetchData(event) {
    event.preventDefault();
    setProgress(true);
    setIsButton(false);
    // changable for recaptcha
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      setProgress(false);
      ErrorMsg("Please fill the reCaptcha form");
      return;
    }
    // changable for recaptcha

    try {
      const response = await axiosRequest("post", "api/form/contactus", data);
      if (response.data) {
        // SuccessMsg();
        setOnClose(true);
        setSuccess(true);
        setIsButton(true);
        // setProgress(false);
        recaptcha.current.reset();
        setTimeout(() => {
          setOnClose(false);
          setSuccess(false);
          setIsButton(false);
          setProgress(false);
        }, 5000);
      } else {
        setOnClose(true);
        setProgress(false);
        recaptcha.current.reset();
        setTimeout(() => {
          setOnClose(false);
          setSuccess(false);
          setIsButton(false);
          setProgress(false);
        }, 3000);
        ErrorMsg();
      }
    } catch (error) {
      recaptcha.current.reset();
      ErrorMsg("Internal Server error");
      setProgress(false);
      setTimeout(() => {
        setOnClose(false);
        setSuccess(false);
        setIsButton(false);
        setProgress(false);
      }, 3000);
    }

    setFormdata({
      inputField1: "",
      inputField2: "",
      inputField3: "",
      inputField4: "",
      inputField5: "",
    });

    formRef.current.reset();

    setTimeout(() => {
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setEmail("");
      setMessage("");
    }, 500);
  }

  const handleFirstNameChange = (event) => {
    const inputValue = event.target.value;

    if (inputValue.length > 100) {
      setFirstNameError("First name should not exceed 100 characters");
    } else {
      if (!firstNameRegex.test(inputValue) && inputValue.length > 0) {
        setFirstNameError("Please enter a valid name");
      } else {
        setFirstName(inputValue);
        setFirstNameError("");
      }
    }
  };

  const handleLastNameChange = (event) => {
    const inputValue = event.target.value.trim();
    if (inputValue.length > 100) {
      setLastNameError("Last name should not exceed 100 characters");
    } else if (!lastNameRegex.test(inputValue) && inputValue.length > 0) {
      setLastNameError("Please enter a valid name");
    } else {
      setLastName(inputValue);
      setLastNameError("");
    }
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");

    if (value.length <= 10) {
      setPhoneNumber(value);

      if (value.length !== 0 && !IndianPhoneNumberRegex.test(value)) {
        setPhoneNumberError("Please enter a valid phone number");
      } else {
        setPhoneNumberError("");
      }
    }
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setEmailError("");
  };

  const handleMessageChange = (event) => {
    if (event.target.value.length > 450) {
      setMessageError("Please write your messsage within 450 words");
      return;
    }
    setMessage(event.target.value);

    if (event.target.value === "") {
      setMessageError("Please enter a message");
    } else {
      setMessageError("");
    }
  };

  const handleFirstNameLabel = () => {
    setFirstNamelabel(true);
  };

  // const handleLastNameFocus = () => {
  //   if (!firstNameRegex.test(firstName)) {
  //     setFirstNameError("Please enter a valid name");
  //   }
  // };

  const handleLastNameLabel = () => {
    setLastNamelabel(true);
  };

  // const handlePhoneNumberFocus = () => {
  //   if (!lastNameRegex.test(lastName)) {
  //     setLastNameError("Please enter a valid name");
  //   }
  // };

  const handlePhoneNumberLabel = () => {
    setPhoneNumberLabel(true);
  };

  // const handleEmailFocus = () => {
  //   if (!IndianPhoneNumberRegex.test(phoneNumber)) {
  //     setPhoneNumberError("Please enter a valid phone number");
  //   }
  // };

  const handleEmailLabel = () => {
    setEmailLabel(true);
    if (phoneNumber.length < 10) {
      setPhoneNumberError("Please enter a valid phone number");
    }
  };

  const handleMessageFocus = (event) => {
    const inputValue = event.target.value;
    if (inputValue !== "" && !IndianEmailRegex.test(inputValue)) {
      setEmailError("Please enter a valid email address");
    }
    // if (!IndianEmailRegex.test(email)) {
    //   setEmailError("Please enter a valid email address");
    // }
  };

  const handleMessageLabel = () => {
    setMessageLabel(true);
  };

  const openGoogleMaps = () => {
    // const location = "9/28+3rd+Floor,East+Patel+Nagar,New+Delhi,Delhi+110008";
    window.open(`https://maps.app.goo.gl/H4VqqivzFjQCJ3YG6`);
  };

  const openDialPad = () => {
    window.location.href = "tel:+919582189429";
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Finconic</title>
        <meta name="description" content="Contact us" />
      </Helmet>
      {progress && (
        <>
          {onClose && (
            <button
              className=" absolute  w-screen h-screen z-[1100]"
              onClick={() => {
                setProgress(false);
                setOnClose(false);
              }}
            ></button>
          )}
          <ProgressBar
            isButton={isButton}
            success={success}
            setProgress={setProgress}
          />
        </>
      )}
      <div className="mobileContactUs">
        <div className="mobileContactUs-headings">
          <h3 className=" uppercase">GET IN TOUCH</h3>
          <h6>
            Whether you’re curious about the product, facing some issue or want
            a casual chit-chat, Our friendly team is here for you.{" "}
          </h6>
          <h2>Just fill out the form below</h2>
        </div>
        <div className="mobileContactUs-forms">
          <form
            className="mobileContactUs-form py-10"
            autoComplete="on"
            ref={formRef}
            onSubmit={fetchData}
          >
            <div className="mobileContactUs-forms-first-name-wrapper">
              <label
                htmlFor="firstNameId"
                className={`absolute z-[95] text-[1.2rem] mt-1 ml-20 transition-all ${
                  firstNamelabel ? "text-[#A1A1A1] " : " hidden"
                } `}
              >
                First name
              </label>
              <div>
                <FaUserAlt className="mobileAboutUS-icon-styles scale-[0.8] " />
                <input
                  name="inputField1"
                  required
                  type="text"
                  id="firstNameId"
                  pattern="[A-Za-z. ]+"
                  inputMode="text"
                  value={firstName}
                  placeholder={`${firstNamelabel ? "" : "First Name"}`}
                  onChange={handleFirstNameChange}
                  className="mobileContactUs-forms-first-name mobileContactUs-forms-inputs"
                  // onBlur={handleLastNameFocus}
                  onFocus={handleFirstNameLabel}
                  aria-label="Enter your First Name"
                />

                {firstNameError && (
                  <div style={{ color: "red", padding: "0.5rem" }}>
                    {firstNameError}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="lastNameId"
                className={`absolute z-[95] text-[0.9rem] mt-1 ml-16 transition-all ${
                  lastNamelabel ? "text-[#A1A1A1] " : " hidden"
                } `}
              >
                Last name
              </label>
              <div>
                <FaUserAlt className="mobileAboutUS-icon-styles scale-[0.8]" />
                <input
                  name="inputField1"
                  required
                  id="lastNameId"
                  type="text"
                  value={lastName}
                  placeholder={`${lastNamelabel ? "" : "Last Name"}`}
                  onChange={handleLastNameChange}
                  className=" mobileContactUs-forms-inputs "
                  // onBlur={handlePhoneNumberFocus}
                  aria-label="Enter your Last Name"
                  onFocus={handleLastNameLabel}
                />
                {lastNameError && (
                  <div style={{ color: "red", padding: "0.5rem" }}>
                    {lastNameError}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="phoneNameId"
                className={`absolute z-[95] text-[0.9rem] mt-[0.8rem] ml-16 transition-all ${
                  phoneNumberLabel ? "text-[#A1A1A1] " : " hidden"
                } `}
              >
                Phone number
              </label>
              <div className="input-contact">
                <MdPhoneInTalk className="mobileAboutUS-icon-styles " />
                <span className="country-code-span my-1 mt-3 text-[2rem] h-[4.4rem]">
                  +91
                </span>
                <input
                  name="inputField2"
                  required
                  type="tel"
                  value={phoneNumber}
                  maxLength={10}
                  placeholder={`${phoneNumberLabel ? "" : "9999999999"}`}
                  pattern="\(\d{3}\) \d{3}\-\d{4}"
                  onChange={handlePhoneNumberChange}
                  className=" mobileContactUs-forms-inputs my-1 mobileContactUs-forms-inputs-phone"
                  // onBlur={handleEmailFocus}
                  aria-label="Enter your phone number"
                  id="phoneNameId"
                  onFocus={handlePhoneNumberLabel}
                />
                {phoneNumberError && (
                  <div style={{ color: "red", padding: "0.5rem" }}>
                    {phoneNumberError}
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="emailId"
                className={`absolute z-[95] text-[0.9rem] mt-3 ml-16 transition-all ${
                  emailLabel ? "text-[#A1A1A1] " : " hidden"
                } `}
              >
                Email
              </label>
              <div className="input-email">
                <MdEmail className="mobileAboutUS-icon-styles  " />
                <input
                  name="inputField3 "
                  required
                  type="email"
                  value={email}
                  placeholder={`${emailLabel ? "" : "abc@gmail.com"}`}
                  onChange={handleEmailChange}
                  className=" mobileContactUs-forms-inputs mt-2"
                  onBlur={handleMessageFocus}
                  aria-label="Enter your email"
                  id="emailId"
                  onFocus={handleEmailLabel}
                />
                {emailError && (
                  <div style={{ color: "red", padding: "0.5rem" }}>
                    {emailError}
                  </div>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="phoneNameId"
                className={`absolute z-[150] text-[0.9rem] mt-1 ml-16 transition-all ${
                  messageLabel ? "hidden" : " hidden"
                } `}
              ></label>
              <div className="input-text">
                <textarea
                  name="inputField4"
                  value={message}
                  placeholder={`${"Type your message here or just say Hi!"}`}
                  onChange={handleMessageChange}
                  className=" mobileContactUs-forms-inputs mobileContactUs-forms-text-msg"
                  aria-label="Enter your message"
                  id="lastNameId"
                  onFocus={handleMessageLabel}
                />
                {messageError && (
                  <div style={{ color: "red", padding: "0.5rem" }}>
                    {messageError}
                  </div>
                )}
              </div>
            </div>

            <br />
            <div className="flex justify-center items-center mt-[-2rem]">
              {/* <div
                className="g-recaptcha"
                data-sitekey="6LdmfFgoAAAAAFMFepjtvgn5sjsWXRYzTwFO7ACy"
              ></div> */}
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={"6LdmfFgoAAAAAFMFepjtvgn5sjsWXRYzTwFO7ACy"}
              />
            </div>
            <div className="btn-position ">
              <button
                style={{
                  marginTop: "-2.5rem",
                }}
                className={`contact-submit-btn ${
                  !(
                    firstName.length === 0 ||
                    lastName.length === 0 ||
                    email.length === 0 ||
                    phoneNumber.length === 0 ||
                    phoneNumber.length < 10 ||
                    message.length === 0
                  ) && !progress
                    ? "cta-btn-flex"
                    : "contact_submit_button_disabled"
                }`}
                type="submit"
                disabled={
                  (firstName.length === 0 ||
                    lastName.length === 0 ||
                    email.length === 0 ||
                    phoneNumber.length === 0 ||
                    phoneNumber.length < 10 ||
                    message.length === 0) &&
                  progress
                }
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="contactUsOptions py-8">
          <div className="contactUsOptions-phone ">
            <div className="contactUsOptions-phone-img">
              <img
                src={callContactPageIcon}
                className="h-10 w-10  "
                alt="Call contact icon"
                onClick={openDialPad}
              />
            </div>
            <div className="contactUsOptions-phone-num contactUsOptions-option-text">
              <p className="text-[#0B3C7F] ">+91</p>
              <p className="text-[#0B3C7F]  " onClick={openDialPad}>
                9582189429
              </p>
            </div>
          </div>
          <div className="contactUsOptions-location">
            <div className="contactUsOptions-option ">
              <div className="contactUsOptions-icon">
                <a href="mailto:info@finconic.com">
                  <img
                    src={mail_Contact_Icon}
                    className="h-10 w-10  "
                    alt="Email icon"
                    srcSet=""
                  />
                </a>
              </div>
              <div className="contactUsOptions-option-text">
                <p>
                  <a
                    href="mailto:info@finconic.com"
                    className="text-[#0B3C7F]  underline"
                  >
                    info@finconic.com{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="contactUsOptions-email">
            <div className="contactUsOptions-option ">
              <div
                className="contactUsOptions-icon"
                onClick={() => openGoogleMaps()}
              >
                <img
                  src={location_ContactUs_Icon}
                  className="h-10 w-10  "
                  alt="location icon"
                  srcSet=""
                />
              </div>
              <div
                className="contactUsOptions-option-text"
                onClick={() => openGoogleMaps()}
              >
                <p className="text-[#0B3C7F] ml-[3px]">
                  9/28 3rd Floor, East Patel Nagar, New Delhi, Delhi 110008
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileFooter />
    </>
  );
};

export default MobileContactUs;
