import React from "react";
import "./mobileNotFound.css";
import { bottomImg, mainImgForNotFoundMobile, topImg } from "../../../utils/ImagesData/imagesData";

const MobileNotFound = () => {
  return (
    <div className="relative overflow-hidden">
      <img src={topImg} alt="Not Found Background Image" className="notFound-Cloudimage notFound-Cloudimage-top" />
      <div className="mobileNotFound-container">
        <div className="mobileNotFound-img">
          <img src={mainImgForNotFoundMobile} alt="Not Found Image" />
        </div>
        <div className="mobileNotFound-content">
          <div className="mobileNotFound-heading">
            <h2>Page Not Found</h2>
          </div>
          <div className="mobileNotFound-desc">
            <p>
              This page doesn’t exist or was removed! We suggest you go back to
              home.
            </p>
          </div>
          <div className="mobileNotFound-button">
            <button
              className="cta-btn-flex"
              onClick={() => (window.location.href = "/")}
            >
              Go Back Home
            </button>
          </div>
        </div>
      </div>
      <img src={bottomImg} alt="Not Found Background Image" className="notFound-Cloudimage notFound-Cloudimage-bottom" />
    </div>
  );
};

export default MobileNotFound;
