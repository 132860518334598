import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import arrowsForReviewSlider from "../../assets/MobileScreenImages/arrowsForReviewSlider.png";

export default function Carroussel(props) {
  const table = props.cards.map((element, index) => {
    return {
      ...element,
      onClick: () => {
        setGoToSlide(index);
      },
    };
  });

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(null);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  return (
    <div
      style={{ width: props.width, height: props.height, margin: props.margin }}
      className=" relative"
    >
      <button
        onClick={() => setGoToSlide(goToSlide - 1)}
        className=" absolute left-[-12rem] h-20 top-24   z-[1500] arrows-left-review arrows-review"
      >
        <img src={arrowsForReviewSlider} alt="Previous Arrow" />
      </button>
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.wobbly}
      />
      <button
        onClick={() => setGoToSlide(goToSlide + 1)}
        className=" absolute right-[-12rem] h-20 top-24 z-[1500]  arrows-right-review arrows-review"
      >
        <img src={arrowsForReviewSlider} alt="Next Arrow" />
      </button>
    </div>
  );
}
