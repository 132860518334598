import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./progressBar.css";
import progressBarSuccessIcon from "../../assets/Image/progressBarSuccessIcon.png";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProgressBar = ({ isButton = false, success = false, setProgress }) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let timeoutId;

    if (success && isButton) {
      timeoutId = setTimeout(() => {
        setIsVisible(false);
        navigate("/");
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [success, isButton, navigate]);

  const handleButtonClick = () => {
    setIsVisible(false); // Hide the progress bar immediately on button click
    navigate("/");
  };

  return isVisible ? (
    <div className="progress-bar-body">
      <div className="progress-container relative">
        {!success ? (
          <CircularProgress
            size={200}
            thickness={1}
            style={{
              color: "#3CB218",
            }}
          />
        ) : (
          <CircularProgress
            variant="determinate"
            size={200}
            thickness={1}
            value={100}
            style={{
              color: "#3CB218",
            }}
          />
        )}

        {success && (
          <div className="flex flex-col items-center justify-center mt-1 text-center absolute top-36">
            <img
              src={progressBarSuccessIcon}
              alt="Success Icon"
              className="h-16 w-16 z-[1000] mb-[1rem]"
            />
            <span className="progress-value text-[#3CB218] font-light">
              <p className="font-normal break-all">Successfully</p>
              <p className="font-normal break-all">Submitted</p>
            </span>
          </div>
        )}

        {isButton && (
          <button className="text cta-btn-flex" onClick={handleButtonClick}>
            Go back Home
          </button>
        )}
      </div>
    </div>
  ) : null;
};

ProgressBar.propTypes = {
  isButton: PropTypes.bool,
  success: PropTypes.bool,
  setProgress: PropTypes.func,
};

export default ProgressBar;
