import React, { useState, useEffect, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink } from "react-router-dom";
import MobileSidebar from "./MobileSidebar";
import "./MobileNavbar.css";
import {
  finconicLogo,
  contactUsLogo,
} from "../../../utils/ImagesData/imagesData";

const MobileNavbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const baseColor = "linear-gradient(180deg, #E4F0FF 0%, #FFF 100%)";
  const transparentColor = "rgba(228, 240, 255, 0.8)";
  const [navbarBackground, setNavbarBackground] = useState(
    `linear-gradient(180deg, rgba(228, 240, 255, 0.8) 0%, #FFF 100%)`
  );
  const [navbarWidth, setNavbarWidth] = useState("94%");
  const [navbarBorderRadius, setNavbarBorderRadius] = useState("1.5rem");
  const [navbarTop, setNavbarTop] = useState("1rem");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > 100) {
        // set the background color to a dark gray when the user scrolls
        setNavbarBackground(baseColor);
        setNavbarWidth("101%");
        setNavbarBorderRadius("0");
        setNavbarTop("0");
      } else {
        // set the background color with reduced opacity when the user scrolls back to the top
        setNavbarBackground(`${transparentColor}, ${baseColor}`);
        setNavbarWidth("94%");
        setNavbarBorderRadius("1.5rem");
        setNavbarTop("1rem");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll); // remove the event listener when the Navbar component unmounts
    };
  }, []);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const setDefaultNavbarStyles = () => {
    setShowSidebar(false);
    setNavbarBackground(`${transparentColor}, ${baseColor}`);
    setNavbarWidth("94%");
    setNavbarBorderRadius("1.5rem");
    setNavbarTop("1rem");
  };

  const setNavbarAfterLinkClicked = () => {
    setShowSidebar(false);
    setDefaultNavbarStyles();
  };
  
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    if (!showSidebar) {
      setNavbarBackground(baseColor);
      setNavbarWidth("101%");
      setNavbarBorderRadius("0");
      setNavbarTop("0");
    } else {
      setDefaultNavbarStyles();
    }
  };

  const links = [
    {
      id: 1,
      title: "Services",
      sublist: [
        { title: "Unsecured Business Loan", route: "/business-loan" },
        { title: "Unsecured Dropline Limit", route: "/dropline-limit" },
        { title: "CGTMSE", route: "/cgtmse" },
      ],
    },
    { id: 2, title: "About Us", route: "/about" },
    { id: 3, title: "Contact Us", route: "/contact" },
  ];

  const openDialPad = () => {
    window.location.href = "tel:+919582189429";
  };

  return (
    <section id="mobile-navbar" className="nav-bar-container z-[1500]">
      <div className="mobile-navbar-container-box">
        <div
          style={{
            background: navbarBackground,
            width: navbarWidth,
            borderRadius: navbarBorderRadius,
            top: navbarTop,
          }}
          className="mobile-navbar-container-flex"
        >
          <div className="mobile-navbar-flex-list-row">
            <div
              className="mobile-navbar-hamburger-menu-container"
              onClick={toggleSidebar}
            >
              {showSidebar ? (
                <CloseIcon sx={{ fontSize: 32 }} />
              ) : (
                <MenuIcon sx={{ fontSize: 32 }} />
              )}
            </div>
            <div className="mobile-navbar-finconic-logo-container">
              <NavLink to="/" onClick={() => setDefaultNavbarStyles()}>
                <img src={finconicLogo} alt="finconic-logo" />
              </NavLink>
            </div>
            <div className="contact-us-logo-container">
              <div onClick={openDialPad}>
                <img src={contactUsLogo} alt="contact-us-logo" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobileSidebar
        open={showSidebar}
        onClose={() => setNavbarAfterLinkClicked()}
        links={links}
      />
    </section>
  );
};

export default MobileNavbar;
